import Service from '@/services/merchants';
import merchantsDB from '@/database/merchants';


const state = {};

const getters = {};

const mutations = {};

const actions = {
    async find({ commit }, params) {
        const merchant = await merchantsDB.find(params.id);
        return Promise.resolve(merchant);
    },

    show({ commit }, params) {
        return Service.show(params.id)
            .then(async (response) => {
                await merchantsDB.updateOrAdd(response);
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error));
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}