<template>
    <div class="interactions rounded">
        <a href="#" @click.prevent="$emit('like')" class="item like" v-if="showLike">
            <i class="mdi mdi-heart-outline"></i>
        </a>

        <a href="#" @click.prevent="$emit('unlike')" class="item unlike" v-else>
            <i class="mdi mdi-heart"></i>
        </a>

        <a href="#" @click.prevent="$emit('dislike')" class="item dislike" v-if="showDislike">
            <i class="mdi mdi-emoticon-sad"></i>
        </a>

        <a href="#" @click.prevent="$emit('undislike')" class="item dislike" v-else>
            <i class="mdi mdi-emoticon-sad text-danger"></i>
        </a>

        <a href="#" title="Report" @click.prevent="$emit('reportAdvert')" class="item text-warning">
            <i class="mdi mdi-flag-checkered"></i>
        </a>
    </div>
</template>
<script>
    export default {
        props: {
            showLike: {
                type: Boolean,
                default: false
            },
            showDislike: {
                type: Boolean,
                default: false
            }
        }
    }
</script>