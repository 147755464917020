<template>
    <story-item :advert="advert" @navigate="navigate"/>
</template>
<script>
import Carousel from '@/components/shared/carousel/Carousel.vue';
import CarouselItem from '@/components/shared/carousel/CarouselItem.vue';
import StoryItem from './partials/StoryItem.vue';

export default {
    props: {
        ads: {
            type: Array,
            default: () => []
        }
    },

    components: {
        Carousel,
        StoryItem,
        CarouselItem
    },

    data() {
        return {
            index: 0
        }
    },

    computed: {
        advert() {
            if (this.ads.length === 0) return null;
            
            return this.ads[this.index];
        }
    },

    methods: {
        cancel() {
            this.$emit('cancel')
        },

        navigate(direction) {
            if (direction === 'next') {
                if (this.index === this.ads.length - 1) {
                    this.cancel();
                } else {
                    this.index++;
                }
            } else {
                if (this.index === 0) {
                    this.cancel();
                } else {
                    this.index--;
                }
            }
        }
    }
}
</script>