import { db } from './index';

const getCheckoutByUser = async (userId) => {
    if (!userId) {
        return null;
    }

    return await db.Checkouts.where({id: userId}).toArray();
}

const update = async (id, data) => {
    const cart = await getCheckoutByUser(id);
    
    if (cart.length) {
        return await db.Checkouts.update(id, {value: data});
    } else {
        return await db.Checkouts.add({id, value: data});
    }
}

export default {
    update,
    getCheckoutByUser
}