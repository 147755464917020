<template>
    <div>
        <create-wizard 
            :activeIndex="activeIndex" 
            @submit="onSubmit"/>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import CreateWizard from '@/views/customer/channels/create/Index.vue';

export default {
    name: "CreateChannel",

    components: {
        CreateWizard
    },

    computed: {
        ...mapGetters({
            payload: 'CreateChannels/getReqPayload',
        }),
        activeIndex() {
            return parseInt(this.$route.query.idx) || 0;
        }
    },

    methods: {
        ...mapActions({
            store: 'Channels/store',
            update: 'Channels/update',
            clearState: 'CreateChannels/clearState'
        }),

        onSubmit() {
            let loadingMsg, successMsg;

            if (this.payload.existingChannel) {
                loadingMsg = 'Updating channel...';
                successMsg = 'Channel updated successfully';
            } else {
                loadingMsg = 'Creating channel...';
                successMsg = 'Channel created successfully';
            }

            this.showLoader(loadingMsg + ' Please wait...')

            this.store(this.payload).then((response) => {
                this.hideLoader();
                this.notifySuccess(successMsg);
                this.clearState();
                this.$router.push({ 
                    name: 'channels.view', 
                    params: {
                        channel_id: response.id 
                    }
                });
            }).catch((error) => {
                this.hideLoader();
                this.notifyError(error.message || 'An error occurred');
            });
        }
    }
}
</script>