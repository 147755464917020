<template>
    <modal
        :show="show"
        :centered="true"
        :maxHeight="true"
        :lg="true"
        @ok="okay"
        @cancel="cancel"
        okText="Filter"
        okIcon="mdi mdi-filter">
        <h5 class="m-0 font-small font-weight-bold text-capitalize" slot="header">
            Channel Filters
        </h5>

        <div slot="body" class="pl-3 pr-3 font-small">
            <div class="d-flex align-items-center mb-2">
                <h5 class="font-xxs text-black-fade m-0 mr-1">Show More Of</h5>
                <span class="text-muted font-xxs">(Click to unselect/select)</span>
            </div>
            <div class="row">
                <div class="col-md-3 col-6" v-for="(productType, index) in productTypes" :key="index">
                    <label class="checkbox cursor-pointer d-flex align-items-center text-black-fade bg-default p-2 rounded">
                        <input 
                            type="checkbox" 
                            v-model="filters.productTypes[productType.id]" 
                            class="mr-1"/>
                        <span class="check"></span>
                        {{ productType.name }}
                    </label>
                </div>
            </div>

            <hr class="mb-4 mt-2"/>

            <div class="row">
                <div class="col-md-3 col-6">
                    <base-input
                        label="Min Price"
                        type="number"
                        placeholder="Min"
                        v-model="filters.price.min"
                    />
                </div>
                <div class="col-md-3 col-6">
                    <base-input
                        label="Max Price"
                        type="number"
                        placeholder="Min"
                        v-model="filters.price.max"
                    />
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import bus from '@/event-bus';
import { get } from 'lodash';
import { mapActions } from 'vuex';

export default {
    props: ['show', 'channel'],

    data() {
        return {
            filters: {
                productTypes: {},
                price: {
                    min: '',
                    max: ''
                },
            }
        }
    },

    computed: {
        channel_id() {
            return get(this.channel, 'id', null);
        },
        productTypes() {
            return get(this.channel, 'product_types', []);
        },
        existingFilters() {
            return get(this.channel, 'filters', {});
        },
    },

    watch: {
        channel: {
            handler() {
                this.init();
            },
            immediate: true,
            deep: true
        }
    },

    methods: {
        ...mapActions({
            update: 'Channels/update'
        }),

        okay() {
            if (!this.channel_id) return;
            
            const filters = {
                price: this.filters.price,
                product_types: Object.keys(this.filters.productTypes).filter(key => this.filters.productTypes[key])
            }

            this.$emit('ok', {filters});
        },

        init() {
            const data = {};
            for(const prodType of this.productTypes) {
                data[prodType.id] = get(this.existingFilters, `product_types`, []).includes(prodType.id);
            }
            this.filters.productTypes = data;
            this.filters.price = get(this.existingFilters, 'price', {min: '', max: ''});
        },

        cancel() {
            this.init();
            this.$emit('cancel')
        }
    },
}
</script>