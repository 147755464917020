<template>
    <div class="top-part">
        <div class="progress-indicators">
            <slot />
        </div>

        <div class="status-profile d-flex align-items-center">
            <button type="button" class="btn mr-2 control" @click.prevent="$emit('cancel')">
                <i class="mdi mdi-arrow-left"></i>
            </button>

            <img class="profile-image mr-3" :src="merchant.logo_url || merchant.logo" @click.prevent="$emit('profile')"/>

            <div class="desc" @click.prevent="$emit('profile')">
                <p class="m-0 font-small font-weight-bold">{{ merchant.name }}</p>
                <p class="m-0 font-xs">
                    <time-counter :timestamp="updatedAt"/>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        updatedAt: {
            type: String,
            required: true
        },
        merchant: {
            type: Object,
            required: true
        }
    }
}
</script>