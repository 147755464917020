<template>
<header class="header d-flex justify-content-between align-items-center mb-4">
    <a href="#" @click.prevent="goBack" class="text-primary">
        <i class="mdi mdi-chevron-left font-xxl"></i>
    </a>
    <h5 class="text-primary">
        <slot/>
    </h5>

    <dropdown/>
</header>
</template>

<script>
import Dropdown from './Dropdown.vue';
export default {
    name: 'GettingStartedHeader',
    components: {
        Dropdown
    },
    data() {
        return {
            showOptions: false
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
    },
}
</script>

<style>

</style>