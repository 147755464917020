<template>
    <div class="bg-white p-1 text-muted text-center elevation">
        <template v-if="type == 'returns'">
            RECEIPTS sorted by return deadline (default), by date - toggle
        </template>
    </div>
</template>
<script>
export default {
    name: "AlertTitle",
    props: ['type']
}
</script>