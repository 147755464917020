import Service from '@/services/adverts';

const state = {};

const getters = {};

const mutations = {};

const actions = {
    show({ commit }, id) {
        return Service.show(id)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}