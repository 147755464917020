<template>
    <div class="shipping-info elevation mb-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h6 class="font-weight-bold font-medium m-0 text-black">Shipping Information</h6>
            <template v-if="info">
                <button class="btn edit-btn elevation-2" v-if="!showEdit" @click="showEditPopup(true)">
                    <i class="mdi mdi-pencil font-medium"></i>
                </button>
                <button class="btn delete-btn elevation-2" v-if="showEdit" @click="showEditPopup(false)">
                    <i class="mdi mdi-close font-medium"></i>
                </button>
            </template>
        </div>

        <div class="infos" v-if="!showEdit">
            <div class="info d-flex align-items-center mb-2" v-for="(item, index) in shippingInfo" :key="index">
                <span class="font-big text-muted mr-3 mdi" :class="item.icon"></span>
                <div class="flex-grow-1">
                    <h6 class="font-weight-bold text-primary m-0 text-capitalize">{{ item.value }}</h6>
                    <span class="font-xxs m-0 d-block text-muted">{{ item.subtitle }}</span>
                </div>
            </div>
        </div>

        <div class="form" v-if="showEdit">
            <base-input 
                divClass="icon-left"
                :noMargin="true" 
                icon="mdi mdi-account-circle font-xl" 
                :placeholder="payload.name.subtitle"
                v-model="payload.name.value"
                :error="vErrors.name" />

            <base-input 
                divClass="icon-left" 
                :noMargin="true" 
                icon="mdi mdi-map-marker font-xl" 
                :placeholder="payload.address.subtitle"
                v-model="payload.address.value"
                :error="vErrors.address" />

            <base-input 
                divClass="icon-left" 
                :noMargin="true" 
                type="number"
                icon="mdi mdi-phone font-xl" 
                :placeholder="payload.phone.subtitle"
                v-model="payload.phone.value"
                :error="vErrors.phone" />

            <div class="text-right">
                <button class="btn btn-secondary p-1 pl-3 pr-3 elevation-2" @click="save">
                    <i class="mdi mdi-check font-medium"></i> Save
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { get } from 'lodash'
import { mapActions } from 'vuex';

export default {
    props: ['info'],

    data() {
        return {
            showEdit: true,
            vErrors: {
                name: '',
                phone: '',
                address: ''
            },
            payload: {
                name: {
                    icon: 'mdi-account-circle',
                    value: '',
                    subtitle: 'Full name'
                },
                address: {
                    icon: 'mdi-map-marker',
                    value: '',
                    subtitle: 'Address'
                },
                phone: {
                    icon: 'mdi-phone',
                    value: '',
                    subtitle: 'Phone Number'
                }
            }
        }
    },

    computed: {
        shippingInfo() {
            return Object.values(this.payload);
        }
    },

    watch: {
        info: {
            deep: true,
            handler() {
                this.payload.name.value = get(this.info, 'name', '');
                this.payload.phone.value = get(this.info, 'phone', '');
                this.payload.address.value = get(this.info, 'address', '');

                this.showEdit = !this.info;
            }
        }
    },

    methods: {
        ...mapActions({
            store: 'Checkouts/storeShippingInfo'
        }),

        showEditPopup(show) {
            this.showEdit = show;
        },

        validate(data) {
            let valid = true;

            for (let key of Object.keys(data)) {
                if (!data[key]) {
                    this.vErrors[key] = `${key} field is required`
                    valid = false
                }
            }

            return valid;
        },

        clearVErrors() {
            for (let key of Object.keys(this.vErrors)) {
                this.vErrors[key] = ''
            }
        },

        save() {
            this.clearVErrors()

            const data = {
                name: this.payload.name.value,
                address: this.payload.address.value,
                phone: this.payload.phone.value
            }

            if (this.validate(data) == false) {
                return;
            }

            this.store(data).then(response => {
                console.log(response)
            }).catch(error => {
                for (let key of Object.keys(error.errors)) {
                    if (error.errors[key].length) {
                        this.vErrors[key] = error.errors[key][0]
                    }
                }
            })
        }
    }
}
</script>