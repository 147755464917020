import { axios } from './index';

export default {
    index(params) {
        return axios.get(`channels/${params.channel_id}/streams`, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    interact(params) {
        return axios.post(`channels/streams/interactions`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    holdOrUnhold(params) {
        return axios.post(`channels/streams/hold`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getHoldList(params) {
        return axios.get(`channels/streams/hold_list`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getComments(params) {
        return axios.get(`channels/streams/notes`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    addComment(params) {
        return axios.post(`channels/streams/notes`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    createViews(params) {
        return axios.post(`channels/streams/views`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    updateViews(params) {
        return axios.put(`channels/streams/views`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    delete(id) {
        return axios.delete(`channels/streams/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    updateClicks(params) {
        return axios.post(`channels/streams/clicks`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getReportReasons(params) {
        return axios.get(`channels/streams/report_reasons`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    reportAdvert(params) {
        return axios.post(`channels/streams/report_advert`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    shareAdvert(params) {
        return axios.post(`channels/streams/share_advert`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    votePoll(params) {
        return axios.post(`channels/streams/polls/vote`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getRelatedAds(params) {
        return axios.get(`channels/streams/related_postings`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },
}