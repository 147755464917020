<template>
  <div class="section">
        <div class="vendor-rating section-content">
            <button
                type="button"
                @click="setRating('low')"
                class="rating font-xxs"
                :class="{ 'active': value.includes('low') }">
                low
            </button>
            <button
                type="button"
                @click="setRating('medium')"
                class="rating font-xxs"
                :class="{ 'active': value.includes('medium') }">
                med
            </button>
            <button
                type="button"
                @click="setRating('high')"
                class="rating font-xxs"
                :class="{ 'active': value.includes('high') }">
                high
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => []
        },
    },
    
    methods: {
        setRating(rating) {
            let values = [];

            if (this.value.includes(rating)) {
                values = this.value.filter(r => r != rating);
            } else {
                values = [...this.value, rating];
            }

            this.$emit('input', values);
        }
    }
}
</script>

<style>

</style>