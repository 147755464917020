import Service from '@/services/smart_alert_histories';
import SmartAlertHistoriesDB from '@/database/smart_alert_histories';

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async index({ commit }, params) {
        const items = await SmartAlertHistoriesDB.index(params);
        return Promise.resolve(items);
    },

    sync({ commit }, params) {
        return Service.index(params)
            .then(async (response) => {
                await SmartAlertHistoriesDB.updateOrAdd(response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    async store({commit}, data) {
        return Service.store(data)
            .then(async (response) => {
                if (response.history) {
                    await SmartAlertHistoriesDB.updateOrAdd([response.history]);
                }
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}