<template>
    <div class="base-switch" :class="{active: value}">
      <input 
        type="checkbox" 
        :id="id" 
        :value="value" 
        @change="onChange">

      <label :for="id">
        <span class="text font-weight-bold">{{ value ? yesText : noText }}</span>
        <span class="dot"></span>
      </label>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BaseSwitch',
    props: {
        id: {
            type: String,
            default: 'switch'
        },
        value: {
            type: Boolean,
            default: false
        },
        yesText: {
            type: String,
            default: 'YES'
        },
        noText: {
            type: String,
            default: 'NO'
        }
    },
    methods: {
        onChange(e) {
            this.$emit('input', Boolean(e.target.checked));
        }
    }
  }
  </script>
