import { db } from './index';

export default {
    async createMany(prod_types) {
        await db.InterestTypes.bulkAdd(prod_types);
    },

    async getLatest() {
        return await db.InterestTypes.orderBy('id').last();
    },

    async getAll() {
        return db.InterestTypes.toArray();
    }
}