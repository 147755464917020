<template>
  <div class="create-channel">
    <p-header _title="Create Account"/>

    <responsive-width>
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <form @submit.prevent="submit">

            <div class="row">
              <div class="col-12">
                  <base-input
                    name="name"
                    placeholder="First and Last name"
                    label="Your Name *"
                    autocomplete="name"
                    v-model="payload.name"/>
                </div>
            </div>

            <div class="row">
              <div class="col-12">
                  <base-input
                    name="email"
                    type="email"
                    placeholder="Enter Your Email"
                    label="Your Email *"
                    autocomplete="email"
                    v-model="payload.email"/>
              </div>
              <div class="col-12">
                <base-input
                  name="username"
                  placeholder="Enter Username"
                  label="Username *"
                  v-model="payload.username"/>
              </div>
            </div>

            <div class="row">
              <div class="col-6 col-lg-4 col-md-4">
                  <base-input
                    name="password"
                    type="password"
                    placeholder="Enter Your Password"
                    label="Password *"
                    v-model="payload.password"/>
              </div>
              <div class="col-6 col-lg-4 col-md-4">
                <base-input
                  name="password_confirmation"
                  type="password"
                  placeholder="Re-enter Your Password"
                  label="Re-enter Your Password *"
                  v-model="payload.password_confirmation"/>
              </div>
            </div>

            <div class="mt-3 mb-3">
              <hr/>
              <h5 class="font-xs mb-1">
                <i class="mdi mdi-eye-off mr-2"></i> Your data belongs to you.
              </h5>
              <p class="font-xs text-muted">
                Woonmi doesn't sell your data to advertisers or other third parties. We only use your data to provide you with the best experience possible.
              </p>
              <hr/>
            </div>

            <div class="mt-3">
              <label class="checkbox cursor-pointer d-flex align-items-center font-xs">
                  <input type="checkbox" name="agreement" v-model="payload.agreement" class="mr-1"/>
                  <span class="check"></span>
                  I agree to the <a class="font-xs d-inline ml-1" @click="onOpenTerms" href="https://woonmi.com" target="_blank">Terms of Service and Privacy Policy</a>
              </label>
            </div>

            <div class="text-center mt-5">
              <button class="btn btn-primary font-medium get-started-btn">
                  Submit 
              </button>
            </div>
          </form>
        </div>
      </div>
    </responsive-width>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PHeader from '@/components/shared/PHeader.vue'

export default {
  components: { PHeader },
  data() {
    return {
      clickedOnTerms: false,
      payload: {
        name: '',
        email: '',
        username: '',
        password: '',
        password_confirmation: '',
        agreement: false,
      }
    }
  },
  computed: {
    ...mapGetters({
      channelPayload: 'CreateChannels/getReqPayload',
      channelDataIsValid: 'CreateChannels/getValidateChannelData',
    })
  },
  methods: {
    ...mapActions({
      getProductTypes: 'ProductTypes/index',
      register: 'Authentications/register',
      loadParams: 'CreateChannels/loadParams',
      createIntent: 'Channels/store',
      clearState: 'CreateChannels/clearState',
    }),

    onOpenTerms() {
      this.clickedOnTerms = true;
    },

    validateName() {
      const names = this.payload.name.split(' ');
      return names.length > 1;
    },

    validateEmail() {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return this.payload.email && emailRegex.test(this.payload.email);
    },

    validate() {
      if (!this.validateName()) {
        this.notifyError('Your name required. Please enter your first and last names')
        return false;
      }
      
      if (!this.validateEmail()) {
        this.notifyError('Email required. Please enter your email')
        return false;
      }

      if (!this.payload.username || this.payload.username.length < 6) {
        this.notifyError('Username required. Please enter your username with at least 6 characters')
        return false;
      }

      if (!this.payload.password || this.payload.password.length < 6) {
        this.notifyError('Password required. Please enter a password with at least 6 characters')
        return false;
      }

      if (this.payload.password !== this.payload.password_confirmation) {
        this.notifyError('Passwords do not match. Please re-enter your password')
        return false;
      }

      if (!this.payload.agreement) {
        this.notifyError('Terms & conditions required. Please agree to the terms of service and privacy policy')
        return false;
      }

      if (!this.clickedOnTerms) {
        this.notifyError('Must read the terms and service. You need to click on the link to terms of service and privacy policy')
        return false;
      }

      return true;
    },

    submit() {
      if (!this.validate()) return;

      this.showLoader('Creating an account for you. Please wait...')

      this.register({...this.payload}).then(response => {
          this.hideLoader()
          if (this.channelDataIsValid) {
            this.createChannel()
          } else {
            this.$router.push({ name: 'dashboard' })
          }
        })
        .catch(error => {
          this.hideLoader()
          this.notifyError(error.message)
        })
    },

    createChannel() {
      this.showLoader('Creating your channel. Please wait...')
      this.createIntent({...this.channelPayload}).then(response => {
          this.hideLoader()
          this.clearState()
          this.$router.push({ name: 'channels.view', params: {intent_id: response.id} })
        })
        .catch(error => {
          this.hideLoader()
          this.notifyError(error.message)
        })
    }
  },

  created() {
    this.loadParams();
  }
}
</script>

<style>

</style>