<template>
    <modal
        :show="show"
        :centered="true"
        :showFooter="false"
        :maxHeight="true"
        okText="Add Note"
        :showOk="false"
        @cancel="cancel">
    
        <h5 class="m-0 font-small font-weight-normal text-capitalize text-truncate p-0" slot="header">
            <div class="d-flex m-0 align-items-center">
                <img :src="merchantUrl" class="avatar-xs circle mr-2" alt="Merchant Logo">
                <div class="">
                    <h5 class="m-0 text-primary">{{ merchantName }}</h5>
                    <h6 class="font-xs text-black">{{ offerName }}</h6>
                </div>
            </div>
        </h5>
    
        <div slot="body" class="p-1 font-small hold-list-item">
            <div class="content">
                <div class="notes p-2">
                    <div class="note pb-2 font-xs" v-for="(history, index) in histories" :key="index">
                        <p class="text-primary text-shadow mb-1">
                           <span class="text-black mr-1">{{ history.created_by ? history.created_by.fullname : 'N/A' }}</span> 
                           <span class="mr-3">@{{ history.created_by ? history.created_by.username : 'N/A' }}</span> 
                           <span class="text-muted font-xs">{{ history.created_at | fromNow }}</span>
                        </p>
                        
                        <p class="text-danger" v-if="history.history_type == 'cancellation'">
                            Cancelled subscription on {{ history.created_at | formattedDateTime }}
                        </p>
                        <p class="text-secondary" v-else-if="history.history_type == 'resume'">
                            Resumed subscription on {{ history.created_at | formattedDateTime }}
                        </p>
                        <p class="m-0" v-else>
                            {{ history.content }}
                        </p>
                    </div>
    
                    <empty title="You haven't added any note" v-if="!histories.length"/>
                </div>
            </div>
        </div>
    
        <div class="d-flex w-100 align-items-center pt-3 elevation-reverse" slot="footer">
            <base-textarea
                typeNumber="4"
                :noLabel="true"
                reference="note"
                :rows="2"
                placeholder="e.g Add notes/comments to this advert"
                v-model="content"
                :error="errorText"
                class="flex-grow-1"
            />
    
            <div class="text-right ml-2">
                <button type="button" class="btn btn-secondary" @click.prevent="submit">
                    Send
                </button>
            </div>
        </div>
    </modal>
    </template>
    
    <script>
    import { get } from 'lodash';
    import { mapActions, mapGetters } from 'vuex';
    export default {
        props: {
            type: {
                type: String,
                required: true
            },
            show: {
                type: Boolean,
                default: false
            },
            merchant: {
                type: Object,
                default: () => ({})
            },
            item: {
                type: Object,
                default: () => ({})
            }
        },
    
        data() {
            return {
                page: 1,
                content: '',
                errorText: '',
                histories: [],
            }
        },
    
        computed: {
            merchantName() {
                return get(this.merchant, 'name', 'N/A');
            },
            merchantUrl() {
                return get(this.merchant, 'logo_url', 'N/A');
            },
            offerName() {
                return get(this.item, 'advert.name', 'N/A');
            },
            itemParams() {
                return {
                    item_id: this.item.id,
                    item_type: this.type,
                }
            }
        },
    
        watch: {
            async show() {
                if (this.item && this.show) {
                    await this.loadHistory({ clear: true })
                    this.syncHistory()
                }
            }
        },
    
        methods: {
            ...mapActions({
                store: 'SmartAlertHistories/store',
                index: 'SmartAlertHistories/index',
                sync: 'SmartAlertHistories/sync'
            }),
    
            cancel() {
                this.content = '';
                this.errorText = '';
                this.$emit('cancel');
            },
    
            submit() {
                if (!this.content) {
                    return;
                }
    
                this.store({...this.itemParams, content: this.content})
                .then(response => {
                    this.histories.push(response.history);
                    this.content = '';
                }).catch(error => {
                    console.log(error);
                })
    
            },
    
            loadHistory({ clear }) {
                const params = {
                    ...this.itemParams,
                    page: this.page,
                }

                this.index(params).then(response => {
                    if (clear) {
                        this.histories = [];
                    }
                    this.histories = [...this.histories, ...response];
                })
                .catch(error => {
                    console.log(error);
                })
            },
    
            syncHistory() {
                this.sync({ ...this.itemParams }).then(response => {
                    this.histories = response;
                    this.loadHistory({ ...this.itemParams, page: 1, clear: true });
                })
                .catch(error => {
                    console.log(error);
                })
            }
        }
    }
    </script>