<template>
    <responsive-width class="carts">
        <h4 class="m-0 mb-4 d-flex justify-content-between align-items-center text-muted">
            Checkout <a href="#" class="font-medium style-metrophobic">#12345</a>
        </h4>

        <div class="row mb-3">
            <div class="col-md-6 col-12">
                <shipping :info="shippingInfo"/>
            </div>
            <div class="col-md-6 col-12">
                <payment 
                    :paymentMethods="paymentMethods" 
                    @payment-method-added="onPaymentMethodAdded"
                    @payment-method-selected="setSelectedPM"/>
            </div>
        </div>

        <div class="total-top bg-white pt-3 pb-3 elevation mb-4">
            <div class="d-flex justify-content-between">
                <span class="font-xs text-muted m-0">Subtotal</span>
                <h5 class="text-muted font-medium">{{ subTotal.formatted }}</h5>
            </div>
            <div class="d-flex justify-content-between">
                <span class="font-xs text-muted m-0">Taxes</span>
                <h5 class="text-muted font-medium">{{ taxes.formatted }}</h5>
            </div>
            <div class="d-flex justify-content-between">
                <span class="font-medium text-muted m-0">Total</span>
                <h5 class="font-weight-bold text-primary font-lg">{{ total.formatted }}</h5>
            </div>
        </div>

        <div class="pay-now d-flex align-items-center">
            <h5 class="total-bottom flex-grow-1 m-0 text-primary">{{ total.formatted }}</h5>
            <button class="pay-btn font-small" @click="payNow">Pay Now</button>
        </div>
    </responsive-width>
  </template>
  
<script>
import {get} from 'lodash';
import filters from '@/mixins/global/filters'
import { mapGetters, mapActions } from 'vuex'
import Payment from '@/views/customer/checkouts/partials/Payment.vue';
import Shipping from '@/views/customer/checkouts/partials/Shipping.vue';

export default {
    components: { 
        Shipping,
        Payment,
    },
    data() {
        return {
            selectedPM: null
        };
    },
    computed: {
        ...mapGetters({
            checkout: 'Checkouts/getCheckout'
        }),

        shippingInfo() {
            return get(this.checkout, 'shipping_info')
        },

        paymentMethods() {
            return get(this.checkout, 'payment_methods', [])
        },

        currency() {
            return get(this.checkout, 'cart.currency', '$');
        },

        subTotal() {
            const sum = get(this.checkout, 'cart.total', 0);

            return {
                sum: sum,
                formatted: this.currency + filters.moneyFormat(sum)
            }
        },

        taxes() {
            const sum = get(this.checkout, 'taxes', 0);
            return {
                sum: sum,
                formatted: this.currency + filters.moneyFormat(sum)
            }
        },

        total() {
            const sum = parseFloat(this.subTotal.sum) + parseFloat(this.taxes.sum)
            return {
                sum: sum,
                formatted: this.currency + filters.moneyFormat(sum)
            }
        }
    },
    methods: {
        ...mapActions({
            getCheckout: 'Checkouts/getCheckout',
            addPM: 'Checkouts/addPaymentMethod',
            confirmPayment: 'Checkouts/confirmPayment'
        }),

        setSelectedPM(pm) {
            this.selectedPM = pm;
        },

        onPaymentMethodAdded(paymentMethod) {
            this.addPM({
                user_id: this.authUser.id,
                pm: paymentMethod
            });
        },

        payNow() {
            this.showLoader(`Processing payment...`);
            const payload = {pm_id: this.selectedPM};
            this.confirmPayment(payload)
                .then((response) => {
                    this.hideLoader();
                    this.showSuccess();
                })
                .catch((error) => {
                    this.hideLoader();
                    this.swalError(error.message, error.error, false)
                })
        },

        showSuccess() {
            const body = `
                <h6 class="mb-3">The order confirmation has been sent to your email address.</h6>

                <div class="row">
                    <div class="col-4">
                        <h5 class="text-primary">$251.93</h5>
                        <span class="text-muted">Total Amount</span>
                    </div>
                    <div class="col-4">
                        <h5 class="text-primary">x5</h5>
                        <span class="text-muted">Items</span>
                    </div>
                    <div class="col-4">
                        <h5 class="text-primary">11/06/24</h5>
                        <span class="text-muted">Est. Delivery</span>
                    </div>
                </div>
            `;
            this.swalSuccess('Thank you for your order!', body, false)
        }
    },
    created() {
        this.getCheckout({user_id: this.authUser.id})
    },
}
</script>

<style>

</style>