<template>
  <div class="gs-mobile-nav responsive-page-width">
    <div class="gs-nav-con">
        <router-link
            :to="{ name: 'dashboard' }"
            href="/dashboard"
            active-class="active"
            class="icon-btn">
            <i class="mdi mdi-home icon mr-1"></i>
            <span>Dashboard</span>
        </router-link>

        <router-link
            :to="{ name: 'list_channels' }"
            href="/channels"
            active-class="active"
            class="icon-btn">
            <i class="mdi mdi-lightbulb-outline icon mr-1"></i>
            <span>Channels</span>
        </router-link>

        <div class="icon-btn plus-holder">
            <router-link
                :to="{ name: 'channels.create' }"
                href="/channels/create"
                class="icon-btn plus">
                <i class="mdi mdi-plus"></i>
            </router-link>
        </div>

        <router-link
            :to="{ name: 'returns' }"
            href="/alerts/returns"
            active-class="active"
            class="icon-btn">
            <i class="mdi mdi-email-outline"></i>
            <span>Smart Alerts</span>
        </router-link>

        <router-link
            :to="{ name: 'interests' }"
            href="/interests"
            active-class="active"
            class="icon-btn">
            <i class="mdi mdi-shape-plus icon mr-1"></i>
            <span>Interests</span>
        </router-link>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>