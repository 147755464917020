<template>
    <div class="status-update elevation-2" :class="{'store-front': storeFront}">

        <profile :advert="advert" :offer="offerCategory" v-if="!noProfile" />

        <div class="media bg-default elevation mt-2">
            <slide-show v-if="advert" :advert="advert" />

            <div class="actions" @click.stop="">
                <div class="dropdown">
                    <button type="button" class="action hold">
                        <i class="mdi mdi-dots-vertical"></i>
                    </button>

                    <div class="options elevation-2">
                        <a href="#" class="font-xs" @click.prevent="hold('private')">
                            Private
                        </a>
                        <a href="#" class="font-xs" @click.prevent="hold('public')">
                            Public
                        </a>
                    </div>
                </div>
            </div>

            <div class=" shop-now">
                <button class="btn" type="button">Shop Now</button>

                <span class="price">
                    $ {{(advert && advert.offer ? advert.offer.price : 0) | moneyFormat}}
                </span>
            </div>
        </div>

        <div class="d-flex buttons">
            <div class="flex-grow-1 d-flex justify-content-center align-items-center">
                <router-link :to="{
                    name: 'view_store_front',
                    params: { posting_id : advert.id},
                    query: {plug: intent_id}
                }" href="javascript:void(0)">Details
                </router-link>
            </div>

            <div class="flex-grow-2 d-flex align-items-center justify-content-end pr-4">
                <a href="#" class="mr-3 d-flex align-items-center text-tertiary like"
                    @click.prevent="interactWithAdvert('like')" v-if="showLike">
                    <span class="font-xs mr-1">{{ no_of_likes | shortenNumber }}</span>
                    <i class="mdi mdi-heart-outline font-big"></i>
                </a>

                <a href="#" @click.prevent="interactWithAdvert('unlike')"
                    class="mr-3 d-flex align-items-center unlike" v-else>
                    <span class="font-xs mr-1">{{ no_of_likes | shortenNumber }}</span>
                    <i class="mdi mdi-heart font-big"></i>
                </a>

                <a href="#" class="mr-3 d-flex align-items-center text-success"
                    @click.prevent="$emit('answer-poll', advert)" v-if="advert.poll">
                    <span class="font-xs mr-1">{{pollVotes | shortenNumber}}</span>
                    <i class="mdi mdi-chart-bar-stacked font-big"></i>
                </a>

                <a href="#" :title="advert.user_shares.length ? `You shared with ${advert.user_shares.length} people` : 'Share Ad'" @click.prevent="$emit('share', advert)"
                    class="item mr-3 d-flex align-items-center text-info">
                    <span class="font-xs mr-1">{{ advert.user_shares.length | shortenNumber }}</span>
                    <i class="mdi mdi-share font-big"></i>
                </a>

                <a href="#" title="Report this ad" @click.prevent="$emit('report', advert)"
                    class="item text-danger mr-3 d-flex align-items-center text-success">
                    <i class="mdi mdi-flag-checkered font-big"></i>
                </a>
            </div>
        </div>

        <!-- <div class="mt-3">
            <div class="thumbs" v-if="relatedAds.length > 1">
                <template v-for="(ad, index) in relatedAds">
                    <img v-if="ad.cover_media && ad.cover_media.mime_type.startsWith('image')" :key="index"
                        :src="ad.cover_media.file_url" class="avatar-lg cursor-pointer" @click="setCurrentAdvert(index)"
                        :class="{'active': index == currentAdvertIndex}" />

                    <video v-if="ad.cover_media && ad.cover_media.mime_type.startsWith('video')" :key="index"
                        :src="ad.cover_media.file_url" class="avatar-lg cursor-pointer" @click="setCurrentAdvert(index)"
                        :class="{'active': index == currentAdvertIndex}" />
                </template>
            </div>
        </div> -->

        <!-- <div class="details d-flex justify-content-between align-items-center mt-3">
            <div class="colors d-flex" v-if="colors.length">
                <div class="avatar-xxs circle mr-1 border" v-for="color in colors[0]" :key="color"
                    :style="{background: color}" :title="color">
                </div>
                <div class="avatar-xxs circle mr-1 bg-default-dark font-xs d-flex justify-content-center align-items-center"
                    v-if="colors.length > 1">
                    {{ colors[1].length }}+
                </div>
            </div>

            <div class="sizes d-flex" v-if="sizes.length">
                <div class="avatar-xxs d-flex justify-content-center align-items-center circle bg-default-dark text-primary mr-1"
                    v-for="size in sizes" :key="size" :title="`Size: ${size}`">
                    {{ size }}
                </div>
            </div>

            <div class="shop-now">
                <button class="btn" type="button">Shop Now</button>

                <span class="price">
                    $ {{(advert && advert.offer ? advert.offer.price : 0) | moneyFormat}}
                </span>
            </div>
        </div> -->

        <!-- <div class="details-section mt-4 mb-5" :class="{'opened': showDetails}">
            <button class="btn title btn-block" @click="toggleDetails">
                Details <i class="mdi" :class="{'mdi-minus': showDetails, 'mdi-plus': !showDetails}"></i>
            </button>
            <div class="items" v-if="showDetails">
                <table class="table m-0">
                    <tbody>
                        <tr v-for="(group, index) in attributes" :key="index">
                            <td :colspan="attribute.col" v-for="(attribute, attr) in group" :key="attr">
                                <span class="name">{{ attribute.name }}</span>
                                <span class="value">{{ attribute.value }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->
    </div>
</template>
<script>
import {chunk} from 'lodash';
import { mapActions } from 'vuex';
import bus from '@/event-bus.js';
import storage from '@/storage.js';
import Profile from './../../hold-list/partials/Profile.vue';
import SlideShow from './SlideShow.vue';

export default {
    props: ['initialAdvert', 'isViewed', 'noProfile', 'storeFront', 'intent_id'],

    data() {
        return {
            mediaIndex: 0,
            showDetails: false,
            relatedAds: [],
            advert: null,
            currentAdvertIndex: null,

            showLike: true,
            no_of_likes: 0
        }
    },

    components: {
        Profile,
        SlideShow
    },

    computed: {
        offerCategory() {
            if (!this.advert || !this.advert.offer) return "";

            let name = this.advert.offer.category.name;

            if (name == 'Buy X Get Y Free') {
                return name.replace('X', this.advert.offer.x_quantity).replace('Y', this.advert.offer.y_quantity);
            } else if (name == 'X amount off') {
                return name.replace('X', this.advert.offer.amount_off);
            } else if (name == 'X % off') {
                return name.replace('X ', this.advert.offer.percent_off);
            } else if (name === 'Fixed Price') {
                if (this.advert.offer.free_shipping_above) {
                    return `Free Shipping Above $${this.advert.offer.free_shipping_above}`;
                }
            }
        },

        pollVotes() {
            if (!this.advert) return;

            let votes = 0;

            if (this.advert.poll) {
                for(let option of this.advert.poll.options) {
                    votes += option.votes;
                }
            }

            return votes;
        }
    },

    methods: {
        ...mapActions({
            remove: 'ChannelStreams/remove',
            delete: 'ChannelStreams/delete',
            holdOrUnhold: 'ChannelStreams/holdOrUnhold',
            interact: 'ChannelStreams/interact',
        }),

        interactWithAdvert(type) {
            if (type == 'like') {
                this.showLike = false;
                this.no_of_likes++;
            } else if (type == 'unlike') {
                this.showLike = true;
                this.no_of_likes--;
            }

            this.interact({
                advert_id: this.advert.id,
                interaction_type: type
            })
                .then(response => {
                    // this.setLikeAndDislike(response)
                })
                .catch(error => null)
        },

        async toggleDetails() {
            if (!this.isLoggedIn) {
                let result = await this.swalConfirm('You need to be logged in to view details', `To interact with ${this.advert.name}, you need to login or create an account with Woonmi`, 'Login Now');

                if (result.value) {
                    this.$emit('saveOnTheFlyData', {
                        action: 'details',
                        advert_id: this.advert.id,
                    });
                }
            } else {
                this.showDetails = !this.showDetails;
            }
        },

        deletedAdvert() {
            if (!this.isLoggedIn) return;

            if (storage.get('hide-delete-update-popup')) {
                this.delete(this.advert.id).catch(error => null)
            } else {
                bus.$emit('delete-status-update', {
                    name: this.advert.name,
                    advert_id: this.advert.id,
                    url: this.advert.file_url
                });
            }
        },

        async hold(visibility) {
            if (!this.isLoggedIn) {
                let result = await this.swalConfirm(
                    'You need to be logged in to add this advert to your hold list',
                    `To interact with ${this.advert.name}, you need to login or create an account with Woonmi`,
                    'Login Now'
                );

                if (result.value) {
                    this.$emit('saveOnTheFlyData', {
                        action: 'hold',
                        advert_id: this.advert.id,
                        visibility: visibility
                    });
                }

                return;
            }

            this.showLoader('Adding to hold list...Please wait');

            const data = {
                visibility,
                type: 'hold',
                advert_id: this.advert.id,
                intent_id: this.intent_id,
            };

            this.holdOrUnhold(data)
                .then(response => {
                    // console.log(response);
                    this.hideLoader()
                })
                .catch(error => {
                    this.hideLoader()
                    if (error.message) {
                        this.swalError(`Could not add to hold list`, error.message, false);
                    }
                })
        },

        init() {
            this.advert = {...this.initialAdvert};
            this.no_of_likes = this.advert.no_of_likes;
            this.showLike = !this.advert.likes || !this.advert.likes.length;

            if (this.initialAdvert) {
                this.relatedAds = [...this.initialAdvert.related_ads];
            }
        },
    },

    mounted() {
    },

    created() {
        this.init();
    }
}
</script>