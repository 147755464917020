<template>
    <div class="section collapsed">
        <!-- <heading title="Which <span>Color(s)</span>?"/> -->

        <div class="p-0 mb-3 color-set section-content">
            <div class="colors">
                <div
                    v-for="(color, ind) in allColors"
                    :class="{ 'active': value.includes(color) }"
                    class="color elevation-2"
                    :data-title="color"
                    :key="ind"
                    :style="{ background: color }"
                    @click="addColor(color)">
                    <i class="mdi mdi-check-all font-2xl"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Heading from './Heading.vue'
import { mapGetters } from 'vuex';
export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        productTypes: {
            type: Array,
            default: () => [],
        },
        activeFeature: {
            type: String,
            default: 'brands'
        }
    },

    components: {
        Heading
    },

    computed: {
        ...mapGetters({
            allColors: 'Channels/getColors'
        })
    },

    methods: {
        addColor(color) {
            let values = [];
            if (this.value.includes(color)) {
                values = this.value.filter((val) => val !== color);
            } else {
                values = [...this.value, color];
            }

            this.$emit('input', values);
        },
    },

    created() {
    },
}
</script>

<style>

</style>