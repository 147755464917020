<template>
    <responsive-width>
        <div class="channels">
          <h1 class="page-title">My<br/>Channels</h1>

          <div class="filter-buttons mb-3">
              <button
                class="btn font-small"
                :class="{ active : params.status === 'active' }"
                @click="params.status = 'active'">Active</button>

              <button
                class="btn font-small"
                :class="{ active: params.status === 'completed' }"
                @click="params.status = 'completed'">Completed</button>
          </div>

          <div class="row">
              <div class="col-12 col-md-6" v-for="(channel, index) in all" :key="channel.id">
                <channel :channel="channel" :index="index"/>
              </div>
          </div>

          <scroll-loader
                :loader-method="loadNextPage"
                :loader-disable="!showLoadMore"
                v-if="pageDetails"/>
        </div>
    </responsive-width>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Channel from './partials/Channel';

export default {
    components: {
        Channel
    },

    data() {
        return {
            showAddIntent: false,
            showViewIntent: false,
            intent: null,
            showFilter: false,

            keyword: '',

            params: {
                status: 'completed'
            },

            showIntents: false,
            loading: false
        }
    },

    computed: {
        ...mapGetters({
            pageDetails: 'Channels/getPageDetails',
            all: 'Channels/getAll'
        })
    },

    watch: {
        'params.status'() {
            this.loadData({ clearState: true })
        }
    },

    methods: {
        ...mapActions({
            index: 'Channels/index',
            show: 'Channels/show'
        }),

        filterStatus(status) {
            this.params.status = status;
            this.loadData({ clearState: true });
        },

        addEditIntent() {
            this.$router.push({ name: 'create_intent' });
        },

        performFiltering() {
            this.loadData({ clearState: true });
        },

        async showIntent(id, params = {}) {
            this.showLoader('Getting intent data... Please wait');

            try {
                let intent = await this.show({ id, params });
                this.hideLoader();
                return intent;
            } catch (error) {
                this.hideLoader();

                return null;
            }
        },

        async editIntent(intent) {
            if (intent) {
                intent = await this.showIntent(intent.id);
            }

            this.intent = intent;
            this.showAddIntent = !!intent;
        },

        async viewIntent(intent) {
            if (intent) {
                intent = await this.showIntent(intent.id, { with_attributes: 1 });
            }

            this.intent = intent;
            this.showViewIntent = !!intent;
        },

        loadNextPage() {
            if (this.showLoadMore) {
                this.loadData(this.loadMore);
            }
        },

        loadData(query) {
            let params = {
                paginate: true,
                per_page: 20,
                ...query,
                ...this.params,
                keyword: this.keyword
            }

            this.loading = true;

            if (query.clearState) {
                this.showIntents = false;
            }

            this.index(params)
                .then(response => {
                    this.loading = false;
                    // this.hideLoader();
                    this.showIntents = true;
                })
                .catch(error => {
                    this.loading = false;
                    // this.hideLoader();
                    this.showIntents = true;
                })
        }
    },

    created() {
        this.loadData({ clearState: true });
    }
}
</script>

<style>

</style>