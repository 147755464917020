<template>
    <router-link :to="{ name: 'channels.view', params: { channel_id: channel.id } }">
        <div  class="channel mb-1" :class="variants[index % 3]">
            <div class="c-summary">
                <div class="cs-name">
                    <h5 class="name">{{ channel.name }}</h5>
                    <p class="desc font-small text-muted text-truncate">
                        {{ productTypes }}
                    </p>
                </div>

                <div class="last-activity">
                    <span class="time font-xs ">
                        {{ channel.status }}
                    </span>
                    <div class="icon">
                        <i class="mdi mdi-check"></i>
                    </div>
                </div>
            </div>

            <div class="ad-items">
                <img v-if="tertiaryBillboard" class="ad-item" :src="tertiaryBillboard"/>
                <img v-if="secondaryBillboard" class="ad-item" :src="secondaryBillboard"/>
                <img v-if="primaryBillboard" class="ad-item" :src="primaryBillboard"/>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: ['index', 'channel'],

    data() {
        return {
            variants: ['var-1', 'var-2', 'var-3'],
        }
    },

    computed: {
        productTypes() {
            return this.channel.product_types.map(productType => productType.name).join(', ');
        },

        primaryBillboard() {
            if (this.channel.billboard && this.channel.billboard.primary)
                return this.channel.billboard.primary.file_url;
        },

        secondaryBillboard() {
            if (this.channel.billboard && this.channel.billboard.secondary)
                return this.channel.billboard.secondary.file_url;
        },

        tertiaryBillboard() {
            if (this.channel.billboard && this.channel.billboard.tertiary)
                return this.channel.billboard.tertiary.file_url;
        }
    },

    mounted() {
        // console.log(this.channel.billboards)
        // console.log(this.primaryBillboard)
    }
}
</script>

<style>

</style>