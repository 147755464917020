import { db } from './index';

const createMany = async (prod_types) => {
    await db.Colors.bulkAdd(prod_types);
}

const getLatest = async () => {
    return await db.Colors.orderBy('id').last();
}

export default {
    createMany,
    getLatest
}