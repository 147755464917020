<template>
    <custom-modal :title="title" @cancel="$emit('cancel')">
        <div class="main-posting bg-white p-2 elevation rounded mb-4">
            <profile :advert="advert" class=""/>

            <div class="tab-1">
                <button
                    type="button"
                    class="tab-1-item"
                    :class="{'active': activeTab == 'media'}"
                    @click.prevent.stop="activeTab = 'media'">
                    Media
                </button>

                <button
                    type="button"
                    class="tab-1-item"
                    :class="{'active': activeTab == 'product_attributes'}"
                    @click.prevent.stop="activeTab = 'product_attributes'">
                    Attributes
                </button>

                <button
                    type="button"
                    class="tab-1-item"
                    :class="{'active': activeTab == 'offer'}"
                    @click.prevent.stop="activeTab = 'offer'">
                    Offer
                </button>

                <button
                    type="button"
                    class="tab-1-item"
                    :class="{'active': activeTab == 'return_policy'}"
                    @click.prevent.stop="activeTab = 'return_policy'">
                    Return Policy
                </button>
            </div>

            <div class="previewer mt-3">
                <img class="media" :src="currentMedia.file_url" v-if="currentMedia.mime_type.startsWith('image')"/>
                <video controls class="media" :src="currentMedia.file_url" v-else/>
            </div>

            <div class="thumbs mt-3">
                <template v-for="(media, index) in mediaFiles">
                    <img
                        v-if="media.mime_type.startsWith('image')"
                        :key="index"
                        :src="media.file_url"
                        class="avatar-md cursor-pointer"
                        @click="mediaIndex = index"
                        :class="{'active': index == mediaIndex}"/>

                    <video
                        v-if="media.mime_type.startsWith('video')"
                        :key="index"
                        :src="media.file_url"
                        class="avatar-md cursor-pointer"
                        @click="mediaIndex = index"
                        :class="{'active': index == mediaIndex}"/>
                </template>
            </div>
        </div>

        <h5 class="p-1 font-weight-bold text-tertiary">Related Items from Merchant</h5>
        <div  class="row no-gutters">
            <div class="col-md-4 col-6 p-1"  v-for="(ad, index) in relatedAds" :key="index">
                <status-update
                    :storeFront="true"
                    :initialAdvert="ad"
                    :noProfile="true"
                    @view="viewAd"/>
            </div>
        </div>
    </custom-modal>
</template>
<script>
import { mapActions } from 'vuex';
import Profile from './../../hold-list/partials/Profile.vue'
import StatusUpdate from './StatusUpdate.vue';

export default {
    props: ['advert'],

    components: {
        Profile,
        StatusUpdate
    },

    data() {
        return {
            relatedAds: [],
            mediaIndex: 0,

            activeTab: 'media'
        }
    },

    computed: {
        title() {
            if (this.advert) return this.advert.name;
        },

        mediaFiles() {
            return this.advert && this.advert.advert_media;
        },

        currentMedia() {
            return this.mediaFiles[this.mediaIndex]
        }
    },

    methods: {
        ...mapActions({
            index: 'ChannelStreams/getRelatedAds'
        }),

        getRelatedAds() {
            this.index({advert_id: this.advert.id})
                .then(response => {
                    this.relatedAds = [...response];
                })
                .catch(error => {
                    console.log(error);
                })
        }
    },

    mounted() {
        this.getRelatedAds();
    }
}
</script>