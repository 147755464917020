<template>
    <router-link class="message" :to="{name: 'view_alerts', params: {type: type, vendor: vendorId}}">
        <div class="label elevation">
            <div class="lcount font-xs style-metrophobic">{{ count }}</div>
            <h6 class="font-xxs text-muted mt-2">
                {{ date | shortenedTime }}
            </h6>
        </div>
        <div class="summary elevation">
            <div class="m-logo _100">
                <img :src="logoUrl"/>
            </div>

            <div class="summary-content">
                <h5 class="s-title">
                    {{ vendorName }}
                    <i v-if="vendorVerified" class="mdi mdi-checkbox-marked-circle verify-icon font-xs"></i>
                </h5>
                <p class="mb-0 text-muted s-desc">{{ vendorSubtitle }}</p>
            </div>

            <div class="expiration">
                <span class="count mb-2">4</span>
                <span class="date text-muted">12/23</span>
            </div>
        </div>
    </router-link>
</template>
<script>
import {get} from 'lodash';

export default {
    props: ['item'],
    computed: {
        vendorId() {
            return get(this.item, 'merchant.id');
        },

        vendorName() {
            return get(this.item, 'merchant.name');
        },

        vendorSubtitle() {
            const parts = []
            const address = get(this.item, 'merchant.address');
            const state = get(this.item, 'merchant.state.name');

            if (address) {
                parts.push(address);
            }
            if (state) {
                parts.push(state);
            }
            
            return parts.join(', ');
        },

        vendorVerified() {
            return get(this.item, 'merchant.is_verified');
        },

        logoUrl() {
            return get(this.item, 'merchant.logo_url');
        },

        type() {
            return get(this.item, 'type');
        },

        count() {
            return get(this.item, 'count');
        },

        date() {
            return get(this.item, 'date');
        }
    }
}
</script>