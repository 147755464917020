import { axios } from './index';

export default {
    /**
     * Get available intent
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('channels', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Store intent
     *
     * @param {Object} data
     * @returns {Object}
     */
    store(data) {
        return axios.post('channels', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    update(id, data) {
        return axios.put(`channels/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    show(id, params) {
        return axios.get(`channels/${id}`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    destroy(id) {
        return axios.delete(`channels/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    getPreferences(params) {
        return axios.get(`channels/preferences`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    saveAttribute(params) {
        return axios.post(`channels/attributes`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    revoveAttribute(id) {
        return axios.delete(`channels/attributes/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    updatePreferences(data) {
        return axios.post(`channels/preferences`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    checkDuplicates(params) {
        return axios.get(`channels/check-duplicates`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    shareIntent(params) {
        return axios.post(`channels/share`, params)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    }
}