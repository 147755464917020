<template>
<responsive-width class="carts">
    <div>
    <div class="total-top elevation">
        <h5 class="font-xs text-center text-black">5 items</h5>
        <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex flex-column">
                <span class="font-xs text-muted m-0">Total Price</span>
                <h5 class="font-weight-bold text-primary font-xl">
                    {{ total.formatted }}
                </h5>
            </div>
            <div>
                <button class="btn btn-secondary" @click="toCheckout">Checkout</button>
            </div>
        </div>
    </div>
    
    <div class="row items">
        <div class="col-6 col-md-3" v-for="(item, index) in items" :key="index">
            <cart-item :index="index" :item="item" @delete-item="onDeleteItem" @update-quantity="onUpdateQuantity"/>
        </div>
    </div>

    <div class="row justify-content-end">
        <div class="col-md-6 col-12">
            <div class="pay-now d-flex align-items-center mt-5 elevation">
                <h5 class="total-bottom flex-grow-1 m-0 text-primary">
                    {{ total.formatted }}
                </h5>
                <button class="pay-btn font-small" @click="toCheckout">Checkout</button>
            </div>
        </div>
    </div>
    </div>
</responsive-width>
</template>

<script>
import {get} from 'lodash';
import filters from '@/mixins/global/filters'
import { mapGetters, mapActions } from 'vuex';
import CartItem from '@/views/customer/carts/partials/CartItem.vue';

export default {
    components: {
        CartItem
    },

    computed: {
        ...mapGetters({
            cart: 'Carts/getCart'
        }),

        items() {
            return Object.values(get(this.cart, 'items', {})).map(item => {
                return {
                    ...item,
                    offer: {
                        ...item.offer,
                        price: item.offer.price,
                        price_formatted: this.currency + filters.moneyFormat(item.offer.price)
                    }
                }
            });
        },

        currency() {
            return get(this.cart, 'currency', '$');
        },

        total() {
            const sum = this.items.reduce((acc, item) => acc + (item.offer.price * item.quantity), 0);
            return {
                sum: sum,
                formatted: this.currency + filters.moneyFormat(sum)
            }
        }
    },

    methods: {
        ...mapActions({
            getCart: 'Carts/getCart',
            removeItem: 'Carts/removeItem',
            updateItem: 'Carts/updateItem'
        }),

        onDeleteItem(id) {
            this.removeItem({user_id: this.authUser.id, id});
        },

        onUpdateQuantity(item) {
            this.updateItem({user_id: this.authUser.id, items: [item]});
        },

        toCheckout() {
            this.$router.push({name: 'checkouts'});
        }
    },

    created() {
        this.getCart({user_id: this.authUser.id});
    }
}
</script>

<style>

</style>