<template>
<div>
    <div class="view-status" :class="{'is-video': isVideo}">
        <story-controls 
            :index="index"
            @cancel="cancel" 
            @setMediaIndex="setMediaIndex"
        />

        <div class="row justify-content-center full-height" v-if="showStatus && advert">
            <div class="col-lg-6 col-md-8 col-sm-12 col-12 full-height status-content">

                <story-header 
                    :updatedAt="advert.updated_at" 
                    :merchant="merchant"
                    @cancel="cancel" 
                    @profile="$emit('profile')">
                    <status-progress
                        :videoFrame="videoFrame"
                        v-for="n in mediaCount"
                        :key="`progress-${n}`"
                        :index="index"
                        :media="currentMedia"
                        @nextMedia="progressNextMedia"
                        :value="n - 1"/>
                </story-header>

                <div class="content-holder" id="content-holder" v-if="currentMedia">
                    <div class="content-image video" v-if="isVideo">
                        <video class="content-video" id="content-video" controls autoplay>
                            <source :type="currentMedia.mime_type">
                        </video>
                    </div>

                    <template v-else>
                        <template v-if="currentPlayType == 'image'">
                            <div class="content-image loader" v-if="loading">
                                <google-loader/>
                            </div>

                            <div class="content-image loader" v-if="showError">
                                <empty
                                    iconClass="text-tertiary"
                                    title="Image could not be loaded" textClass="text-tertiary"/>
                            </div>
                        </template>

                        <div class="content-image preview-poll" v-if="currentPlayType == 'poll'">
                            <poll
                                :poll="currentMedia.poll"
                                @pollUpdated="pollUpdated"
                                @voteCasted="voteCasted"/>
                        </div>

                        <div
                            class="content-image testimonial d-flex align-items-center justify-content-center"
                            v-if="currentPlayType == 'testimonial'">
                            <testimonial :testimonial="currentMedia.testimonial"/>
                        </div>
                    </template>
                </div>

                <story-actions :showLike="showLike" :showDislike="showDislike"/>
            </div>
        </div>

        <div class="actions">
            <div class="row justify-content-center no-gutters">
                <div class="col-lg-6 col-md-8 col-sm-12 col-12">
                    <p class="text-white text-center" v-if="currentMedia && currentMedia.caption">
                        {{ currentMedia.caption }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <report-advert
        :show="showReportModal"
        :advert="advert"
        @cancel="showReportModal = false"/>
</div>
</template>

<script>
import bus from '@/event-bus.js';
import Poll from '@/views/customer/products/story-view/partials/Poll';
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import GoogleLoader from '@/components/shared/GoogleLoader.vue';
import Testimonial from '@/views/customer/products/story-view/partials/Testimonial';
import StatusProgress from '@/views/customer/products/story-view/partials/Progress';
import ReportAdvert from '@/views/customer/products/story-view/partials/ReportAdvert';
import StoryControls from '@/views/customer/products/story-view/partials/StoryControls';
import StoryHeader from '@/views/customer/products/story-view/partials/StoryHeader';
import StoryActions from '@/views/customer/products/story-view/partials/StoryActions';

export default {
    props: {
        advert: {
            type: Object,
            default: () => ({})
        }
    },

    components: {
        Poll,
        StatusProgress,
        GoogleLoader,
        ReportAdvert,
        Testimonial,
        StoryControls,
        StoryHeader,
        StoryActions
    },

    data() {
        return {
            index: 0,
            selectedAdIndex: 0,

            videoFrame: 0,

            loading: true,
            showError: false,
            showStatus: true,

            showLike: true,
            showDislike: true,

            start_time: null,
            viewRecord: null,
            view_breaks: [],
            showReportModal: false,

            pausedIndex: null,
            currentPlayType: 'image'
        }
    },

    computed: {
        ...mapGetters({
            reasons: 'ChannelStreams/getReportReasons'
        }),

        media() {
            let mediaFiles = get(this.advert, 'media_files', []);

            if (mediaFiles.length === 0) return [];

            const testimonial = get(this.advert, 'testimonial', null);

            if (testimonial) {
                mediaFiles.push({
                    id: `testimonial-${Math.random().toString(16).substr(2)}`,
                    mime_type: `testimonial/testimonial`,
                    testimonial: { ...testimonial }
                });
            }

            const poll = get(this.advert, 'poll', null);

            if (poll && get(poll, 'my_votes', []).length === 0) {
                mediaFiles.push({
                    id: `poll-${poll.id}`,
                    mime_type: `poll/poll`,
                    poll: {...poll}
                });
            }

            return mediaFiles;
        },

        isVideo() {
            return this.currentMedia && this.currentMedia.mime_type.startsWith('video')
        },

        isPoll() {
            return this.currentMedia && this.currentMedia.mime_type.startsWith('poll')
        },

        isTestimonial() {
            return this.currentMedia && this.currentMedia.mime_type.startsWith('testimonial')
        },

        currentMedia() {
            if (!this.mediaCount) {
                return null;
            };

            if (this.index >= this.mediaCount) {
                this.index = 0;
            }

            let media;

            if (this.currentPlayType == 'poll') {
                media = this.media[this.mediaCount - 1]
            } else if (this.currentPlayType == 'testimonial') {
                media = this.media[this.mediaCount - 2]
            } else {
                media = this.media[this.index]
            }

            return {
                src: media.file_url,
                duration: 5,
                mime_type: media.mime_type,
                caption: media.caption,
                action: media.action_name,
                lead: media.action_lead,
                poll: media.poll,
                testimonial: media.testimonial
            }
        },

        merchant() {
            return get(this.advert, 'merchant')
        },

        mediaCount() {
            return get(this.advert, 'media_files_count', 0)
        }
    },

    watch: {
        index() {
            this.playCurrentMedia();
        },

        showReportModal() {
            if (this.showReportModal) {
                this.pauseOrPlayMedia('pause')
            } else {
                this.pauseOrPlayMedia('play');
            }
        },

        advert: {
            deep: true,
            immediate: true,
            handler() {
                this.showStatus = false;

                setTimeout(() => {
                    this.startStoryView();

                    this.showStatus = true;
                }, 250);
            }
        }
    },

    methods: {
        ...mapActions({
            updateClicks: 'ChannelStreams/updateClicks',
            interact: 'ChannelStreams/interact',
            storeViews: 'ChannelStreams/createViews',
            updateDuration: 'ChannelStreams/updateViews'
        }),

        voteCasted() {
            console.log('vote casted: start progress');

            bus.$emit('start-status-progress', this.index);
        },

        pollUpdated(poll) {
            console.log(poll);
        },

        pauseOrPlayMedia(type) {
            if (this.isVideo) {
                const video = document.getElementById("content-video");

                if (video) {
                    if (type == 'pause') {
                        video.pause();
                    } else {
                        video.play();
                    }
                }
            } else {
                if (type == 'play' && !isNaN(this.pausedIndex)) {
                    this.setMediaIndex(this.pausedIndex);

                    this.pausedIndex = null;
                }
            }
        },

        reportAdvert() {
            this.showReportModal = true;
        },

        interactWithAdvert(type) {
            if (type == 'like') {
                this.showLike = false;
            } else if (type == 'dislike') {
                this.showDislike = false;
            } else if (type == 'unlike') {
                this.showLike = true;
            } else {
                this.showDislike = true;
            }

            if (type == 'like' && !this.showDislike) {
                this.showDislike = true;
            }

            if (type == 'dislike' && !this.showLike) {
                this.showLike = true;
            }

            this.interact({
                advert_id: this.advert.id,
                interaction_type: type
            })
            .then(response => {
                // this.setLikeAndDislike(response)
            })
            .catch(error => null)
        },

        goto() {
            if (!this.advert) return;

            this.updateClicks({advert_id: this.advert.id}).catch(error => null);

            // window.open(this.advert.action_url, '_blank');
        },

        cancel() {
            this.$emit('cancel');
        },

        progressNextMedia(index) {
            this.setMediaIndex(index);
        },

        setMediaIndex(index) {
            console.log('set media index', index);
            if (this.showReportModal) {
                this.pausedIndex = index;
                return;
            }

            if (index >= 0 && index < this.mediaCount) {
                this.index = index;
            } else {
                this.updateViews();

                let direction = index < this.index ? 'prev' : 'next';

                this.reset();

                this.$emit('navigate', direction);
            }
        },

        loadImage() {
            if (this.isPoll) {
                this.currentPlayType = 'poll';
                return;
            }

            if (this.isTestimonial) {
                this.currentPlayType = 'testimonial';
                bus.$emit('start-status-progress', this.index);
                return;
            }

            this.currentPlayType = 'image';
            let image = new Image();
            image.className = `content-image`;

            let start = (new Date()).getTime();

            image.onload = () => {
                let lag = (new Date()).getTime() - start;

                if (lag) {
                    this.view_breaks.push(lag);
                }

                $('#content-holder').append(image);
                bus.$emit('start-status-progress', this.index);
                this.loading = false;
            };

            image.onerror = () => {
                bus.$emit('start-status-progress', this.index);
                this.loading = false;
                this.showError = true;
            }

            image.src = this.currentMedia.src;
        },

        playCurrentMedia() {
            console.log('play current media');
            if (this.currentMedia) {
                this.loading = true;
                this.showError = false;
                this.videoFrame = 0;

                let classes = [
                    '.content-holder img.content-image'
                ];

                $(classes.join(', ')).remove();

                if (this.isVideo) {
                    this.registerVideoEvent()
                } else {
                    this.loadImage();
                }
            }
        },

        registerVideoEvent() {
            this.$nextTick().then(() => {
                const video = $('.content-video');

                const THIS = this;

                this.videoFrame = 0;

                let start = (new Date()).getTime();

                video.on('timeupdate', function(event){
                    if (isNaN(THIS.videoFrame)) {
                        let lag = (new Date()).getTime() - start;

                        if (lag) {
                            THIS.view_breaks.push(lag);
                        }
                    }

                    if( this.currentTime == this.duration ) {
                        THIS.setMediaIndex(THIS.index + 1);
                    }

                    THIS.videoFrame = (this.currentTime / this.duration) * 100;
                });

                if (video[0]) {
                    video[0].src = this.currentMedia.src;
                    video[0].load();
                }
            });
        },

        startStoryView() {
            this.setLikeAndDislike(this.advert);
            this.reset();

            $('.background-cover').remove();

            let cover = new Image();
            cover.className = `background-cover`;
            
            cover.onload = () => {
                $('.view-status').prepend(cover);
                this.playCurrentMedia();
                this.createViews();
            }

            cover.src = this.merchant.logo_url || this.merchant.logo;
        },

        createViews() {
            this.start_time = (new Date()).getTime();
            this.view_breaks = [];

            this.storeViews({advert_id: this.advert.id})
                .then(response => {
                    this.viewRecord = response;
                })
                .catch(error => null);
        },

        updateViews() {
            if (!this.start_time || this.start_time < 0 || !this.viewRecord) {
                return;
            }

            let lag = this.view_breaks.filter(brk => parseInt(brk)).reduce((accumulator, currentValue) => accumulator + currentValue)

            let duration = parseInt(((new Date()).getTime() - this.start_time - lag) / 1000);

            this.start_time = null;

            this.updateDuration({view_id: this.viewRecord.id, duration})
                .then(response => null)
                .catch(error => null);
        },

        setLikeAndDislike(advert) {
            this.showLike = !advert ||
                !advert.likes ||
                !Array.isArray(advert.likes) ||
                !advert.likes.length;

            this.showDislike = !advert ||
                !advert.dislikes ||
                !Array.isArray(advert.dislikes) ||
                !advert.dislikes.length;
        },

        reset() {
            this.index = 0;
            this.videoFrame = 0;
        }
    },

    mounted() {
        // console.log()
    }
}
</script>