<template>
    <responsive-width :no-padding="true">
        <div class="new-dashboard">
            <div class="account mb-3">
                <div class="profile">
                    <img :src="authUser.image_url  || '/img/user.jpg'"/>
                    <rank-icon rank="silver"/>
                </div>
                <div class="details">
                    <h3 class="greeting">Hello!</h3>
                    <p class="rank">Your Rank: <span class="rank-label">Gold IV</span></p>
                </div>
                <div class="actions">
                    <a href="#">
                        <i class="mdi mdi-settings"></i>
                    </a>
                </div>
            </div>

            <div class="trends mb-3">
                <div class="row no-gutters">
                    <div class="col-4 p-1">
                        <div class="trends-item trending">
                            <span class="name font-small">What's Trending</span>
                            <div class="icon">
                                <i class="mdi mdi-trending-up"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 p-1">
                        <div class="trends-item activity">
                            <span class="name font-small">My <br/> Activities</span>
                            <div class="icon">
                                <i class="mdi mdi-library-books"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-4 p-1">
                        <div class="trends-item rewards">
                            <span class="name font-small">My <br/> Rewards</span>
                            <div class="icon">
                                <i class="mdi mdi-cash-usd"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-6">
                    <my-channels :channels="dashboard.my_channels"/>
                </div>
                <!-- <div class="col-12 col-lg-6">
                    <comments :comments="dashboard.comments"/>
                </div> -->
                <div class="col-12 col-lg-6">
                    <my-holdlists :holdLists="dashboard.hold_list"/>
                </div>
                <div class="col-12 col-lg-6">
                    <friends-holdlists :holdLists="dashboard.friends_hold_list" />
                </div>
                <div class="col-12">
                    <div class="my-interests">
                        <div class="d-flex no-gutters no-wrap" style="overflow-x:scroll">
                            <div class="col-7 col-md-3"
                                v-for="(card, index) in infoCards"
                                :key="index">
                                <info-card :item="card"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </responsive-width>
</template>

<script>
import RankIcon from './rank.vue'
import InfoCard from './partials/InfoCard.vue'
import { mapActions, mapGetters } from 'vuex'
import MyChannels from './partials/MyChannels.vue'
import MyHoldlists from './partials/MyHoldlists.vue'
import FriendsHoldlists from './partials/FriendsHoldlists.vue';
import Comments from './partials/Comments.vue'

export default {
    components: {
        RankIcon,
        InfoCard,
        MyChannels,
        MyHoldlists,
        Comments,
        FriendsHoldlists,
    },
    data() {
        return {
            keyword: '',
            interest_id: '',

            selectedUpdate: null
        }
    },

    computed: {
        ...mapGetters({
            dashboard: 'Dashboards/getCustomerSummary'
        }),

        infoCards() {
            return [
                { title: 'Personal Development', icon: 'personal-development', value: Math.floor(Math.random() * 100) },
                { title: 'Automobiles', icon: 'cars-automobiles', value: Math.floor(Math.random() * 100) },
                { title: 'Technology', icon: 'technology', value: Math.floor(Math.random() * 100) },
                { title: 'Arts & Creative', icon: 'arts-creative', value: Math.floor(Math.random() * 100) },
                { title: 'Personal Development', icon: 'personal-development', value: Math.floor(Math.random() * 100) },
                { title: 'Automobiles', icon: 'cars-automobiles', value: Math.floor(Math.random() * 100) },
                { title: 'Technology', icon: 'technology', value: Math.floor(Math.random() * 100) },
            ]
        }
    },

    methods: {
        ...mapActions({
            getCustomerSummary: 'Dashboards/getCustomerSummary'
        }),

        async getPushPermission() {
            if (!PushManager || !PushManager.getPermissionStatus) {
                return;
            }

            let state = await PushManager.getPermissionStatus();

            if (state == 'prompt') {
                let result = await this.swalConfirm(
                    `Turn On Notifications`,
                    `For better experience, you need to turn on notifications to receive timely information regarding your channels. If you click "Turn On" button, a popup will appear please click to allow Woonmi to send you notifications. If you click "Maybe Later", this popup will show anytime you login until you allow or deny.`, `Turn On Now`, `Maybe Later`
                );
                if (result.value) {
                    this.showLoader('Allowing subscription to notifications...');
                    await PushManager.subscribe()
                        .catch(error => {
                            this.hideLoader();
                            this.swalError(
                                `Sorry, Notification Denied`,
                                `The notification permission was not granted and blocked instead. To allow it, you will need to do so from your browser settings.`,
                                false
                            );
                        });
                    this.hideLoader();
                }
            }
        }
    },

    created() {
        // this.setPheaderTitle('My Dashboard')

        this.getCustomerSummary()
            .then(response => console.log(this.dashboard))
            .catch(error => null);

        setTimeout(() => {
            navigator.serviceWorker.getRegistration()
                .then(serviceWorkerRegistration => {
                    if (serviceWorkerRegistration) {
                        this.getPushPermission();
                    }
                })
                .catch(error => null);
        }, 2000);
    }
}
</script>

<style>

</style>