import notie from 'notie';

notie.setOptions({
    overlayClickDismiss: false
});

export const SUCCESS = 1;
export const WARNING = 2;
export const ERROR = 3;
export const INFO = 4;
export const defaultOptions = {
    position: 'top',
    time: 5,
}

export default {
    info(text, options = {}) {
        notie.alert({
            ...defaultOptions,
            type: INFO,
            text: text,
            ...options,
        });
    },

    force(text, options = {}) {
        return new Promise((resolve) => {
            notie.force({
                ...defaultOptions,
                type: INFO,
                text: text,
                ...options,
                callback: function () {
                    resolve();
                }
            });
        });
    },

    success(text, options = {}) {
        notie.alert({
            ...defaultOptions,
            type: SUCCESS,
            text: text,
            ...options,
        });
    },

    warn(text, options = {}) {
        notie.alert({
            ...defaultOptions,
            type: WARNING,
            text: text,
            ...options,
        });
    },

    error(text, options = {}) {
        notie.alert({
            ...defaultOptions,
            type: ERROR,
            text: text,
            ...options,
        });
    },

    confirmAction(text, options = {}) {
        return new Promise((resolve) => {
            notie.confirm({
                ...defaultOptions,
                cancelText: 'No',
                text: text,
                cancelCallback: function () {
                  resolve(false);
                },
                submitCallback: function () {
                    resolve(true);
                },
                ...options,
              })
        });
    },

    prompt(text, options = {}) {
        return new Promise((resolve, reject) => {
            notie.input({
                ...defaultOptions,
                text: text,
                submitText: 'Continue',
                submitCallback: function (value) {
                    resolve({ cancelled: false,  value });
                },
                cancelCallback: function (value) {
                    resolve({ cancelled: true,  value });
                },
                ...options,
              })
        });
    },

    promptSelect(text, choices, options = {}) {
        return new Promise((resolve, reject) => {
            notie.select({
                ...defaultOptions,
                text: text,
                submitText: 'Continue',
                position: 'bottom',
                choices: choices,
                cancelCallback: function () {
                    resolve(null);
                },
                ...options,
              })
        });
    },
}