import { db } from './index';
import { chunk } from 'lodash';

const parseItemType = (item_type) => {
    switch (item_type) {
        case 'subscription':
            return 'App\\Models\\AdvertSubscription';
        case 'return':
            return 'App\\Models\\OrderReturn';
        default:
            return item_type;
    }
};

export default {
    async index({item_id, item_type, page = 1}) {
        const perPage = 20;
        item_type = parseItemType(item_type);
        let items = await db.SmartAlertHistories.where({item_id, item_type}).toArray();
        items = chunk(items, perPage);
        items = items[page - 1] || [];
        return items;
    },

    async updateOrAdd(items) {
        for (let item of items) {
            await db.SmartAlertHistories.put(item);
        }
    },

    async getLastHistory({item_id, item_type}) {
        item_type = parseItemType(item_type);

        const last = await db.SmartAlertHistories
            .where({item_id, item_type})
            .reverse('id')
            .first();

        return last ? last.id : 0;
    }
}