<template>
    <div class="glide" :id="`glide-${ad.id}`">
      <div data-glide-el="track" class="glide__track">
        <ul class="glide__slides">
          <li class="glide__slide">
            <div class="product" @click="viewAd">
                <div class="p-media">
                    <img ref="currentImg" class="pm-img" :src="media.file_url"/>

                    <div class="thumbnails">
                        <img
                            :src="am.file_url"
                            v-for="(am, index) in mediaFiles"
                            :key="am.id"
                            :class="{ 'active': media && media.id === am.id }"
                            @click.stop.prevent="setMedia(am)"/>
                    </div>
                </div>
                <div class="p-tile">
                    <h4 class="pt-title m-0 mb-1 text-truncate">
                        <img class="avatar-xxs circle mr-1 bg-white" :src="merchantLogo"/>
                        {{ ad.name }}
                    </h4>
                    <p class="pt-desc text-truncate m-0">{{ ad.subtitle || ad.merchant.name }}</p>

                    <div class="d-flex justify-content-between align-items-center mt-2">
                        <span class="pt-price">{{ formattedPrice }}</span>
                        <button type="button" @click.stop="addToCart" class="btn pt-add">
                            <i class="mdi mdi-google-circles-extended text-white font-xl mdi-spin" v-if="addingToCart"></i>
                            <i class="mdi mdi-cart-plus font-xl" v-else></i>
                        </button>
                    </div>
                </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
</template>
<script>
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import Glide from '@glidejs/glide'
import { mapActions } from 'vuex';
import bus from '@/event-bus';

export default {
    props: ['ad', 'channel_id'],

    data() {
        return {
            media: null,

            addingToCart: false,

            swipeStates: {
                start: false,
                moved: false,
                end: false,
            }
        }
    },

    computed: {
        el() {
            return `#glide-${this.ad.id}`
        },

        mediaFiles() {
            return this.ad.media_files;
        },

        merchantLogo() {
            return this.ad.merchant.logo || this.ad.merchant.logo_url;
        },

        formattedPrice() {
            return '$' + this.ad.price;
        }
    },

    watch: {
        media() {
            this.applyThemeColors();
        }
    },

    methods: {
        ...mapActions({
            holdOrUnhold: 'ChannelStreams/holdOrUnhold',
        }),

        addToCart() {
            console.log('Add to cart');
            this.addingToCart = true;
            setTimeout(() => {
                this.addingToCart = false;
            }, 5000);
        },

        setMedia(media) {
            this.media = media;
        },

        viewAd() {
            this.$emit('viewAd', this.ad.id)
        },

        setUpGlide() {
            const glide = new Glide(this.el, { perView: 1 });

            glide.on('swipe.start', (e) => {
                this.swipeStates.start = true;
            });

            glide.on('swipe.move', () => {
                this.swipeStates.moved = true;
            });

            glide.on('swipe.end', (e) => {
                this.swipeStates.end = true;

                const {start, moved, end} = this.swipeStates;

                if (start && moved && end) {
                    if (this.isLoggedIn) {
                        this.hold('private');
                    } else {
                        const button = document.querySelector('.create-channel-info');
                        if (button) {
                            button.scrollIntoView({behavior: 'smooth', block: 'center'});
                        }
                    }
                }

                this.swipeStates.start = false;
                this.swipeStates.moved = false;
                this.swipeStates.end = false;
            });

            glide.mount();
        },

        async hold(visibility = 'private') {
            if (!this.isLoggedIn) {
                return;
            }

            this.showLoader('Adding to hold list...Please wait');

            const data = {
                visibility,
                action: 'hold',
                advert_id: this.ad.id,
                channel_id: this.channel_id,
            };

            this.holdOrUnhold(data)
                .then(response => {
                    this.hideLoader()
                    this.notifySuccess(`${this.ad.name} added to hold list`);
                })
                .catch(error => {
                    console.log(error);
                    this.hideLoader()
                    if (error.message) {
                        this.notifyError(`Could not add to hold list. ${error.message}`);
                    } else {
                        this.notifyError('Could not add to hold list. Please try again');
                    }
                })
        },

        async applyTheme() {
            try {
                let palette = this.media.color_palette;

                if (!palette) {
                    palette = await this.getImageColorPalette(this.$refs.currentImg);
                }

                if (palette && palette.main) {
                    const {main: color} = palette;
                    const { currentImg } = this.$refs;

                    currentImg.style.background = `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
                }
            } catch (error) {
                console.log(error);
            }
        },

        setBackground(palette) {
            console.log({palette})
        },

        async applyThemeColors() {
            if (!this.$refs.currentImg) return;

            this.$refs.currentImg.crossOrigin = "Anonymous"

            if (this.$refs.currentImg.complete) {
                await this.applyTheme();
            } else {
                this.$refs.currentImg.onload = async () => {
                    await this.applyTheme();
                }
            }
        }
    },

    mounted() {
        this.setUpGlide();
    },

    created() {
        this.media = this.ad.cover;
    }
}
</script>