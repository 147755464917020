<template>
    <Products
        :ads="ads"
        :pageDetails="pageDetails"
        @next-page="loadUpdates"
        @viewAd="viewAd"
        @trashed="onTrashed"
        @update-channel="onUpdateChannel"
        :status="status"
        :channel="channel"
        :productTypes="productTypes" />
</template>

<script>
import bus from '@/event-bus';
import Products from '@/views/customer/products/Index.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['channel', 'productTypes'],

    components: {
        Products,
    },

    data() {
        return {
            status: 'new',
        }
    },

    computed: {
        ...mapGetters({
            ads: 'ChannelStreams/getAll',
            pageDetails: 'ChannelStreams/getPageDetails'
        }),


        channel_id() {
            return this.channel ? this.channel.id : null;
        }
    },

    methods: {
        ...mapActions({
            index: 'ChannelStreams/index',
            removeUpdate: 'ChannelStreams/remove'
        }),

        viewAd(id) {
            bus.$emit('show-product-details-popup', id);
        },

        onUpdateChannel(params) {
            this.emitEvent('update-channel', params, [ () => this.loadUpdates({ clearState: true }) ]);
        },

        loadUpdates(query) {
            let params = {
                ...query,
                status: this.status,
                channel_id: this.channel_id
            }

            if (params.clearState) {
                this.showLoader(`Getting ${this.status} updates... Please wait`);
            }

            this.index(params)
                .then(response => {
                    this.hideLoader();
                    this.readImpressions();
                })
                .catch(error => {
                    this.hideLoader();
                })
        },


        loadNextPage() {
            if (this.showLoadMore) {
                this.loadUpdates(this.loadMore);
            }
        },

        onTrashed() {
            this.status = 'viewed'
            this.loadUpdates({ clearState: true})
        }
    },

    created() {
        this.loadUpdates({ clearState: true });
    }
}
</script>

<style></style>