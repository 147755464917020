<template>
  <responsive-width>
    <div class="alerts">
      <div class="tabs mb-3">
        <router-link 
          v-for="tab in tabs" 
          :key="tab.route" 
          :to="{name: tab.route}" 
          class="tab-item font-small flex-grow-1 text-capitalize"
          active-class="active">
          {{ tab.name }}
          <!-- <span class="badge badge-primary">55</span> -->
        </router-link>
      </div>

      <router-view></router-view>
    </div>
  </responsive-width>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        { route: 'returns', name: 'Returns' },
        { route: 'subscriptions', name: 'Subscriptions' },
        { route: 'offers', name: 'Offers' },
        { route: 'warranties', name: 'Warranties' },
        // { route: 'shipments', name: 'Shipments'}
      ]
    }
  },
  computed: {
    activeTab() {
      return this.$route.name
    }
  },
  methods: {},
  created() {}
}
</script>

<style>

</style>