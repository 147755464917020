<template>
    <question-answer>
        <question>
            <span slot="progress">
                <span class="font-weight-bold font-big">Objective</span>
            </span>
            <div ref="title" class="font-5xl font-thin text-muted"></div>
        </question>


        <answers :showOptions="showOptions">
            <div class="objective-form">
                <div class="options">
                    <div :class="`option font-medium bc-${i%3}`" v-for="(objective, i) in objectives" @click.prevent="goNext(objective.id)">
                        {{ objective.name }}                 
                    </div>
                </div>
            </div>
        </answers>
    </question-answer>
</template>

<script>
import TypeIt from "typeit";
import { mapActions, mapGetters } from 'vuex';
import Answers from '@/views/customer/channels/create/stages/partials/Answers.vue';
import Question from '@/views/customer/channels/create/stages/partials/Question.vue';
import QuestionAnswer from '@/views/customer/channels/create/stages/partials/QuestionAnswer.vue';
import LetsGo from '@/views/customer/channels/create/stages/LetsGo.vue';

export default {
    components: {
        Answers,
        Question,
        QuestionAnswer,
        LetsGo
    },

    data() {
        return {
            showOptions: false,
        }
    },

    computed: {
        ...mapGetters({
            objectives: 'ShoppingObjectives/getAll',
        }),

        objective: {
            get() {
                return this.$store.getters['CreateChannels/getObjective'];
            },
            set(value) {
                this.setValue({ key: 'objective', value });
            }
        }
    },

    methods: {
        ...mapActions({
            index: 'ShoppingObjectives/index',
            setValue: 'CreateChannels/setValue',
            checkDuplicates: 'Channels/checkDuplicates',
            preseed: 'CreateChannels/preseed',
        }),

        typeHeading() {
            const objective = `<span class="font-5xl text-primary">objective</span>?`;

            const instance = new TypeIt(this.$refs.title, {
                speed: 20,
                startDelay: 100,
                cursor: false,
                afterComplete: async (instance) => {
                    this.showOptions = true;
                },
            })
            .empty()
            .type(`What is your <br/> overall shopping <br/>`);

            if (Math.floor(Math.random() * 2)) {
                instance.type(`objective?`)
                        .delete(10, { speed: 200 })
                        .type(objective)
            } else {
                instance.type(objective);
            }

            instance.go();
        },

        async goNext(item) {
            if (this.isLoggedIn) {
                const channel = await this.checkDuplicates({objective: item});
    
                if (channel && channel.id) {
                    const res = await this.confirm(`
                        You already have an active channel with the same objective. 
                        Do you want to update it?`, {
                            cancelText: 'No, Create New Channel',
                            submitText: 'Yes, Update Channel',
                        }
                    );
    
                    if (res === true) {
                        this.preseed(channel);
                    } else {
                        this.preseed(null);
                    }
                }
            }

            this.objective = item;

            await this.getOptionalSpecificObjective();
            
            setTimeout(() => {
                this.$emit('next', 'productTypes');
            }, 0);
        },

        async getOptionalSpecificObjective() {
            const yes = await this.confirm(`Do you have a specific objective in mind?`);
            
            if (yes) {
                const {cancelled, value} = await this.prompt(`Enter Optional Specific Objective`, {
                    placeholder: 'e.g Improve aging skin',
                    type: 'text'
                })
                
                this.setValue({
                    key: 'specificObjective', 
                    value: value || ''
                });
            }
        },
    },

    mounted() {
        setTimeout(() => {
            this.typeHeading();
        }, 0);
    },

    created() {
        this.index();
    }
}
</script>