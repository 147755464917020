<template>
    <responsive-width>
        <div class="mt-2 d-flex" style="overflow: auto;">
            <span
                class="text-truncate font-xs p-2 m-1 round text-center bg-default-dark text-primary"
                style="min-width: 100px;"
                v-for="(type, index) in productTypes" :key="index">
                {{ type.name }}
            </span>
        </div>

        <div class="mt-3" v-if="channel">
            <view-ads 
                :channel="channel"
                :productTypes="productTypes"
                @update-channel="updateChannel"/>
        </div>
    </responsive-width>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ViewAds from '@/views/customer/channels/view/Ads.vue';

export default {
    props: ['channel_id'],

    components: {
        ViewAds,
    },

    data() {
        return {
            channel: null
        }
    },

    computed: {
        productTypes() {
            if (!this.channel || !this.channel.product_types) return [];
            return this.channel.product_types;
        },
    },

    methods: {
        ...mapActions({
            show: 'Channels/show',
            update: 'Channels/update',
            setPHeaderTitle: 'setPHeaderTitle'
        }),

        updateChannel({ payload, callbacks }) {
            payload = {
                id: this.channel_id,
                data: { ...payload }
            }
            
            this.update(payload)
                .then(response => {
                    this.channel = response;
                    
                    for (let callback of callbacks) {
                        callback();
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },

        getChannel() {
            this.showLoader(`Getting channel details... Please wait`);

            this.show(this.channel_id)
                .then(response => {
                    this.hideLoader();
                    this.channel = response;
                })
                .catch(error => {
                    this.hideLoader();
                    console.log(error);
                })
        }
    },

    created() {
        this.getChannel();
    }
}
</script>