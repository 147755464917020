<template>
    <div>
        <status-interests v-model="interest_id" v-if="isInterestBased" />

        <div class="mb-4 p-1 text-right">
            <button class="btn btn-default-dark text-primary" @click="showSnooze = true">
                <i class="mdi mdi-bell"></i>
                Snooze Settings
            </button>
        </div>

        <div class="row" id="all-updates">
            <div class="col-md-4 mb-3" v-for="(status, index) in all" :key="`${index}-${status.id}`">
                <transition-group name="swipe">
                    <status-update
                        :key="status.id"
                        :initialAdvert="status"
                        :isViewed="isViewed"
                        :intent_id="intent_id"
                        @view="ad => viewAdvert(ad, index)"
                        @saveOnTheFlyData="saveOnTheFlyData"
                        @report="ad => advertToReport = ad"
                        @share="ad => advertToShare = ad"
                        @answer-poll="ad => advertPoll = ad"/>
                </transition-group>
            </div>
        </div>

        <empty :title="emptyTitle" v-if="!all.length" />

        <scroll-loader :loader-method="loadNextPage" :loader-disable="!showLoadMore" />

        <bottom-tab :items="bottomNavItems" v-model="status" v-if="isLoggedIn" />

        <transition name="router">
            <view-status v-if="selectedUpdate" :advert="selectedUpdate" @cancel="selectedUpdate = null;"
                @navigate="navigate" />
        </transition>

        <delete-update-popup />

        <report-advert
            :show="!!advertToReport"
            :advert="advertToReport"
            @cancel="cancelReportAd"
            @advert-reported="id => excludedAds.push(id)"/>

        <snooze-settings
            :show="showSnooze"
            @cancel="showSnooze = false"/>

        <share-advert
            :show="!!advertToShare"
            :advert="advertToShare"
            @cancel="cancelShareAd"/>

        <answer-poll
            :show="!!advertPoll"
            :advert="advertPoll"
            @cancel="advertPoll = null"/>
    </div>
</template>
<script>
import {debounce} from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import ViewStatus from './partials/ViewStatus';
import ViewStoreFront from './partials/StoreFront';
import StatusInterests from './partials/Interests';
import StatusUpdate from './partials/StatusUpdate';
import BottomTab from '@/components/navs/BottomTab';
import DeleteUpdatePopup from './partials/DeleteUpdatePopup.vue';
import ReportAdvert from './partials/ReportAdvert';
import ShareAdvert from './partials/ShareAdvert';
import AnswerPoll from './partials/AnswerPoll.vue';
import SnoozeSettings from './partials/SnoozeSettings.vue';

export default {
    props: ['type', 'intent_id', 'onthefly'],

    components: {
        BottomTab,
        StatusUpdate,
        ViewStatus,
        StatusInterests,
        DeleteUpdatePopup,
        ViewStoreFront,
        ReportAdvert,
        AnswerPoll,
        ShareAdvert,
        SnoozeSettings
    },

    data() {
        return {
            showSnooze: false,

            status: 'new',
            keyword: '',
            interest_id: '',

            selectedUpdate: null,
            advertIndex: null,

            advertToReport: null,
            advertToShare: null,
            advertPoll: null,

            excludedAds: [],

            bottomNavItems: [
                {
                    id: 'new',
                    name: 'New Ads',
                    description: `Not yet viewed`
                },
                {
                    id: 'viewed',
                    name: 'Recently Viewed',
                    description: `Within 24 hours`
                },
            ]
        }
    },

    computed: {
        ...mapGetters({
            updates: 'ChannelStreams/getAll',
            pageDetails: 'ChannelStreams/getPageDetails'
        }),

        all() {
            return this.updates.filter(ad => !this.excludedAds.includes(ad.id));
        },

        emptyTitle() {
            return "No ads found"
        },

        isInterestBased() {
            return this.type && this.type.toLowerCase() == 'interest';
        },

        isIntentBased() {
            return this.type && this.type.toLowerCase() == 'intent';
        },

        availableStatuses() {
            return this.bottomNavItems.map(item => item.id);
        },

        interests() {
            if (!this.authUser.interest_types || !this.authUser.interest_types.length) return [];

            return this.authUser.interest_types;
        },

        isViewed() {
            return this.status == 'viewed';
        }
    },

    watch: {
        status() {
            if (this.$route.query.status != this.status) {
                this.$router.replace(`?status=${this.status}`);
            }

            this.loadUpdates({clearState: true});
        },

        interest_id() {
            this.loadUpdates({clearState: true});
        }
    },

    methods: {
        ...mapActions({
            index: 'ChannelStreams/index',
            removeUpdate: 'ChannelStreams/remove',
            storeImpressions: 'Campaigns/storeImpressions',
            getReportReasons: 'ChannelStreams/getReportReasons',
        }),

        cancelReportAd() {
            this.advertToReport = null;
        },

        cancelShareAd() {
            this.advertToShare = null;
        },

        saveOnTheFlyData(params) {
            if (this.isLoggedIn) return;

            params = {
                ...params,
                attributes: this.onthefly,
                product_type_name: this.$route.query.q
            }

            sessionStorage.setItem('last-onthefly-data', JSON.stringify(params));

            this.$router.push({name: 'login'});
        },

        viewAdvert(advert, index) {
            if (!advert) return;
            console.log(advert);
            this.selectedUpdate = advert;
            this.advertIndex = index;
        },

        navigate(direction) {
            if (!this.isLoggedIn) return;

            if (this.selectedUpdate && this.status == 'new') {
                /* Remove viewed campaign from store */
                this.removeUpdate(this.selectedUpdate.id);
            };
        },

        loadNextPage() {
            if (this.showLoadMore) {
                this.loadUpdates(this.loadMore);
            }
        },

        loadUpdates(query) {
            let params = {
                ...query,
                status: this.status,
                type: this.type,
                intent_id: this.intent_id,
                interest_id: this.interest_id,
            }

            if (this.onthefly) {
                delete params.status;
                delete params.type;
                delete params.intent_id;
                delete params.interest_id;

                params = {
                    ...params,
                    ...this.onthefly,
                    onthefly: true
                }
            }

            if (params.clearState) {
                this.showLoader(`Getting ${this.status} updates... Please wait`);
            }

            this.index(params)
                .then(response => {
                    this.hideLoader();

                    this.readImpressions();
                })
                .catch(error => {
                    this.hideLoader();
                })
        },

        saveImpressions(ids) {
            if (!this.isLoggedIn) return;

            this.storeImpressions({ids})
                .then(response => null)
                .catch(error => console.log(error));
        },

        readImpressions: debounce(function () {
            if (!this.isLoggedIn) return;

            let ids = [];

            $('.status-update:not(.impression)').each((index, el) => {
                let id = $(el).attr('id');

                if (id && this.isInViewport($(el))) {
                    id = parseInt(id.replace('swipable_id_', ''));

                    if (id) {
                        ids.push(id);
                    }
                }
            });

            if (ids.length) {
                this.saveImpressions(ids);
            }
        }, 1000),

        isInViewport (el) {
            if (!this.isLoggedIn) return;

            if (typeof jQuery === "function" && el instanceof jQuery) {
                el = el[0];
            }

            var rect = el.getBoundingClientRect();

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
            );
        }
    },

    created() {
        if (this.isLoggedIn) {
            let status = this.$route.query.status;

            if (status && this.availableStatuses.includes(status)) {
                if (this.status != status) {
                    this.status = status;
                    return;
                }
            }

            this.getReportReasons().catch(error => null);
        }

        this.loadUpdates({clearState: true});
    },

    mounted() {
        if (this.isLoggedIn) {
            window.addEventListener('scroll', this.readImpressions)
        }
    }
}
</script>