<template>
    <div class="login-page v-stretch">
        <div class="row v-stretch">
            <div class="col-md-12 v-stretch d-flex flex-column justify-content-center align-items-center  p-5">
                <div class="mb-5 title">
                    <logo/>
                </div>

                <div class="row justify-content-center w-100">
                    <div class="col-md-5 col-10 p-0">
                        <form @submit.prevent="submit" method="POST">
                            <div class="form-group">
                                <i class="mdi mdi-account icon"></i>
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Username or Email or Phone"
                                    name="identity"
                                    v-model="credentials.identity"/>
                                <span class="text-danger font-xs">{{ validationErrors.identity }}</span>
                            </div>

                            <div class="row justify-content-center mt-5">
                                <div class="col-12">
                                    <button type="submit" class="btn font-medium sign-in btn-block elevation">
                                        RESET PASSWORD
                                    </button>
                                </div>
                            </div>

                            <div class="text-center mt-5 signup">
                                <p>Remebered your password?</p>
                                <router-link :to="{name: 'login'}">
                                    <a class="font-medium" href="#">Login</a>
                                </router-link>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import OtherLinks from './partials/PageLinks';
import Heading from './partials/GuestHeading';

export default {
    components: {
        Heading,
        OtherLinks
    },

    data() {
        return {
            credentials: {
                identity: '',
            },
            validationErrors: {
                identity: '',
            },
        }
    },

    methods: {
        ...mapActions({
            forgotPassword: 'Authentications/forgotPassword'
        }),

        submit() {
            if (!this.credentials.identity) {
                this.validationErrors.identity = 'Username, Email or Phone is required';
                return;
            }

            this.validationErrors.identity = '';

            this.showLoader('Sending Reset Link... Please wait');

            this.forgotPassword(this.credentials)
                .then(response => {
                    this.hideLoader();

                    if (response.message) {
                        this.swalSuccess(response.message);
                    }

                    this.goLogin();
                })
                .catch(error => {
                    this.hideLoader();
                    this.buildValidationErrors(error.errors);
                });
        },

        goLogin() {
            this.$router.push({name: 'login'});
        }
    },

    created() {
        if (this.isLoggedIn) {
            this.$router.push({name: 'dashboard'});
        }
    }
}
</script>