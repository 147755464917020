<template>
    <div class="bg-white p-3 rounded">
        <h3 class="text-danger font-xl">Sorry to see you go</h3>
        <h4 class="font-medium mb-5">
            Completing this form will schedule your account for deletion. 
            You will have 30 days to cancel the deletion process by simply logging back in.
        </h4>

        <form @submit.prevent="submit">
            <base-textarea 
                typeNumber="4" 
                label="Please tell us why..."
                v-model="payload.reason"
            />
    
            <button 
                type="submit" 
                class="btn font-medium btn-danger btn-block mt-5">
                CLOSE ACCOUNT
            </button>
        </form>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            payload: {
                reason: ''
            }
        }
    },

    methods: {
        ...mapActions({
            closeAccount: 'Authentications/closeAccount',
            logout: 'Authentications/localLogout'
        }),

        async submit() {
            if (!this.payload.reason) return;

            const {cancelled, value} = await this.prompt(`Enter your password`.toUpperCase(), {
                placeholder: 'e.g SuperSecretPassword',
                type: 'password'
            })

            if (cancelled) return;

            this.showLoader(`Closing account... Please wait.`);
            this.closeAccount({...this.payload, password: value})
                .then((res) => {
                    console.log(res);
                    this.hideLoader();
                    this.notifyInfo(res.message);
                    this.logout();
                })
                .catch(() => {
                    this.hideLoader();
                    this.notifyError('An error occurred while closing your account. Please try again later.');
                })
        }
    }
}
</script>