import { axios } from './index';

export default {
    getCart(params) {
        return axios.get('carts', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    addItems(data) {
        return axios.post('carts', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    updateItem(data) {
        return axios.put(`carts`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    removeItem(id) {
        return axios.delete(`carts/items/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    }
}