<template>
    <div>
        <div class="row justify-content-end">
        <div class="col-md-4">
          <div class="search-form">
              <div class="form-group">
                  <input type="text" v-model="keyword" class="form-control" placeholder="enter search term"/>
                  <button type="submit" class="btn sf-submit">
                      <i class="mdi mdi-magnify"></i>
                  </button>
              </div>
          </div>
        </div>
      </div>

      <div class="row messages">
          <div class="col-md-6" v-for="(item, index) in all" :key="index">
            <alert-item :item="item" type="warranties"/>
          </div>
      </div>
      <infinite-loading @infinite="loadData"></infinite-loading>
    </div>
</template>
<script>
import { debounce } from 'lodash';
import {mapActions, mapGetters} from 'vuex';
import AlertItem from '@/views/customer/alerts/partials/AlertItem.vue';

export default {
    components: {
        AlertItem
    },

    data() {
        return {
            keyword: '',
            all: [],
            page: 1,
        }
    },

    watch: {
        keyword: debounce(function () {
            this.page = 1;
            this.all = [];
            this.loadData();
        }, 250)
    },

    methods: {
        ...mapActions({
            index: 'SmartAlerts/index',
            sync: 'SmartAlerts/sync'
        }),

        loadData(loader) {
            let params = { 
                keyword: this.keyword, 
                type: 'warranties', 
                page: this.page
            };

            this.index(params).then(alerts => {
                loader && loader.loaded();

                if (alerts.length === 0) {
                    loader && loader.complete();
                }

                this.page += 1;
                this.all = [...this.all, ...alerts]
            }).catch(error => {
                loader && loader.error();
            });
        }
    },

    created() {
        this.sync({type: 'warranties'}).then(response => {
            this.loadData();
        });
    }
}
</script>