import { db } from './index';

const createMany = async (prod_types) => {
    await db.ShoppingObjectives.bulkAdd(prod_types);
}

const getLatest = async () => {
    return await db.ShoppingObjectives.orderBy('id').last();
}

const getAll = async () => {
    return await db.ShoppingObjectives.toArray();
};

export default {
    createMany,
    getLatest,
    getAll,
}