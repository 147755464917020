import { axios } from './index';

export default {
    index(params) {
        return axios.get('postings', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    show(id) {
        return axios.get(`postings/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    destroy(id) {
        return axios.delete(`postings/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },
}