<template>
    <div>
        <CreateChannel :activeIndex="activeIndex" @submit="onSubmit"/>
    </div>
</template>
<script>
import CreateChannel from '@/views/customer/channels/create/Index.vue';

export default {
    name: "GettingStarted",

    components: {
        CreateChannel
    },

    computed: {
        activeIndex() {
            return parseInt(this.$route.query.idx) || 0;
        }
    },

    methods: {
        onSubmit(params) {
            this.$router.push({ 
                name: 'getting_started.products'
            });
        }
    }
}
</script>