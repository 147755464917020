<template>
    <div class="login-page v-stretch">
        <div class="row v-stretch">
            <div class="col-md-12 v-stretch d-flex flex-column justify-content-center align-items-center">
                <div class="mb-3 title">
                    <logo/>
                </div>

                <div class="row justify-content-center w-100">
                    <div class="col-md-5 col-10 p-4">
                        <form @submit.prevent="submit" method="POST">
                            <p class="text-danger font-xs" v-if="showLoginFailed">
                                Login failed. Please check your credentials and try again.
                            </p>

                            <div class="form-group">
                                <i class="mdi mdi-account icon"></i>
                                <input
                                    class="form-control"
                                    type="text"
                                    placeholder="Username or Email or Phone"
                                    name="identity"
                                    v-model="credentials.identity"/>
                                <span class="text-danger font-xs">{{ validationErrors.identity }}</span>
                            </div>

                            <div class="form-group">
                                <i class="mdi mdi-lock icon"></i>
                                <input
                                    class="form-control"
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    ref="password"
                                    v-model="credentials.password"/>
                                    <span class="text-danger font-xs">{{ validationErrors.password }}</span>
                                <button type="button" class="btn password-toggle" @click="togglePassword">
                                    <i class="mdi mdi-eye" v-if="!showPassword"></i>
                                    <i class="mdi mdi-eye-off" v-else></i>
                                </button>
                            </div>

                            <div class="d-flex justify-content-between mt-5">
                                <label class="checkbox cursor-pointer d-flex align-items-center text-black-fade">
                                    <input type="checkbox" name="remember" v-model="credentials.remember" class="mr-1"/>
                                    <span class="check"></span>
                                    Keep me logged in
                                </label>
                                <router-link :to="{name: 'forgot_password'}">
                                    <a href="#">Forgot Password ?</a>
                                </router-link>
                            </div>

                            <div class="row justify-content-center mt-5">
                                <div class="col-12">
                                    <button type="submit" class="btn font-medium sign-in btn-block elevation">
                                        LOGIN
                                    </button>
                                </div>
                            </div>

                            <div class="buttons no-gutters row mt-5">
                                <div class="col-6 pr-2">
                                    <button type="button" @click="sso('facebook')" class="btn btn-block facebook elevation text-no-wrap">
                                        <i class="mdi mdi-facebook"></i>
                                        <span class="font-x">FACEBOOK</span>
                                    </button>
                                </div>
                                <div class="col-6 pl-2">
                                    <button type="button" @click="sso('google')" class="btn btn-block google elevation">
                                        <i class="mdi mdi-google-plus"></i>
                                        <span>GOOGLE</span>
                                    </button>
                                </div>
                            </div>

                            <div class="text-center mt-5 signup">
                                <p>Not a member yet?</p>
                                <router-link :to="{name: 'getting_started', params: {stage: 'moods'}}">
                                    <a class="font-medium" href="#">Get Started</a>
                                </router-link>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>

        <verify-code
            :show="showVerify2FA"
            :twoFaMsg="twoFaMsg"
            :isSetup="false"
            @success="onVerify2FA"
            @cancel="showVerify2FA = false"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VerifyCode from '@/views/common/authentication/partials/Verify2FA.vue';

export default {
    name: 'Login',

    components: {
        VerifyCode,
    },

    data() {
        return {
            showPassword: false,
            showVerify2FA: false,
            showLoginFailed: false,
            twoFaMsg: '',

            credentials: {
                identity: '',
                password: '',
                remember: Number(localStorage.getItem('remember')),
            },
            validationErrors: {
                identity: '',
                password: '',
            },
        }
    },

    watch: {
        'credentials.remember'() {
            const rem = Number(this.credentials.remember);
            localStorage.setItem('remember', rem);
            this.setStorage(rem);
        }
    },

    methods: {
        ...mapActions({
            login: 'Authentications/login',
            ssoLogin: 'Authentications/socialLoginGetRedirectUrl',
            setStorage: 'Authentications/setStorage',
        }),

        signup() {
            this.$router.push({name: 'register'});
        },

        submit(code = '') {
            this.showLoginFailed = false;
            this.showLoader('Authenticating... Please wait');

            this.validationErrors = {
                identity: '',
                password: '',
            };

            if (typeof code === 'object') {
                code = '';
            }

            this.twoFaMsg = '';

            this.login({...this.credentials, code})
                .then(response => {
                    this.hideLoader();
                    this.showVerify2FA = false;
                    setTimeout(() => {
                        this.goHome();
                    }, 0);
                })
                .catch(error => {
                    this.hideLoader();
                    if (error.two_fa) {
                        this.handleTwoFa(error.two_fa);
                    } else {
                        this.showLoginFailed = true;
                        this.buildValidationErrors(error.errors);
                    }
                });
        },

        goHome() {
            let route = null;

            const redirect = this.$route.query.redirect;

            if (this.authUser.password_changed_at === null) {
                route = {name: 'secure_account', query: {redirect}};
            } else if (redirect) {
                route = redirect;
            } else {
                route = {name: `dashboard`};
            }

            if (route) {
                this.$router.push(route);
            }
        },

        handleTwoFa(msg) {
            this.showVerify2FA = true;
            if (msg == 'invalid') {
                this.twoFaMsg = 'invalid';
            } else {
                this.twoFaMsg = '';
            }
        },

        onVerify2FA({code}) {
            this.submit(code);
        },

        togglePassword() {
            this.showPassword = !this.showPassword;

            if (this.showPassword) {
                this.$refs.password.setAttribute('type', 'text');
            } else {
                this.$refs.password.setAttribute('type', 'password');
            }
        },

        sso(provider) {
            this.showLoader('Redirecting... Please wait');
            this.ssoLogin(provider)
                .then(response => {
                    this.hideLoader();
                    console.log(response);
                    window.location.href = response.url;
                })
                .catch(error => {
                    console.log(error);
                    this.hideLoader();
                });
        },
    },

    created() {
        if (this.isLoggedIn) {
            this.goHome();
        }
    }
}
</script>