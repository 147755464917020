import Portal from '@/views/common/layouts/Master.vue';
import Settings from '@/views/common/settings/Index.vue';
import Notifications from '@/views/common/notifications/Index.vue';
import ViewChannel from '@/views/customer/channels/view/Index.vue';
import CreateChannel from '@/views/customer/channels/CreateChannel.vue';
import Dashboard from '@/views/customer/dashboard/Index.vue';
import Interests from '@/views/customer/interests/Index.vue'
import SelectInterests from '@/views/customer/interests/SelectInterests.vue'
import SecureAccount from '@/views/customer/account/SecureAccount.vue'
import ProductDetails from '@/views/customer/intents/ProductDetail.vue';
import Alerts from '@/views/customer/alerts/Index.vue';
import ViewAlert from '@/views/customer/alerts/ViewAlert.vue';
import Channels from '@/views/customer/channels/Index.vue';

import HoldList from '@/views/customer/holdlists/Index.vue';
import FriendsHoldList from '@/views/customer/holdlists/Friends.vue';
import ViewHoldlist from '@/views/customer/holdlists/View.vue';

import ViewCarts from '@/views/customer/carts/Index.vue';
import Checkout from '@/views/customer/checkouts/Index.vue';

import ViewReturns from '@/views/customer/alerts/returns/Index.vue';
import ViewOffers from '@/views/customer/alerts/offers/Index.vue';
import ViewWarranties from '@/views/customer/alerts/warranties/Index.vue';
import ViewSubscriptions from '@/views/customer/alerts/subscriptions/Index.vue';


let routes = [
    {
        path: '/',
        name: 'general',
        component: Portal,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                name: 'notifications',
                path: 'notifications',
                component: Notifications,
                meta: {
                    title: 'Notifications'
                }
            },
        
            {
                name: 'settings',
                path: 'settings',
                component: Settings,
                meta: {
                    title: 'Settings'
                }
            }
        ]
    },

    {
        path: '/',
        name: 'app',
        component: Portal,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                name: 'list_channels',
                path: 'channels',
                component: Channels,
                meta: {
                    title: 'View Channels'
                }
            },
        
            {
                name: 'my_hold_lists',
                path: 'holdlists/self',
                component: HoldList,
                meta: {
                    title: 'My Hold List'
                }
            },
        
            {
                name: 'friends_hold_list',
                path: 'holdlists/friends',
                component: FriendsHoldList,
                meta: {
                    title: 'My Friends Hold List'
                }
            },
        
            {
                name: 'view_holdlist',
                path: 'holdlists/:type/:hold_list_id',
                component: ViewHoldlist,
                props: true,
                meta: {
                    title: 'View Hold List Item'
                }
            },
        
            {
                name: 'channels.create',
                path: 'channels/create',
                component: CreateChannel,
                meta: {
                    title: 'Create New Channel'
                }
            },
        
            {
                name: 'channels.view',
                path: 'channels/:channel_id',
                component: ViewChannel,
                meta: {
                    title: 'Channel Streams'
                },
                props: true
            },
        
            {
                name: 'dashboard',
                path: 'dashboard',
                component: Dashboard,
                meta: {
                    title: 'Dashboard'
                }
            },
        
            {
                name: 'select_interests',
                path: 'interests/setup',
                component: SelectInterests,
                meta: {
                    title: 'Select Your Interests'
                }
            },
        
            {
                name: 'interests',
                path: 'interests',
                component: Interests,
                meta: {
                    title: 'Interests'
                }
            },
        
            {
                name: 'secure_account',
                path: 'account/secure',
                component: SecureAccount,
                props: true,
                meta: {
                    title: 'Secure your Account'
                }
            },
        
            {
                path: 'alerts',
                name: 'alerts',
                component: Alerts,
                meta: {
                    title: 'Smart Alerts'
                },
                children: [
                    {
                        path: 'returns',
                        name: 'returns',
                        component: ViewReturns
                    },
                    {
                        path: 'subscriptions',
                        name: 'subscriptions',
                        component: ViewSubscriptions
                    },
                    {
                        path: 'offers',
                        name: 'offers',
                        component: ViewOffers
                    },
                    {
                        path: 'warranties',
                        name: 'warranties',
                        component: ViewWarranties
                    }
                ]
            },
        
            {
                path: 'alerts/:type/:vendor',
                name: 'view_alerts',
                component: ViewAlert
            },
        
            {
                path: 'products/:id',
                name: 'product_detail',
                component: ProductDetails
            },
        
            {
                path: 'carts',
                name: 'carts',
                meta: {
                    title: 'Shopping Cart'
                },
                component: ViewCarts
            },
        
            {
                path: 'checkouts',
                name: 'checkouts',
                meta: {
                    title: 'Checkout'
                },
                component: Checkout
            },
        ]
    }
];

export default routes;