import Vue from 'vue';
import { get } from 'lodash';

const STORAGE_KEY = 'cc_params';

const INITIAL_STATE = {
    existingChannel: '',
    mood: '',
    objective: '',
    specificObjective: '',
    productTypes: [],
    customize: {
        products: {},
        merchants: {},
        sales: {}
    },
    interests: [],
};

const state = { ...INITIAL_STATE }

const getters = {
    getValidateChannelData(state) {
        if (state.mood === 'explore') {
            return state.interests.length >= 5;
        }

        return state.mood && state.objective && state.productTypes.length > 0;
    },

    getParams(state) {
        return { ...state }
    },
    getMood(state) {
        return state.mood;
    },
    getObjective(state) {
        return state.objective;
    },
    getSpecificObjective(state) {
        return state.specificObjective;
    },
    getProductTypes(state) {
        return state.productTypes;
    },
    getCustomizedProducts(state) {
        return state.customize.products;
    },
    getCustomizedMerchants(state) {
        return state.customize.merchants;
    },
    getCustomizedSales(state) {
        return state.customize.sales;
    },
    getInterests(state) {
        return state.interests;
    },
    getProductsFeatureIsCustomized(state) {
        return Object.values(state.customize.products).some(value => {
            const brands = get(value, 'brands', []).length;
            const colors = get(value, 'colors', []).length; 
            const sizes = get(value, 'sizes', []).length;
            const materials = get(value, 'materials', []).length;
            const preferences = get(value, 'preferences', []).length;

            return brands || colors || sizes || materials || preferences;
        });
    },

    getMerchantsFeatureIsCustomized(state) {
        return Object.values(state.customize.merchants).some(value => {
            const preferences = get(value, 'preferences', []).length;
            const ratings = get(value, 'ratings', []).length; 

            return preferences || ratings;
        });
    },

    getReqPayload(state) {
        return {
            mood: state.mood,
            existingChannel: state.existingChannel,
            objective: state.objective,
            specific_objective: state.specificObjective,
            product_types: state.productTypes.map(prodType => {
                return {
                    id: prodType.id,
                    features: state.customize.products[prodType.id],
                    merchants: state.customize.merchants[prodType.id],
                    sales: state.customize.sales[prodType.id]
                }
            }),
            interests: state.interests.map(interest => interest.id),
        }
    }
};

const mutations = {
    SET_VALUE(state, { key, value }) {
        Vue.set(state, key, value);
    },

    SET_CUSTOMIZE(state, { key, value }) {
        Vue.set(state.customize, key, value);
    },

    LOAD_PARAMS(state) {
        let params = sessionStorage.getItem(STORAGE_KEY);

        if (!params) return;

        params = JSON.parse(params);

        for (let key of Object.keys(state)) {
            if (params[key]) {
                Vue.set(state, key, params[key]);
            }
        }
    },

    PERSIST_PARAMS(state) {
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state));
    },

    CLEAR_STATE(state) {
        state = { ...INITIAL_STATE }
        sessionStorage.removeItem(STORAGE_KEY);
    },

    PRESEED(state, channel) {
        Vue.set(state, 'existingChannel', get(channel, 'id', ''));
        Vue.set(state, 'objective', get(channel, 'objective.id', ''));
        Vue.set(state, 'specificObjective', get(channel, 'specific_objective', ''));
        Vue.set(state, 'productTypes', get(channel, 'product_types', []).map(prodType => {
            return {id: prodType.id, name: prodType.name}
        }));

        if (get(channel, 'product_types', []).length > 1) {
            Vue.set(state, 'mood', 'multiple');
        }

        Vue.set(state, 'customize', {
            products: get(channel, 'product_types', []).reduce((acc, prodType) => {
                acc[prodType.id] = get(prodType, 'features', {});
                return acc;
            }, {}),
            merchants: get(channel, 'product_types', []).reduce((acc, prodType) => {
                acc[prodType.id] = get(prodType, 'merchants', {});
                return acc;
            }, {}),
            sales: get(channel, 'product_types', []).reduce((acc, prodType) => {
                acc[prodType.id] = get(prodType, 'sales', {});
                return acc;
            }, {}),
        });
        Vue.set(state, 'interests', []);
    }
};

const actions = {
    setValue({ commit }, payload) {
        commit('SET_VALUE', payload);
    },

    setCustomize({ commit }, payload) {
        commit('SET_CUSTOMIZE', payload);
    },

    loadParams({ commit }) {
        commit('LOAD_PARAMS');
    },

    persistParams({ commit }) {
        commit('PERSIST_PARAMS');
    },

    clearState({commit}) {
        commit('CLEAR_STATE')
    },

    preseed({commit}, channel) {
        commit('PRESEED', channel)
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}