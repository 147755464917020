import { db } from './index';

export default {
    async createMany(merchants) {
        await db.Merchants.bulkAdd(merchants);
    },

    async find(merchantId) {
        const merchant = await db.Merchants.get(parseInt(merchantId));
        return merchant;
    },

    async updateOrAdd(merchant) {
        return await db.Merchants.put(merchant);
    }
}