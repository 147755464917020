<template>
    <div>
        <div class="row">
            <div class="col-12 col-md-6" v-for="(item, i) in all" :key="i">
                <subscription-item :item="item" @view="viewHistory"/>
            </div>
        </div>
        <infinite-loading @infinite="loadData"></infinite-loading>

        <view-history 
            type="subscription"
            :merchant="merchant"
            :show="showViewHistory" 
            :item="subscription" 
            @cancel="viewHistory(null)"/>
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import SubscriptionItem from './partials/SubscriptionItem.vue';
import ViewHistory from '@/views/customer/alerts/ViewHistory.vue';

export default {
    props: ['merchant'],
     
    components: {
        SubscriptionItem,
        ViewHistory
    },

    data() {
        return {
            page: 1,
            all: [],
            showViewHistory: false,
            subscription: null
        }
    },

    computed: {
        vendor() {
            return this.$route.params.vendor;
        }
    },

    methods: {
        ...mapActions({
            index: 'AdvertSubscriptions/index',
            sync: 'AdvertSubscriptions/sync',
        }),

        viewHistory(subscription) {
            this.subscription = subscription;
            this.showViewHistory = !!subscription;
        },

        loadData(loader) {
            let params = { 
                keyword: this.keyword, 
                page: this.page,
                vendor: this.vendor
            };
            
            this.index(params).then(items => {
                loader && loader.loaded();

                if (items.length === 0) {
                    loader && loader.complete();
                }

                this.page += 1;
                this.all = [...this.all, ...items]
            }).catch(error => {
                loader && loader.error();
            });
        }
    },

    created() {
        this.sync({vendor: this.vendor})
        .then(response => {
            this.all = [...response]
            this.loadData();
        });
    }
}
</script>