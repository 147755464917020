/**
 * In this file, we register all vue external plugins such as vee-validate.
 * We register all global components here
 * We import all sass/scss files here
 */
import '@/assets/sass/main.scss';
import ScrollLoader from 'vue-scroll-loader';
import InfiniteLoading from 'vue-infinite-loading';
import GlobalComponents from '@/components/global';

import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

export default {
    /**
     * Installs vue plugins
     *
     * @param {Object} Vue
     * @returns {Undefined}
     */
    install(Vue) {
        /**
         * register all plugins here
         */
        //e.g Vue.use(VeeValidate) etc

        Vue.use(ScrollLoader);
        Vue.use(InfiniteLoading);

        /**
         * Register all global components here
         */
        Object.keys(GlobalComponents).forEach(key => {
            Vue.component(key, GlobalComponents[key]);
        });

        Vue.component('datetime', Datetime);
    }
}