<template>
    <div class="guest-heading elevation-2">
        <div class="d-flex align-items-center">
            <router-link :to="{name: 'welcome'}" tagv="div" class="logo cursor-pointer">
                <logo/>
            </router-link>
        </div>

        <div class="d-flex flex-column justify-content-center align-items-center text-content">
            <span class="title" v-html="title"></span>

            <p class="font-weight-lighter m-0 description text-white mt-1">
                {{ description }}
            </p>
        </div>

        <div v-if="showLogin">
            <router-link :to="{name: 'login'}" href="javascript:void(0)" class="btn btn-login text-truncate">
                Sign In
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'description'],

    computed: {
        showLogin() {
            return ['welcome', 'register'].includes(this.$route.name)
        }
    }
}
</script>