import Service from '@/services/data_sync.js';

import brands from '@/database/brands'
import colors from '@/database/colors'
import countries from '@/database/countries'
import productTypes from '@/database/product_types'
import interestTypes from '@/database/interest_types'
import shoppingObjectives from '@/database/shopping_objectives'

const actions = {
    async getInitialData({ commit }) {
        const lpt = await productTypes.getLatest();
        const lit = await interestTypes.getLatest();
        const lb = await brands.getLatest();
        const lc = await colors.getLatest();
        const lco = await countries.getLatest();
        const lso = await shoppingObjectives.getLatest();
        
        const params = {
            lptid: lpt?.id || '',
            litid: lit?.id || '',
            lbid: lb?.id || '',
            lcoid: lc?.id || '',
            lcid: lco?.id || '',
            lsoid: lso?.id || '',
        }
        
        return Service.getInitialData(params)
            .then(response => {
                productTypes.createMany(response.product_types);
                interestTypes.createMany(response.interest_types);
                brands.createMany(response.brands);
                colors.createMany(response.colors);
                countries.createMany(response.countries);
                shoppingObjectives.createMany(response.shopping_objectives);

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    }
};

export default {
    actions,
    state:{},
    getters:{},
    mutations:{},
    namespaced: true,
}