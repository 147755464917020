<template>
<div class="">
    <div class="row no-gutters">
        <div class="col-12 col-md-6" v-for="(session, index) in sessions" :key="index">
            <div class="bg-white elevation p-2 m-1 no-radius d-flex justify-content-between align-items-center flex-gap-1">
                <div class="avatar-xs bg-default-dark circle d-flex justify-content-center align-items-center">
                    <i class="mdi font-2xl" :class="session.icon"></i>
                </div>
                <div class="info flex-grow-1">
                    <p class="text-justify mb-0"><b>IP Address:</b> {{ session.ip }}</p>
                    <p class="text-justify mb-0" v-if="session.device_desc"><b>Device:</b> {{ session.device_desc }}</p>
                    <p class="text-justify mb-0"><b>Logged In Since:</b> {{ session.created_at | fromNow }}</p>
                </div>
                <div class="actions">
                    <label v-if="session.id != current" class="checkbox cursor-pointer d-flex align-items-center text-black-fade">
                        <input type="checkbox" name="remember" v-model="selectedMap[session.id]" class="mr-1"/>
                        <span class="check"></span>
                    </label>
                    <i v-else class="font-xxs">Current</i>
                </div>
            </div>
        </div>
    </div>

    <div class="actions text-right mt-2" v-if="sessions.length > 1">
        <button class="btn btn-default-dark" :class="{'elevation-2': selected.length > 0}" @click.prevent="logout">
            <i class="mdi mdi-logout"></i> Logout Selected Sessions
        </button>
    </div>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

    export default {
        data() {
            return {
                selectedMap: {},
            }
        },

        computed: {
            ...mapGetters({
                data: 'Authentications/getActiveSessions'
            }),

            sessions() {
                return this.data.all.map(session => {
                    let icon = 'mdi-laptop';
                    let device_desc = null;

                    if (session.platform && ['android', 'ios' ].includes(session.platform.toLowerCase())) {
                        icon = 'mdi-cellphone'
                    }

                    if (session.device || session.platform) {
                        device_desc = session.platform;
                        if (session.device) {
                            if (device_desc) {
                                device_desc += ', ';
                            }
                            device_desc += session.device;
                        }
                    }

                    session = {
                        ...session,
                        icon,
                        device_desc
                    };

                    return session;
                });
            },

            current() {
                return this.data.current;
            },

            selected() {
                return Object.keys(this.selectedMap).filter(key => this.selectedMap[key]);
            }
        },

        methods: {
            ...mapActions({
                getSessions: 'Authentications/getActiveSessions',
                revokeSession: 'Authentications/revokeSession',
            }),

            async logout() {
                if (!this.selected.length) {
                    return;
                }

                const result = await this.swalConfirm(
                    'Are you sure?', 
                    `Please confirm you wish to logout from selected sessions`, 
                    `YES, Logout`, 
                    `Cancel`
                );

                if (!result.value) {
                    return;
                }

                this.revokeSession({
                    tokens: this.selected,
                    action: 'selected'
                })
                .then(response => {
                    this.selectedMap = {};
                    this.getSessions();
                })
                .catch(error => {
                    this.swalError('Error', 'An error occurred while trying to logout from selected sessions');
                })
            
            }
        },

        created() {
            this.getSessions()
            .catch(error => {
                console.log(error);
            })
        }
    }
</script>