<template>
    <div class="poll-item" v-if="poll">
        <div class="question font-big text-uppercase">
            {{ poll.question }}
        </div>

        <div class="options">
            <div class="option" v-for="(option, index) in poll.options" :key="index">
                <div class="count-bar" :class="{'selected': selectedOptionId == option.id}"
                    :style="{ width: selectedOptionId ? widths[`opt_${option.id}`] : ''}"
                    v-if="widths[`opt_${option.id}`]">
                </div>
                <div class="d-flex justify-content-between align-items-center inner text-capitalize font-small"
                    @click.stop="vote(option)">
                    <div>
                        <i v-if="selectedOptionId == option.id"
                            class="mdi mdi-checkbox-marked-circle text-white mr-2"></i>
                        <i v-else class="mdi mdi-radiobox-blank mr-2 text-primary icon"></i>
                        {{ option.name }}
                    </div>
                    <span v-if="selectedOptionId">{{ results[`opt_${option.id}`] | shortenNumber }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    props: ['poll'],

    data() {
        return {
            results: {},
            totalVotes: 0,

            selectedOptionId: null,

            widths: {}
        }
    },

    computed: {
    },

    methods: {
        ...mapActions({
            store: 'ChannelStreams/votePoll'
        }),

        setResults() {
            if (!this.poll) return;

            this.totalVotes = 0;

            for (let option of this.poll.options) {
                // let value = option.votes;
                let value = parseInt(Math.random() * 10000000) + 10;
                this.results[`opt_${option.id}`] = value;
                this.totalVotes += value;
            }

            // console.log(this.results);

            this.setWidths();
        },

        setWidths() {
            this.widths = {};
            let key;

            for (let option of this.poll.options) {
                key = `opt_${option.id}`;

                this.widths[key] = `${parseInt((this.totalVotes ? this.results[key] / this.totalVotes : 0) * 100)}%`
            }
        },

        vote(option) {
            if (this.selectedOptionId) return;

            this.results[`opt_${option.id}`] += 1;
            this.totalVotes += 1;

            this.setWidths();

            this.selectedOptionId = option.id;

            this.store({option_id: option.id})
                .then(response => {})
                .catch(error => null);
        }
    },

    mounted() {
        if (this.poll.user_votes.length) {
            this.selectedOptionId = this.poll.user_votes[0].option_id;
        }

        this.setResults();
    }
}
</script>