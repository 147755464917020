<template>
  <div class="circular-progress" ref="progress">
    <button @click="$emit('goNext')" class="btn next">
        <i class="mdi mdi-chevron-right"></i>
        <span  class="text-white" style="width: 0;overflow: hidden;">
          {{ submitText }}
        </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    submitText: {
      type: String,
      default: "Submit"
    },
    progress: {
      type: Object,
      default: () => ({
        current: 1,
        total: 9
      })
    }
  },

  data() {
    return {
      prevDeg: 0
    }
  },

  watch: {
    progress: {
      handler() {
        this.calculateProgress();
      },
      deep: true
    }
  },

  methods: {
    setProgress(deg) {
      this.$refs.progress.style.background = `conic-gradient(#BE37C0 ${deg}deg, transparent 0deg)`;

      if (deg === 360) {
        this.$refs.progress.style.animation = 'transform-into-submit 0.35s ease-in-out forwards';
        this.$refs.progress.querySelector('.btn.next').style.animation = 'transform-into-submit-btn 0.35s ease-in-out forwards';
        this.$refs.progress.querySelector('.btn.next span').style.animation = 'transform-into-submit-text 0.35s ease-in-out forwards';
      }
    },

    calculateProgress() {
      const { current, total } = this.progress;
      const deg = (current / total) * 360;
      const speed = 5;

      if (current === 1) {
        this.setProgress(deg);
        this.prevDeg = deg;
        return;
      } else {
        const interval = setInterval(() => {
          if (this.prevDeg >= deg) {
            clearInterval(interval);
            return;
          }

          this.prevDeg += 1;
          this.setProgress(this.prevDeg);
        }, speed);
      }

    }
  },

  mounted() {
    this.calculateProgress();
  }
}
</script>

<style>

</style>