import Service from '@/services/smart_alerts';
import smartAlertsDB from '@/database/smart_alerts';

const state = {
    all: [],
};

const getters = {
    getAll(state) {
        return state.all;
    }
};

const mutations = {
    SET_ALERTS(state, alerts) {
        state.all = [...state.all, ...alerts]
    },
};

const actions = {
    async index({ commit }, params) {
        const alerts = await smartAlertsDB.index(params);
        return Promise.resolve(alerts);
    },

    sync({ commit }, params) {
        return Service.index(params)
            .then(async (response) => {
                await smartAlertsDB.updateOrAdd(response.alerts);

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}