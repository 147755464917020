<template>
    <div class="section collapsed">
        <!-- <heading title="Which <span>Size(s)</span>?"/> -->

        <div class="p-0 mb-3 color-set section-content">
            <div class="colors">
                <div v-for="(size, ind) in sizes"
                    @click="add(size)"
                    :class="{ 'active': value.includes(size) }" 
                    class="color elevation-2"
                    :key="ind">
                    <div class="name">{{ size }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Heading from './Heading.vue'

export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        productTypes: {
            type: Array,
            default: () => [],
        },
    },

    components: {
        Heading
    },

    computed: {
        sizes() {
            return ['S', 'M', 'L', 'XL'];
        }
    },

    methods: {

        add(size) {
            let values = [];

            if (this.value.includes(size)) {
                values = this.value.filter((val) => val !== size);
            } else {
                values = [...this.value, size];
            }

            this.$emit('input', values);
        }
    },

}
</script>

<style></style>