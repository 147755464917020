<template>
    <div class="getting-started">
        <carousel v-model="activeIndex">
            <carousel-item>
                <!-- Mood: Ask for mood -->
                <mood @next="goNext" v-if="isMoods" />
            </carousel-item>

            <carousel-item>
                <!-- Objective: Get objective -->
                <objective @next="goNext" v-if="isObjective" />
            </carousel-item>

            <carousel-item>
                <!-- Product Type: Select product types -->
                <product-types @next="goNext" v-if="isProductTypes" />
            </carousel-item>

            <carousel-item>
                <!-- Customization -->
                <customize 
                    @next="goNext" 
                    @showCustomizeWizard="onShowCustomizeWizard" 
                    v-if="isCustomize" />
            </carousel-item>

            <carousel-item>
                <!-- Interests -->
                <interests
                    @get-started="submit"
                    :interests="interestTypes"
                    v-model="params.interests"
                    v-if="isInterests"
                />
            </carousel-item>
        </carousel>
        
        <customization-wizard
            :currentTab="customizeConfig.currentTab"
            :show="customizeConfig.show"
            @cancel="onShowCustomizeWizard(null)"/>

        <template v-if="showContinueBtn">
            <button 
                @click="onContinue" 
                class="btn btn-primary font-medium get-started-btn elevation view-results-btn">
                <template v-if="isLastStep">
                    FINISH <i class="mdi mdi-chevron-double-right"></i>
                </template>
                <template v-else>
                    CONTINUE <i class="mdi mdi-chevron-double-right"></i>
                </template>
            </button>
        </template>
    </div>
</template>
<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Carousel from '@/components/shared/carousel/Carousel.vue';
import Mood from '@/views/customer/channels/create/stages/Mood.vue';
import Intro from '@/views/customer/channels/create/stages/Intro.vue';
import CarouselItem from '@/components/shared/carousel/CarouselItem.vue';
import Interests from '@/views/customer/channels/create/stages/Interests.vue';
import Objective from '@/views/customer/channels/create/stages/Objective.vue';
import ProductTypes from '@/views/customer/channels/create/stages/ProductTypes.vue';
import Customize from '@/views/customer/channels/create/stages/Customize.vue';
import CustomizationWizard from '@/views/customer/channels/create/stages/customize/Index.vue';
import { WARNING, SUCCESS } from '@/libs/notie';

export default {
    props: {
        activeIndex: {
            type: Number,
            default: 0
        }
    },
    
    components: {
        Mood,
        Intro,
        Carousel,
        Objective,
        Interests,
        ProductTypes,
        CarouselItem,
        Customize,
        CustomizationWizard,
    },

    data() {
        return {
            indexes: {
                mood: 0,
                objective: 1,
                productTypes: 2,
                customize: 3,
                interests: 4,
            },

            customizeConfig: {
                currentTab: 'products',
                show: false
            }
        }
    },

    computed: {
        ...mapGetters({
            interestTypes: 'InterestTypes/getAll',
            mood: 'CreateChannels/getMood',
            objective: 'CreateChannels/getObjective',
            productTypes: 'CreateChannels/getProductTypes',
            interests: 'CreateChannels/getInterests',
            params: 'CreateChannels/getParams',

            productsFeatureIsCustomized: 'CreateChannels/getProductsFeatureIsCustomized',
            merchantsFeatureIsCustomized: 'CreateChannels/getMerchantsFeatureIsCustomized',
        }),

        showContinueBtn() {
            if (this.isProductTypes && this.params.mood.endsWith('multiple')) {
                return this.params.productTypes.length > 0;
            }

            if (this.isInterests) {
                return this.params.interests.length >= 5;
            }

            if (this.isCustomize) {
                return true;
            }

            return false;
        },

        isMoods() {
            return this.activeIndex === this.indexes.mood;
        },

        isObjective() {
            return this.activeIndex === this.indexes.objective;
        },

        isProductTypes() {
            return this.activeIndex === this.indexes.productTypes;
        },

        isCustomize() {
            return this.activeIndex === this.indexes.customize;
        },

        isInterests() {
            return this.activeIndex === this.indexes.interests;
        },

        isLastStep() {
            return this.isCustomize || this.isInterests;
        }
    },

    watch: {
        params: {
            handler() {
                this.cacheParams();
            },
            deep: true
        }
    },

    methods: {
        ...mapActions({
            loadInterests: 'InterestTypes/index',
            loadPreferenceTypes: 'PreferenceTypes/index',
            loadParams: 'CreateChannels/loadParams',
            persistParams: 'CreateChannels/persistParams',
        }),

        onContinue() {
            if (this.isLastStep) {
                this.submit();
            } else {
                this.goNext('customize');
            }
        },

        cacheParams: debounce(function () {
            this.persistParams();
        }, 100),

        onShowCustomizeWizard(params) {
            if (params) {
                this.customizeConfig.currentTab = params.value;
                this.customizeConfig.show = true;
            } else {
                this.customizeConfig.show = false;
            }
        },

        async goNext(property) {
            const idx = this.indexes[property];

            if (idx === undefined) return;

            this.updateRouteQuery({ idx });
        },

        redirectToMood() {
            const text = `
                <h6>Oops! It seems you haven't selected a mood yet.</h6>
                <p class="m-0">You will be sent back to the moods screen</p>
            `;

            this.notifyError(text, {}, true).then(() => {
                this.goNext('mood');
            });
        },

        redirectToObjective() {
            const text = `
                <h6>Oops! It seems you haven't selected an objective yet.</h6>
                <p class="m-0">You will be sent back to the objective screen</p>
            `;

            this.notifyError(text, {}, true).then(() => {
                this.goNext('objective');
            });
        },

        redirectToProductTypes() {
            const text = `
                <h6>Oops! It seems you haven't selected any product categories yet.</h6>
                <p class="m-0">You will be sent back to the product categories screen</p>
            `;

            this.notifyError(text, {}, true).then(() => {
                this.goNext('productTypes');
            });
        },

        async validateParams() {
            if (!['single', 'multiple', 'explore'].includes(this.params.mood)) {
                this.redirectToMood();
                return false;
            }

            if (!this.params.objective) {
                this.redirectToObjective();
                return false;
            }

            if (!this.params.productTypes.length) {
                this.redirectToProductTypes();
                return false;
            }

            return true;
        },

        async finalizeAndSubmit() {
            const text = `
                <h6>Customize Features and Preferences?</h6>
                <p class="m-0">
                    Are you sure you want to proceed without customizing your preferences? 
                </p>
            `;

            const choices = [
                {
                    text: 'Yes, I want to proceed without customizing',
                    type: SUCCESS,
                    handler: () => {
                        this.$emit('submit', {...this.params});
                    }
                },
                {
                    text: 'Customize Product Features',
                    type: WARNING,
                    handler: () => {
                        this.onShowCustomizeWizard({ value: 'products' });
                    }
                },
                {
                    text: 'Customize Merchant Features',
                    type: WARNING,
                    handler: () => {
                        this.onShowCustomizeWizard({ value: 'merchants' });
                    }
                },
                {
                    text: 'Customize Sale Terms',
                    type: WARNING,
                    handler: () => {
                        this.onShowCustomizeWizard({ value: 'sales' });
                    }
                }
            ];

            this.promptSelect(text, choices).catch(err => null);
        },

        async submit() {
            const isInvalid = await this.validateParams();

            if (!isInvalid) return;

            if (this.productsFeatureIsCustomized || this.merchantsFeatureIsCustomized) {
                this.$emit('submit', {...this.params});
            } else {
                this.finalizeAndSubmit();
            }
        },
    },

    created() {
        this.loadParams();
        this.loadInterests();
        this.loadPreferenceTypes();
    }
}
</script>