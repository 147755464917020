<template>
    <question-answer>
        <question>
            <span slot="progress">
                <span class="font-weight-bold font-big">Customize Features</span>
            </span>
            <div ref="title" class="font-5xl font-thin text-muted"></div>
        </question>


        <answers :showOptions="showOptions">
            <div class="mt-4">
                <button 
                    v-for="item in actions" 
                    :key="item.value" 
                    class="btn btn-block bg-white elevation-2 radius-0 d-flex align-items-center text-left"
                    @click="onOptionClick(item)">
                    <div class="mr-2">
                        <i class="font-5xl text-secondary" :class="item.icon"></i>
                    </div>
                    <div class="flex-grow-1">
                        <h5 class="mb-1 text-primary font-xs">{{ item.text }}</h5>
                        <p class="m-0 font-xxs text-black">{{ item.description }}</p>
                    </div>
                </button>
            </div>
        </answers>
    </question-answer>
</template>

<script>
import TypeIt from "typeit";
import { mapActions, mapGetters } from 'vuex';
import Answers from '@/views/customer/channels/create/stages/partials/Answers.vue';
import Question from '@/views/customer/channels/create/stages/partials/Question.vue';
import QuestionAnswer from '@/views/customer/channels/create/stages/partials/QuestionAnswer.vue';

export default {
    props: {
        value: {
            type: [Object],
            default: () => ({})
        }
    },

    components: {
        Answers,
        Question,
        QuestionAnswer,
    },

    data() {
        return {
            showOptions: false,

            actions: [
                {
                    value: 'products',
                    icon: 'mdi mdi-package-variant-closed',
                    text: 'Customize Product Features',
                    description: 'Brand, Color, Size, etc.',
                },
                {
                    value: 'merchants',
                    icon: 'mdi mdi-store',
                    text: 'Customize Merchant Features',
                    description: 'Eco-friendly, Local, etc'
                },
                {
                    value: 'sales',
                    icon: 'mdi mdi-tag',
                    text: 'Customize Sale Terms',
                    description: 'Returns, Discounts, promotions, etc.',
                },
            ],
        }
    },

    computed: {
        ...mapGetters({
        }),
    },

    methods: {
        ...mapActions({
        }),

        onOptionClick(option) {
            this.$emit('showCustomizeWizard', option)
        },

        typeHeading() {
            const objective = `<span class="font-5xl text-primary">Customize Features</span>?`;

            const instance = new TypeIt(this.$refs.title, {
                speed: 20,
                startDelay: 100,
                cursor: false,
                afterComplete: async (instance) => {
                    this.showOptions = true;
                },
            })
            .empty()
            .type(`
                Customize: <br/> <span class="font-5xl text-primary">product</span>,
                <span class="font-5xl text-primary">merchant</span>, and 
                <span class="font-5xl text-primary">sale</span> terms`
            );

            instance.go();
        },
    },

    mounted() {
        setTimeout(() => {
            this.typeHeading();
        }, 0);
    }
}
</script>