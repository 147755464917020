export default {
    routerMode: process.env.VUE_APP_ROUTER_MODE,
    //app specific config
    baseURL: process.env.VUE_APP_BASE_API_URL,

    //firebase config
    firebase: {
        vapid: process.env.VUE_APP_FIREBASE_VAPID,
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID,
        measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
    },

    //stripe config
    stripe: {
        publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    },
}