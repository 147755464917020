<template>
  <div class="question">
        <div class="responsive-page-width">
            <heading>
                <slot name="progress"/>
            </heading>

            <div class="question-content">
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>
import Heading from '@/views/customer/channels/create/stages/partials/Heading.vue';

export default {
    name: 'GettingStartedQuestion',
    components: {
        Heading,
    },
}
</script>

<style>

</style>