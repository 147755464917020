<template>
    <div class="edit-profile">
        <form @submit.prevent="submit" class="form elevation">

            <div class="profile-cover d-flex justify-content-center align-items-center">
                <div class="profile-img elevation">
                    <img :src="imageUrl" alt="Profile Image">
                    <label for="profile-img">
                        <input accept="image/*" type="file" id="profile-img" style="display: none" @change="imgInputChange">
                        <i class="mdi mdi-camera font-big text-primary"></i>
                    </label>
                </div>
            </div>

            <div class="mb-5 mt-4">
                <base-input
                    v-model="payload.username"
                    :error="validationErrors.username"
                    label="Username"
                    placeholder="e.g johndoe"
                />
            </div>
    
            <div class="mb-5">
                <base-input
                    v-model="payload.first_name"
                    :error="validationErrors.first_name"
                    label="First Name"
                    placeholder="e.g John"
                />
            </div>
    
            <div class="mb-5">
                <base-input
                    v-model="payload.last_name"
                    :error="validationErrors.last_name"
                    label="Last Name"
                    placeholder="e.g doe"
                />
            </div>
    
            <div class="">
                <button
                    type="submit"
                    class="btn elevation btn-block btn-success font-xs text-white login-btn">
                    <i class="mdi mdi-content-save-edit-outline"></i> UPDATE PROFILE
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
    data() {
        return {
            disableCountry: false,
            imagePreviewUrl: null,

            payload: {
                username: '',
                first_name: '',
                last_name: '',
            },

            image: null,

            validationErrors: {
                username: null,
                first_name: '',
                last_name: '',
            }
        }
    },

    computed: {
        imageUrl() {
            return this.imagePreviewUrl || this.authUser.image_url;
        }
    },

    methods: {
        ...mapActions({
            updateProfile: 'Authentications/updateProfile',
            updateProfileImage: 'Authentications/updateProfileImage',
        }),

        imgInputChange(e) {
            if (!e.target.files.length) {
                return;
            }

            const file = e.target.files[0];

            if (!file.type.includes('image')) {
                this.notifyError('Invalid file type. Please select an image file');
                return;
            }

            const reader = new FileReader();

            reader.onload = e => {
                this.imagePreviewUrl = e.target.result;
            }

            reader.readAsDataURL(file);

            this.image = file;
        },

        async submit() {
            this.buildValidationErrors({});

            this.showLoader('Updating your Profile...');

            const data = {
                ...this.payload
            }

            if (this.image) {
                data.img_mime = this.image.type;
                data.img_ext = this.image.name.split('.').pop();
            }

            this.updateProfile(data)
                .then(response => {
                    if (this.image) {
                        if (response.presigned_url) {
                            this.uploadProfileImage(response.presigned_url);
                        } else {
                            this.hideLoader();
                            this.showFailedToUploadImage();
                        }
                    } else {
                        this.hideLoader();
                        this.notifySuccess('Profile Updated Successfully');
                    }
                })
                .catch(error => {
                    this.hideLoader();
                    this.notifyError(error.message);
                    this.buildValidationErrors(error.errors);
                })
        },

        async uploadProfileImage(presignedUrl) {
            if (!presignedUrl) return;
            const {url, key} = presignedUrl;
            this.showLoader('Uploading Profile Image...');
            const res = await this.uploadPresignedFile(this.image, url);

            if (res) {
                this.updateProfileImage({image: key}).then(response => {
                    this.hideLoader();
                    this.notifySuccess('Profile Updated Successfully');
                })
                .catch(error => {
                    this.hideLoader();
                    this.showFailedToUploadImage();
                });
            } else {
                this.hideLoader();
                this.showFailedToUploadImage();
            }
        },

        showFailedToUploadImage() {
            this.notifyWarn(`Profile Updated but failed to upload image. Please try again.`);
        }
    },

    created() {
        this.payload = {
            username: this.authUser.username,
            first_name: this.authUser.first_name,
            last_name: this.authUser.last_name,
            email: this.authUser.email
        }
    }
}
</script>