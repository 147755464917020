<template>
    <div class="order-item bg-white mt-3 elevation">
        <div class="heading d-flex justify-content-between align-items-center">
            <div class="product">
                <h5 class="name mb-0 font-small">{{ advert_name }}</h5>
                <p class="text-muted m-0">{{ advert_subtitle }}</p>
            </div>
            <span class="status font-xs received" v-if="isActive">Active</span>
            <span class="status font-xs cancelled" v-else>Canceled</span>
        </div>

        <div class="order-section">
            <div class="section-item d-flex align-items-center justify-content-between text-muted">
                <span>
                    <i class="mdi mdi-format-list-checks mr-2"></i>
                    Subscription Plan
                </span>
                <span>{{ plan }}</span>
            </div>

            <div class="section-item d-flex align-items-center justify-content-between text-muted">
                <span>
                    <i class="mdi mdi-calendar-text mr-2"></i>
                    Next Billing Date
                </span>
                <span>{{ next_billing_date | formattedDate }}</span>
            </div>

            <div class="section-item d-flex align-items-center justify-content-between text-muted">
                <span>
                    <i class="mdi mdi-cash mr-2"></i>
                    Next Billing Amount
                </span>
                <span>{{ currency }}<span class="ml-1">{{ item.next_billing_amount | moneyFormat }}</span></span>
            </div>

            <div class="section-item d-flex align-items-center justify-content-between text-muted">
                <span>
                    <i class="mdi mdi-calendar-text mr-2"></i>
                    Billing Cycle
                </span>
                <span class="text-capitalize">{{ billing_cycle }}</span>
            </div>

            <div class="section-item d-flex align-items-start justify-content-between text-muted">
                <span>
                    <i class="mdi mdi-close mr-2"></i>
                    Cancellation
                </span>
                <div class="p-0 pl-2 pr-2 text-right">
                    <div class="text-primary bg-default ">
                        {{ cancellation_email }}
                    </div>
                    <a href="#" 
                        @click.prevent="cancelSubscription" 
                        v-if="isActive"
                        class="text-danger">Cancel Now</a>
                    <a href="#" 
                        @click.prevent="resumeSubscription" 
                        v-else
                        class="text-success">Resume Now</a>
                </div>
            </div>
        </div>

        <div class="order-section text-center">
            <a href="#" @click.prevent="$emit('view', item)" class="text-info">
                View History / Message Vendor
            </a>
        </div>
    </div>
</template>
<script>
import {get} from 'lodash';
import {mapActions} from 'vuex';

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            subsItem: null
        }
    },

    computed: {
        currency() {
            return get(this.subsItem, 'currency', null) || get(this.item, 'currency', 'USD');
        },
        isActive() {
            const status = get(this.subsItem, 'status') || get(this.item, 'status', 'inactive');
            return status === 'active';
        },
        advert_name() {
            return get(this.subsItem, 'advert.name') || get(this.item, 'advert.name', 'N/A');
        },
        advert_subtitle() {
            return get(this.subsItem, 'advert.subtitle') || get(this.item, 'advert.subtitle', 'N/A');
        },
        plan() {
            return get(this.subsItem, 'plan') || get(this.item, 'plan', 'N/A');
        },
        next_billing_date() {
            return get(this.subsItem, 'next_billing_date') || get(this.item, 'next_billing_date', 'N/A');
        },
        billing_cycle() {
            return get(this.subsItem, 'billing_cycle') || get(this.item, 'billing_cycle', 'N/A');
        },
        cancellation_email() {
            return get(this.subsItem, 'cancellation_email') || get(this.item, 'cancellation_email', 'N/A');
        }
    },

    methods: {
        ...mapActions({
            update: 'AdvertSubscriptions/update',
        }),

        async resumeSubscription() {
            if (this.isActive) {
                return
            }

            const choice = await this.swalConfirm('Resume Subscription', 
            `<h6>You will be billed for this item. Are you sure you want to resume your subscription for <span class="font-weight-bold text-success">${this.item.advert.name}</span>?</h6>`,
            'Yes, Resume It', "No"
            )
            
            if (!choice.value) {
                return
            }

            this.update({id: this.item.id, data: {subscription_id: this.item.id, status: 'active'}})
            .then((subs) => {
                this.subsItem = {...subs}
            }).catch(() => {
                this.swalError(
                    'Subscription Resumption Failed', 
                    'An error occurred while trying to resume your subscription'
                );
            });
        },

        async cancelSubscription() {
            if (!this.isActive) {
                return
            }

            const choice = await this.swalConfirm('Sorry to see you leave', 
            `<h6>You will no longer be billed for this item. Are you sure you want to cancel your subscription for <span class="font-weight-bold text-danger">${this.item.advert.name}</span>?</h6>`,
            'Yes, Cancel It', "No"
            )
            
            if (!choice.value) {
                return
            }

            this.update({id: this.item.id, data: {subscription_id: this.item.id, status: 'cancelled'}})
            .then((subs) => {
                this.subsItem = {...subs}
            }).catch(() => {
                this.swalError(
                    'Subscription Cancellation Failed', 
                    'An error occurred while trying to cancel your subscription'
                );
            });
        }
    }
}
</script>