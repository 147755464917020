<template>
    <div class="item elevation" :class="`bg-${(index)%numOfBg} ${showDelete ? 'is-confirm' : ''}`">
        <img src="/img/products/cerave2.png" alt="item" class="cart-img"/>
        <button class="btn delete-btn elevation-2" @click="showDeletePopup(true)">
            <i class="mdi mdi-delete-forever text-black font-big"></i>
        </button>
        <div class="item-details">
            <div class="">
                <h5 class="font-weight-bold font-xs text-truncate text-black name">
                    {{ item.offer.name }}
                </h5>
                <h5 class="font-weight-bold text-primary d-flex justify-content-between align-items-center font-medium">
                    {{ item.offer.price_formatted }}
                    <div class="d-flex align-items-center quantity">
                        <button class="btn elevation-2" @click="updateQuantity(-1)">
                            <i class="mdi mdi-minus font-xs"></i>
                        </button>
                        <button class="btn qty elevation-2">
                            {{ item.quantity }}
                        </button>
                        <button class="btn elevation-2" @click="updateQuantity(1)">
                            <i class="mdi mdi-plus font-xs"></i>
                        </button>
                    </div>
                </h5>
            </div>
            <h6 class="text-muted font-xxs w-100 text-truncate">Size: XXL, Color: Burgundy</h6>
        </div>
        <div class="delete-confirmation" v-if="showDelete">
            <h5 class="font-big text-white">Confirm</h5>
            <div class="d-flex justify-content-between">
                <button class="cancel elevation-2" @click="showDeletePopup(false)">Cancel</button>
                <button class="confirm elevation-2" @click="deleteItem">Delete</button>
            </div>
        </div>
    </div>
</template>
<script>
import bus from '@/event-bus';

export default {
    props: {
        index: {
            type: Number,
            required: true
        },
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            numOfBg: 3,
            showDelete: false,
            eventName: 'close-cart-item-delete-popup'
        }
    },
    methods: {
        showDeletePopup(val) {
            this.showDelete = val;
            if (val) {
                bus.$emit(this.eventName, this.item.offer.id);
            }
        },

        deleteItem() {
            this.$emit('delete-item', this.item.offer.id);
        },
        updateQuantity(increment) {
            if (this.item.quantity + increment == 0) {
                return;
            }

            this.$emit('update-quantity', {
                id: this.item.offer.id, 
                quantity: this.item.quantity + increment
            });
        }
    },

    created() {
        bus.$on(this.eventName, (item_id) => {
            if (this.item.offer.id == item_id) {
                return;
            }
            this.showDelete = false;
        });
    },

    beforeDestroy() {
        bus.$off(this.eventName);
    }
}
</script>