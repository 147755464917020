<template>
    <responsive-width >
      <div class="alerts">
        
        <div class="tabs">
            <router-link 
            v-for="tab in tabs" 
            :key="tab.route" 
            :to="{name: tab.route}" 
            class="tab-item font-small flex-grow-1"
            active-class="active">
            {{ tab.name }}
            </router-link>
        </div>

        <vendor-profile :merchant="merchant"/>
        
        <alert-title :type="$route.params.type"/>

        <view-returns v-if="isReturns"/>
        <view-subscriptions v-if="isSubsrciptions" :merchant="merchant"/>
      </div>
    </responsive-width>
</template>
  
<script>
import { mapActions } from 'vuex'
import VendorProfile from '@/views/customer/alerts/partials/Vendor.vue';
import AlertTitle from '@/views/customer/alerts/partials/AlertTitle.vue';
import ViewReturns from '@/views/customer/alerts/returns/Show.vue';
import ViewSubscriptions from '@/views/customer/alerts/subscriptions/Show.vue';

export default {
    components: {
        VendorProfile,
        AlertTitle,
        ViewReturns,
        ViewSubscriptions
    },
    data() {
        return {
            merchant: null,
            tabs: [
                { route: 'returns', name: 'Returns' },
                { route: 'subscriptions', name: 'Subscriptions' },
                { route: 'offers', name: 'Offers' },
                { route: 'warranties', name: 'Warranties' },
            ]
        }
    },
    computed: {
        isReturns() {
            return this.$route.params.type === 'returns'
        },
        isSubsrciptions() {
            return this.$route.params.type === 'subscriptions'
        },
        vendor() {
            return this.$route.params.vendor;
        },
    },
    methods: {
        ...mapActions({
            setPHeaderTitle: 'setPHeaderTitle',
            syncMerchant: 'Merchants/show',
            findMerchant: 'Merchants/find'
        })
    },
    created() {
        // this.setPHeaderTitle('View Alert(s)')

        this.findMerchant({id: this.vendor}).then(response => {
            this.merchant = response;
        });

        this.syncMerchant({id: this.vendor})
        .then(response => {
            this.merchant = response;
        }).catch(error => {
            console.log('ERROR', error);
        });
    }
}
</script>

<style>

</style>