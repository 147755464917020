import { axios } from './index';

export default {
    getCheckout(params) {
        return axios.get('checkouts', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    storeShippingInfo(data) {
        return axios.post('checkouts/shipping-info', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    setupPaymentMethod(data) {
        return axios.post('checkouts/payment-methods/setup', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    savePaymentMethod(data) {
        return axios.post('checkouts/payment-methods', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    confirmPayment(data) {
        return axios.post('checkouts/confirm-payment', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    }
}