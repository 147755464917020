<template>
    <responsive-width :noPadding="true" class="onboarding">
        <div class="heading">
            <div class="d-flex justify-content-between align-items-center">
                <div class="logox">
                    <img src="img/logotext.svg"/>
                </div>
                <div class="text-right">
                    <button @click="toLogin" type="button" class="btn btn-link text-uppercase">Login</button>
                </div>
            </div>
        </div>

        <div class="w-content">
            <carousel :loop="false" :swippable="true" v-model="activeIndex" @swipped="onSwipped">
                <carousel-item>
                    <div class="w-content" v-if="activeIndex == 0">
                        <div class="onboarding-img">
                            <img src="img/man2.svg"/>
                        </div>
                        <div class="text-content">
                            <div class="p-4 text-center">
                                <h3 class="name font-8xl">Imagine</h3>
                                <p class="desc font-medium text-muted">you had a smart “concierge” simplifying and protecting all your online shopping journeys</p>
                            </div>
                        </div>
                    </div>
                </carousel-item>
                <carousel-item>
                    <div class="w-content" v-if="activeIndex == 1">
                        <div class="onboarding-img">
                            <img src="img/girl1.svg"/>
                        </div>
                        <div class="text-content">
                            <div class="p-4 text-center">
                                <h3 class="name font-8xl text-no-wrap">Free Yourself</h3>
                                <p class="desc font-medium text-muted"> from the vicious cycle of searching, joggling website links, disruptive & irrelevant pop-up ads, ......</p>
                            </div>
                        </div>
                    </div>
                </carousel-item>
                <carousel-item>
                    <div class="w-content" v-if="activeIndex == 2">
                        <div class="onboarding-img">
                            <img src="img/portia.svg"/>
                        </div>

                        <div class="text-content">
                            <div class="p-4 text-center">
                                <h3 class="name font-8xl">Shoppers Empowered</h3>
                                <p class="desc font-medium text-muted"><em>Everything</em> a shopper needs for <i>every</i> phase of <i>every</i> shopping journey. <i>All</i> managed from a <i>single</i> app.</p>
                            </div>
                        </div>
                    </div>
                </carousel-item>
            </carousel>
        </div>


        <div class="footing">
            <div class="pl-5 pr-5 d-flex justify-content-between">
                <div class="d-flex welcome-sliders">
                    <div class="item" :class="{active: activeIndex == 0}"></div>
                    <div class="item" :class="{active: activeIndex == 1}"></div>
                    <div class="item" :class="{active: activeIndex == 2}"></div>
                </div>

                <span class="text-black-fade">SWIPE RIGHT</span>
            </div>
            
            <div class="text-center mt-5 pb-3">
                <button @click="next" class="btn btn-primary font-medium get-started-btn elevation">
                    <template v-if="activeIndex < 2">
                        {{activeIndex+1}}/3 Continue <i class="mdi mdi-chevron-double-right"></i>
                    </template>
                    <template v-else>
                        3/3 Get Started
                    </template>
                </button>
            </div>
        </div>
    </responsive-width>
</template>
<script>
import Carousel from '@/components/shared/carousel/Carousel.vue';
import CarouselItem from '@/components/shared/carousel/CarouselItem.vue';

export default {
    components: {
        Carousel,
        CarouselItem,
    },

    data() {
        return {
            activeIndex: 0,
            onboarding: 'onboarding',
            done: 'done'
        }
    },

    methods: {
        onSwipped(index) {
            this.activeIndex = index;
        },

        completeOnboarding() {
            localStorage.setItem(this.onboarding, this.done);
        },
        
        next() {
            if (this.activeIndex < 2) {
                this.activeIndex++;
            } else {
                this.completeOnboarding();
                this.$router.push({name: 'register', params: {stage: 'moods'}});
            }
        },

        toLogin() {
            this.completeOnboarding();
            this.$router.push({name: 'login'});
        }
    },

    beforeCreate() {
        if (localStorage.getItem(this.onboarding) == this.done) {
            this.$router.push({name: 'login'});
        }
    }
}
</script>