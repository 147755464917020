<template>
    <div class="product-detail" @click.prevent.stop="">
        <div class="pd-media">
            <img class="main-img" :src="activeMedia.file_url" />

            <div class="other-images">
                <img v-for="(m, i) in medias" :key="m.id" class="avatar-md" :class="{ 'active': activeMedia.id === m.id }"
                    :src="m.file_url" @click="activeMedia = { ...m, index: i }" />
            </div>

            <div class="pd-slider-controls">
                <div class="control" v-for="(m, i) in medias" :key="i" :class="{ 'active': activeMedia.index == i }"
                    @click="activeMedia = { ...m, index: i }">
                </div>
            </div>
        </div>

        <!-- <div class="pd-product-preferences" v-if="ad.preferences.length">
            <img class="pdp-item avatar-md" v-for="p in ad.preferences" :src="p.image_url" :key="p.id" />
        </div> -->

        <div class="pd-content">
            <div class="">
                <div class="pdc-actions">
                    <div class="d-flex align-items-center">
                        <button type="button" class="btn">
                            <i class="mdi mdi-plus"></i>
                        </button>

                        <div class="ml-3">
                            <h5 class="pdc-price">{{ attributes.price }}</h5>
                        </div>
                    </div>

                    <button type="button" class="btn">
                        <i class="mdi mdi-heart-outline"></i>
                    </button>
                </div>

                <div class="summary flex-grow-1">
                    <div class="merchant-prod">
                        <img v-if="merchant" :src="merchant.logo_url || merchant.logo" class="avatar-xs circle bg-white"/>
                        <h5 class="pdc-name">{{ ad.name }}</h5>
                    </div>
                    <read-more-text :text="attributes.description || ''" className="m-0 pdc-desc text-muted text-justify" />
                </div>
            </div>

            <div class="p-line"></div>

            <div class="more-info mb-5">
                <nav class="pdc-subnav">
                    <button type="button" class="btn active">Detail</button>
                    <button type="button" class="btn">Preferences</button>
                    <button type="button" class="btn">Claims</button>
                </nav>
            </div>

            <div class="p-line"></div>

            <div class="pdc-related-ads">
                <div class="row">
                    <div class="col-12 col-md-4" v-for="(ra, i) in relatedAds" :key="i">
                        <related-prod :ad="ra" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RelatedProd from './related-prod.vue';
import bus from '@/event-bus';
import {get} from 'lodash'

export default {
    props: ['ad'],
    components: { RelatedProd },

    data() {
        return {
            activeMedia: null,

            preferences: [
                'allnatural.png',
                'black-owned.jpg',
                'eco-friendly.png',
                'organic.png',
                'recyclable.png',
                'womenowned.png'
            ]
        }
    },

    computed: {
        medias() {
            return this.ad.media_files || [];
        },

        attributes() {
            let data = {};
            data.price = get(this.ad, 'currency', '$') + Number(this.ad.price).toFixed(2);
            data.description = this.ad.description || this.ad.product.description;
            return data;
        },

        merchant() {
            return this.ad ? this.ad.merchant : null;
        },

        relatedAds() {
            return get(this.ad, 'related_ads', [])
        }
    },

    watch: {
        ad: {
            deep: true,
            handler() {
                // this.setPheaderTitle(this.ad.name);
            }
        }
    },

    methods: {
        cancelDetail() {
            bus.$emit('close-product-details-popup');
        }
    },

    mounted() {
        // console.log(this.ad)
    },

    created() {
        // this.setPheaderTitle('Product Detail');

        this.activeMedia = {
            ...this.medias[0],
            index: 0
        };
    }
}
</script>

<style></style>