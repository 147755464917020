import Login from '@/views/common/authentication/Login.vue';
import VerifyEmail from '@/views/common/verifications/Email.vue';
import ResendEmail from '@/views/common/verifications/ResendEmail.vue';
import ResetPassword from '@/views/common/authentication/ResetPassword.vue';
import ForgotPassword from '@/views/common/authentication/ForgotPassword.vue';
import SocialLogin from '@/views/common/authentication/SocialLogin.vue';
import FinishSetup from '@/views/common/authentication/FinishSetup.vue';

import GettingStarted from '@/views/customer/getting-started/Index.vue';
import GSProductListings from '@/views/customer/getting-started/ProductListing.vue';
import Register from '@/views/customer/getting-started/Register.vue';
import GSProductDetails from '@/views/customer/getting-started/ProductDetail.vue';

import GuestHoldlist from '@/views/customer/holdlists/Guest.vue';
import WelcomePage from '@/views/common/Welcome.vue';

let links = [
    {
        path: '/',
        name: 'welcome',
        component: WelcomePage,
    },

    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },

    {
        path: '/getting-started',
        name: 'getting_started',
        component: GettingStarted,
        props: true
    },

    {
        path: '/getting-started/products',
        name: 'getting_started.products',
        component: GSProductListings
    },

    {
        path: '/getting-started/products/:id',
        name: 'getting_started.products.details',
        component: GSProductDetails
    },

    {
        path: '/getting-started/register',
        name: 'register',
        component: Register
    },

    {
        path: '/shlguest/:public_id',
        name: 'guest_holdlist',
        props: true,
        component: GuestHoldlist
    },

    {
        path: '/passwords/forgot',
        name: 'forgot_password',
        component: ForgotPassword,
        meta: {
            title: 'Forgot Password'
        }
    },

    {
        path: '/passwords/reset/:token',
        name: 'reset_password',
        props: true,
        component: ResetPassword,
        meta: {
            title: 'Reset Password'
        }
    },

    {
        path: `/verifications/email/:token?`,
        name: 'verify_email',
        props: true,
        component: VerifyEmail,
        meta: {
            title: `Verify Email Address`
        }
    },

    {
        path: `/verifications/resend_email`,
        name: 'resend_email',
        props: true,
        component: ResendEmail,
        meta: {
            title: `Verify Email Address`
        }
    },

    {
        name: 'social_login',
        path: '/authorize/:provider/callback',
        component: SocialLogin,
        props: true,
        meta: {
            title: `Login With Social Media`
        }
    },

    {
        name: 'finish_setup',
        path: '/complete-setup',
        component: FinishSetup,
        meta: {
            title: 'Complete Your Account Setup'
        }
    }
]

export default links;