<template>
    <div class="">
        <div class="bg-white p-2">
            <div class="btn-group" role="group">
                <button 
                    @click="activeTab = 'email'"
                    type="button" 
                    class="btn" 
                    :class="isEmail ? 'btn-primary' : 'btn-default'">
                    Email Address
                </button>

                <button 
                    @click="activeTab = 'phone'"
                    type="button" 
                    class="btn"
                    :class="isPhone ? 'btn-primary' : 'btn-default'">
                    Phone Number
                </button>
            </div>
        </div>
        
        <form @submit.prevent="onSubmit" class="form bg-white p-4 elevation">

            <div class="" v-if="currentStep === 1">
                <div class="bg-default d-flex flex-column p-2 mb-5">
                    <span class="font-weight-bold">Current <span class="text-capitalize">{{ activeTab }}</span></span>
                    {{ isEmail ? authUser.email : authUser.phone }}
                </div>
    
                <base-input
                    v-if="isEmail"
                    v-model="email"
                    type="email"
                    label="Enter New Email Address"
                    placeholder="e.g johndoe@example.com"
                />
                
                <base-input
                    v-if="isPhone"
                    v-model="phone"
                    type="tel"
                    label="Enter New Phone Number"
                    placeholder="e.g +12345678901"
                />
            </div>

            <div class="code-info" v-if="currentStep === 2">
                <div class="bg-default p-3 d-flex align-items-center">
                    <i class="mdi mdi-information text-black font-big"></i> 
                    <div class="ml-3">
                        Unique 6 digit verification codes have been sent to both 
                        <span class="text-primary">{{authUser[activeTab]}}</span> and 
                        <span class="text-primary">{{ value }}</span>. Enter the codes below to verify this action.
                    </div>
                </div>

                <div class="p-3 mt-3">
                    <div>
                        Enter code sent to <span class="text-primary">{{authUser[activeTab]}}</span>
                    </div>
                    <base-input
                        v-model="current_code"
                        type="number"
                        placeholder="e.g 123499"
                    />
                </div>

                <div class="p-3">
                    <div>
                        Enter code sent to <span class="text-primary">{{ value }}</span>
                    </div>
                    <base-input
                        v-model="new_code"
                        type="number"
                        placeholder="e.g 382787"
                    />
                </div>
            </div>

            <div class="">
                <button
                    type="submit"
                    class="btn elevation btn-block font-xs text-white text-uppercase"
                    :class="enableSubmitBtn ? 'btn-secondary' : 'btn-default text-black'">
                    <i class="mdi mdi-swap-horizontal"></i> Change {{ activeTab }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
    data() {
        return {
            currentStep: 1,
            activeTab: 'email',

            email: '',
            phone: '',
            current_code: '',
            new_code: '',

            validCodeLength: 6
        }
    },

    computed: {
        isEmail() {
            return this.activeTab === 'email';
        },
        isPhone() {
            return this.activeTab === 'phone';
        },

        enableSubmitBtn() {
            if (this.currentStep === 1) {
                if (this.isEmail) {
                    return this.authUser.email !== this.email && this.validateEmail(this.email);
                } else {
                    return this.authUser.phone !== this.phone && this.validatePhone(this.phone);
                }
            } else {
                const reg = /^\d+$/;
                return this.current_code.length === this.validCodeLength 
                    && this.new_code.length === this.validCodeLength 
                    && this.current_code !== this.new_code
                    && reg.test(this.current_code)
                    && reg.test(this.new_code);
            }
        },

        value() {
            return this[this.activeTab];
        }
    },

    methods: {
        ...mapActions({
            updateEmailPhone: 'Authentications/updateEmailPhone',
            sendVerificationCode: 'Authentications/sendVerificationCode'
        }),

        validateEmail(email) {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return regex.test(email);
        },

        validatePhone(phone) {
            const regex = /^\+[1-9]\d{1,14}$/;
            return regex.test(phone);
        },

        onSubmit() {
            if (!this.enableSubmitBtn) {
                return;
            }

            if (this.currentStep === 1) {
                this.sendCode();
            } else {
                this.processUpdate();
            }
        },

        sendCode() {
            this.showLoader(`Sending verification code... Please wait`);

            this.sendVerificationCode({
                type: this.activeTab,
                value: this.value
            })
            .then(async (response) => {
                this.hideLoader();
                this.currentStep = 2;
                this.notifyInfo(`
                <div class="text-left">
                    Unique 6 digit verification codes have been sent to both 
                    <span class="text-black">${this.authUser[this.activeTab]} (current ${this.activeTab})</span> and 
                    <span class="text-black">${ this.value } (new ${this.activeTab})</span>. 
                    Enter the codes below to verify this action.
                </div>
                `);
            })
            .catch(error => {
                this.hideLoader();
                this.notifyError(error.message);
            })
        },

        processUpdate() {
            this.showLoader(`Updating your ${this.activeTab}... Please wait`);

            const data = {
                value: this.value,
                type: this.activeTab,
                current_code: this.current_code,
                new_code: this.new_code
            }

            this.updateEmailPhone(data)
                .then(response => {
                    this.hideLoader();
                    this.notifySuccess(`Your ${this.activeTab} has been updated successfully`);
                    this.reset();
                })
                .catch(error => {
                    this.hideLoader();
                    this.notifyError(error.message);
                })
        },
        
        reset() {
            const fields = ['email', 'phone', 'current_code', 'new_code'];
            fields.forEach(field => this[field] = '');
            this.currentStep = 1;
        }
    },

    created() {
    }
}
</script>