<template>
    <div class="dropdown">
        <a class="dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-menu font-big"></i>
        </a>

        <div class="dropdown-menu" aria-labelledby="menuoptions">
            <router-link :to="{ name: 'login' }">
                <a class="dropdown-item" href="#">Login</a>
            </router-link>
            <a class="dropdown-item" href="https://woonmi.com/faq" target="_blank">FAQ</a>
            <a class="dropdown-item" href="https://woonmi.com/#contactus" target="_blank">Contact US</a>
        </div>
    </div>
</template>

<script>
export default {

}
</script>