<template>
    <div class="customize-products">
        <div class="text-center text-black mb-2">
            <span class="font-weight-bold">
                {{ progress.current }}
            </span>/
            <span>{{ progress.total }}
                - <span class="">{{ productType ? productType.name : 'Product' }}</span>
            </span>
        </div>

        <div id="product-features">
            <div class="card">
                <div class="card-header p-0" id="brands">
                    <h5 class="mb-0">
                        <button 
                            class="btn btn-link btn-block d-flex justify-content-between align-items-center" 
                            data-toggle="collapse" 
                            data-target="#brandsCollapse" 
                            aria-expanded="true" 
                            aria-controls="brandsCollapse">
                            Brands <i class="mdi mdi-chevron-down font-xl"></i>
                        </button>
                    </h5>
                </div>

                <div id="brandsCollapse" class="collapse show" aria-labelledby="brands" data-parent="#product-features">
                    <div class="card-body">
                        <brands 
                            :productTypes="productTypes" 
                            v-model="payload.brands"/>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header p-0" id="colors">
                    <h5 class="mb-0">
                        <button 
                            class="btn btn-link btn-block d-flex justify-content-between align-items-center" 
                            data-toggle="collapse" 
                            data-target="#colorsCollapse" 
                            aria-expanded="true" 
                            aria-controls="colorsCollapse">
                            Colors <i class="mdi mdi-chevron-down font-xl"></i>
                        </button>
                    </h5>
                </div>

                <div id="colorsCollapse" class="collapse" aria-labelledby="colors" data-parent="#product-features">
                    <div class="card-body">
                        <colors 
                            :productTypes="productTypes" 
                            v-model="payload.colors"/>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header p-0" id="sizes">
                    <h5 class="mb-0">
                        <button 
                            class="btn btn-link btn-block d-flex justify-content-between align-items-center" 
                            data-toggle="collapse" 
                            data-target="#sizesCollapse" 
                            aria-expanded="true" 
                            aria-controls="sizesCollapse">
                            Sizes <i class="mdi mdi-chevron-down font-xl"></i>
                        </button>
                    </h5>
                </div>

                <div id="sizesCollapse" class="collapse" aria-labelledby="sizes" data-parent="#product-features">
                    <div class="card-body">
                        <sizes 
                            :productTypes="productTypes" 
                            v-model="payload.sizes"/>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header p-0" id="materials">
                    <h5 class="mb-0">
                        <button 
                            class="btn btn-link btn-block d-flex justify-content-between align-items-center" 
                            data-toggle="collapse" 
                            data-target="#materialsCollapse" 
                            aria-expanded="true" 
                            aria-controls="materialsCollapse">
                            Materials <i class="mdi mdi-chevron-down font-xl"></i>
                        </button>
                    </h5>
                </div>

                <div id="materialsCollapse" class="collapse" aria-labelledby="materials" data-parent="#product-features">
                    <div class="card-body">
                        <materials 
                            :productTypes="productTypes" 
                            v-model="payload.materials"/>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header p-0" id="prodPref">
                    <h5 class="mb-0">
                        <button 
                            class="btn btn-link btn-block d-flex justify-content-between align-items-center" 
                            data-toggle="collapse" 
                            data-target="#prodPrefCollapse" 
                            aria-expanded="true" 
                            aria-controls="prodPrefCollapse">
                            Preferences <i class="mdi mdi-chevron-down font-xl"></i>
                        </button>
                    </h5>
                </div>

                <div id="prodPrefCollapse" class="collapse" aria-labelledby="prodPref" data-parent="#product-features">
                    <div class="card-body">
                        <preferences
                            type="product"
                            v-model="payload.preferences"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="navigation-btns mt-4">
            <next-btn 
                :progress="progress" 
                submitText="Finish"
                @goNext="goNext"/>
        </div>
    </div>
</template>
<script>
import { get } from 'lodash';
import Sizes from './partials/sizes.vue';
import Brands from './partials/brands.vue';
import Colors from './partials/colors.vue';
import NextBtn from './partials/next-btn.vue';
import Preferences from './partials/preferences.vue';
import { mapActions, mapGetters } from 'vuex';
import Materials from './partials/Materials.vue';
import Carousel from '@/components/shared/carousel/Carousel.vue';
import CarouselItem from '@/components/shared/carousel/CarouselItem.vue';

export default {
    components: {
        Carousel,
        CarouselItem,
        NextBtn,
        Sizes,
        Brands,
        Colors,
        Materials,
        Preferences,
    },

    data() {
        return {
            index: 0,
            payload: {},
            stepIndexes: {
                brands: 0,
                colors: 1,
                sizes: 2,
                materials: 3,
            }
        }
    },

    computed: {
        ...mapGetters({
            productTypes: 'CreateChannels/getProductTypes',
            productsConfig: 'CreateChannels/getCustomizedProducts',
        }),

        productType() {
            if (this.index < 0 || this.index >= this.productTypes.length) {
                return null;
            }

            return this.productTypes[this.index];
        },

        progress() {
            return {
                current: this.index + 1,
                total: this.productTypes.length || 1
            }
        },
    },

    watch: {
        payload: {
            handler() {
                this.setData();
            },
            deep: true
        },

        index() {
            this.reset();
        },
    },

    methods: {
        ...mapActions({
            setCustomize: 'CreateChannels/setCustomize'
        }),

        reset() {
            this.payload = {
                brands: get(this.productsConfig, `${this.productType.id}.brands`, []),
                colors: get(this.productsConfig, `${this.productType.id}.colors`, []),
                sizes: get(this.productsConfig, `${this.productType.id}.sizes`, []),
                materials: get(this.productsConfig, `${this.productType.id}.materials`, []),
                preferences: get(this.productsConfig, `${this.productType.id}.preferences`, []),
            }
        },

        setData() {
            const data = {
                ...this.productsConfig,
                [this.productType.id]: {...this.payload}
            };

            this.setCustomize({ key: 'products', value: data });
        },

        goNext() {
            if (this.index < this.productTypes.length - 1) {
                this.index++;
            } else {
                this.$emit('done');
            }
        }
    },

    mounted() {
        this.reset();
    }
}
</script>
