<template>
    <div class="customize-products">
        <div class="text-center text-black mb-2">
            <span class="font-weight-bold">
                {{ progress.current }}
            </span>/
            <span>{{ progress.total }}
                - <span class="">{{ productType ? productType.name : 'Product' }}</span>
            </span>
        </div>

        <div id="vendor-features">
            <div class="card">
                <div class="card-header p-0" id="return_policy">
                    <h5 class="mb-0">
                        <button 
                            class="btn btn-link btn-block d-flex justify-content-between align-items-center" 
                            data-toggle="collapse" 
                            data-target="#return_policyCollapse" 
                            aria-expanded="true" 
                            aria-controls="return_policyCollapse">
                            Return Policy <i class="mdi mdi-chevron-down font-xl"></i>
                        </button>
                    </h5>
                </div>

                <div id="return_policyCollapse" class="collapse show" aria-labelledby="return_policy" data-parent="#vendor-features">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="text-black">Must have Returns</label>
                            <base-switch v-model="payload.return_policy"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header p-0" id="deliveryDeadline">
                    <h5 class="mb-0">
                        <button 
                            class="btn btn-link btn-block d-flex justify-content-between align-items-center" 
                            data-toggle="collapse" 
                            data-target="#deliveryDeadlineCollapse" 
                            aria-expanded="true" 
                            aria-controls="deliveryDeadlineCollapse">
                            Delivery Deadline <i class="mdi mdi-chevron-down font-xl"></i>
                        </button>
                    </h5>
                </div>

                <div id="deliveryDeadlineCollapse" class="collapse show" aria-labelledby="deliveryDeadline" data-parent="#vendor-features">
                    <div class="card-body">
                        <p class="mb-2 text-black font-small">Item(s) must arrive by:</p>
                        <base-input
                            title="Select Date"
                            v-model="payload.deadline"
                            type="date"
                            placeholder="Select Deadline Date">
                        </base-input>
                    </div>
                </div>
            </div>
        </div>

        <div class="navigation-btns mt-4">
            <next-btn 
                :progress="progress" 
                submitText="Finish"
                @goNext="goNext"/>
        </div>
    </div>
</template>
<script>
import { get } from 'lodash';
import NextBtn from './partials/next-btn.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        NextBtn,
    },

    data() {
        return {
            index: 0,

            payload: {
                return_policy: false,
                deadline: '',
            }
        }
    },

    computed: {
        ...mapGetters({
            productTypes: 'CreateChannels/getProductTypes',
            salesConfig: 'CreateChannels/getCustomizedSales',
        }),

        productType() {
            if (this.index < 0 || this.index >= this.productTypes.length) {
                return null;
            }

            return this.productTypes[this.index];
        },

        progress() {
            return {
                current: this.index + 1,
                total: this.productTypes.length || 1
            }
        },
    },

    watch: {
        payload: {
            handler() {
                this.setData();
            },
            deep: true
        },

        index() {
            this.reset();
        },
    },

    methods: {
        ...mapActions({
            setCustomize: 'CreateChannels/setCustomize'
        }),

        reset() {
            this.payload = {
                return_policy: get(this.salesConfig, `${this.productType.id}.return_policy`, false),
                deadline: get(this.salesConfig, `${this.productType.id}.deadline`, ''),
            }
        },

        setData() {
            const data = {
                ...this.salesConfig,
                [this.productType.id]: {...this.payload}
            };

            this.setCustomize({key: 'sales', value: data});
        },

        goNext() {
            if (this.index < this.productTypes.length - 1) {
                this.index++;
            } else {
                this.$emit('done');
            }
        }
    },

    mounted() {
        this.reset();
    }
}
</script>
