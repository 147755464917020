<template>
    <div>
        <button type="button" class="btn control back elevation-2" @click.prevent="cancel">
            <i class="mdi mdi-arrow-left"></i>
        </button>
        
        <button type="button" class="btn control cancel elevation-2" @click.prevent="cancel">
            <i class="mdi mdi-close"></i>
        </button>

        <div class="left" @click.prevent="setMediaIndex(index - 1)">
            <button type="button" class="btn control elevation-2">
                <i class="mdi mdi-chevron-left"></i>
            </button>
        </div>

        <div class="right" @click.prevent="setMediaIndex(index + 1)">
            <button type="button" class="btn control elevation-2">
                <i class="mdi mdi-chevron-right"></i>
            </button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        index: {
            type: Number,
            required: true
        }
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        setMediaIndex(index) {
            this.$emit('setMediaIndex', index);
        }
    }
}
</script>