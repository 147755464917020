<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="false"
    :maxHeight="true"
    okText="Add Note"
    okIcon="mdi mdi-plus"
    @ok="submit"
    @cancel="cancel">

    <h5 class="m-0 font-small font-weight-normal text-capitalize" slot="header">Delete {{ name }}</h5>

    <div slot="body" class="p-1 font-small">
        <div class="d-flex">
            <img v-if="url" class="avatar-md mr-2 circle" style="object-fit:cover;" :src="url"/>

            <div class="flex-grow-1">
                <p class="p-2 text-justify font-small">
                    You have not viewed {{ name }} and will never see it again once you delete it. Please confirm.
                </p>

                <p class="text-muted text-left font-small">
                    <label>
                        <input v-model="hidePopup" type="checkbox" style="position:relative;bottom:-3px;" class="mr-1"/>
                        Next time do not show this popup
                    </label>
                </p>
            </div>
        </div>


        <div class="row no-gutters mb-3">
            <div class="col-6 p-1">
                <button type="button" @click="submit" class="btn btn-danger elevation font-small login-btn btn-block">
                    <i class="fa fa-trash mr-1"></i> CONFIRM
                </button>
            </div>

            <div class="col-6 p-1">
                <button type="button" @click="cancel" class="btn btn-default-dark elevation font-small login-btn btn-block">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import bus from '@/event-bus.js';
import storage from '@/storage.js';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: [],

    data() {
        return {
            hidePopup: false,
            show: false,
            name: '',
            url: '',
            advert_id: '',
            eventName: 'delete-status-update'
        }
    },

    methods: {
        ...mapActions({
            delete: 'ChannelStreams/delete',
        }),

        cancel(clear = true) {
            this.show = false;

            if (clear) {
                this.name = '';
                this.advert_id = '';
                this.url = '';
            }
        },

        deleteCampaign(params) {
            this.name = params.name;
            this.advert_id = params.advert_id;
            this.url = params.url;
            this.show = true;
        },

        submit() {
            this.cancel(false);

            if (this.hidePopup) {
                storage.set('hide-delete-update-popup', 1);
            }

            this.delete(this.advert_id)
            .then(response => {
                this.cancel();
            })
            .catch(error => null)
        }
    },

    created() {
        bus.$on(this.eventName, this.deleteCampaign);
    },

    beforeDestroy() {
        bus.$on(this.eventName, this.deleteCampaign);
    }
}
</script>