<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="false"
    :maxHeight="true"
    okText="Add Note"
    okIcon="mdi mdi-plus"
    @ok="submit"
    @cancel="cancel">

    <h5 class="m-0 font-small font-weight-normal text-capitalize" slot="header">Report {{ name || 'Advert' }}</h5>

    <div slot="body" class="p-1 font-small">
        <div class="d-flex">
            <img v-if="url" class="avatar-md mr-2 circle" style="object-fit:cover;" :src="url"/>

            <div class="flex-grow-1">
                <label
                    v-for="(reason, index) in reasons"
                    :key="index"
                    class="cursor-pointer bg-default d-block p-2 rounded mb-1 font-xs"
                    :for="`reason_${reason.id}`">
                    <input
                        type="radio"
                        class="mr-2 special_feature"
                        :value="reason.id"
                        :id="`reason_${reason.id}`"
                        name="report_reason"
                        v-model="reason_id"/>
                    <span>{{ reason.name }}</span>
                </label>
            </div>
        </div>


        <div class="row no-gutters mt-3 mb-3">
            <div class="col-6 p-1">
                <button type="button" @click="cancel" class="btn btn-default-dark elevation font-small login-btn btn-block">
                    Cancel
                </button>
            </div>
            <div class="col-6 p-1">
                <button type="button" @click="submit" class="btn btn-danger elevation font-small login-btn btn-block">
                    <i class="mdi mdi-flag-checkered mr-1"></i> REPORT
                </button>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import storage from '@/storage.js';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['advert','show'],

    data() {
        return {
            values: [],
            reason_id: ''
        }
    },

    computed: {
        ...mapGetters({
            reasons: 'ChannelStreams/getReportReasons'
        }),

        name() {
            return this.advert && this.advert.name
        },

        url() {
            if (this.advert && this.advert.cover_media) {
                return this.advert.cover_media.file_url
            }
        }
    },

    watch: {
        reasons() {
            this.setValues();
        }
    },

    methods: {
        ...mapActions({
            delete: 'ChannelStreams/delete',
            reportAdvert: 'ChannelStreams/reportAdvert',
        }),

        setValues() {
            // return;
            for (let reason of this.reasons) {
                this.values[`reason_${reason.id}`] = false;
            }
        },

        cancel(clear = true) {
            this.$emit('cancel');
        },

        getReasons() {
            let ids = [], id;

            for (let key of Object.keys(this.values)) {
                if (this.values[key]) {
                    id = parseInt(key.split('_').pop());

                    if (id) {
                        ids.push(id);
                    }
                }
            }

            return ids;
        },

        submit() {
            let params = {
                advert_id: this.advert.id,
                reason_id: this.reason_id
            };

            this.cancel();

            this.reportAdvert(params)
            .then(response => {
                console.log(response)
                this.reason_id = '';

                this.$emit('advert-reported', this.advert.id);
            })
            .catch(error => null)
        }
    },

    mounted() {
        this.setValues();
    }
}
</script>