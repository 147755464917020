<template>
    <div class="create-channel">
        <div class="responsive-page-width">
            <div class="row justify-content-center">
                <div class="col-12 col-md-5">
                    <p class="mb-4 text-muted">Provide Password and other info to secure  your account</p>

                    <form @submit.prevent="submit">
                        <base-input
                            name="password"
                            type="password"
                            placeholder="Enter Your Password"
                            label="Password"
                            v-model="payload.password"
                            :error="validationErrors.password" />

                        <base-input
                            name="password_confirmation"
                            type="password"
                            placeholder="Re-Enter Your Password"
                            label="Password Confirmation"
                            v-model="payload.password_confirmation"
                            :error="validationErrors.password_confirmation" />

                        <hr/>

                        <base-select
                            v-model="payload.country_id"
                            label="Country"
                            required
                            class="filter elevation"
                            :error="validationErrors.country_id">
                            <option value="">None</option>
                            <option v-for="(country, index) in countries" :key="index" :value="country.id">
                                {{ country.name }} ({{ country.country_code }})
                            </option>
                        </base-select>

                        <base-input
                            name="phone"
                            type="number"
                            placeholder="Enter Your Phone Number"
                            label="Phone Number"
                            v-model="payload.phone"
                            :error="validationErrors.phone"
                            :prepend="!!setSelectedCountry"
                            :append="!!payload.phone">
                            <span slot="prepend" class="font-xs font-weight-bold" v-if="setSelectedCountry">
                                {{ setSelectedCountry.country_code }}
                            </span>
                            <button v-if="!!payload.phone" type="button" class="btn btn-xs font-xs btn-success p-1 elevation" slot="append">
                                Verify
                            </button>
                        </base-input>

                        <div class="border-wrap">
                            <button type="submit" class="btn create-account btn-block">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PHeader from '../../../components/shared/PHeader.vue'
export default {
    components: { PHeader },
    data() {
        return {
            payload: {
                password: '',
                password_confirmation: '',
                country_id: '',
                phone: '',
            },

            validationErrors: {
                password: '',
                password_confirmation: '',
                country_id: '',
                phone: '',
            },

            selectedCountry: null,
        }
    },

    computed: {
        ...mapGetters({
            countries: 'Countries/getAll'
        }),

        setSelectedCountry() {
            return this.countries.find(country => country.id == this.payload.country_id);
        },
    },
    methods: {
        ...mapActions({
            secureAccount: 'Authentications/secureAccount'
        }),

        submit() {
            this.buildValidationErrors({});

            this.showLoader('Securing your Account... Please wait');

            this.secureAccount({ ...this.payload })
                .then(response => {
                    console.log(response);
                    this.hideLoader();
                    this.$router.push({name: 'dashboard'});
                })
                .catch(error => {
                    this.hideLoader();
                    this.swalError(`Attention Required`, `${error.message} Please fix all issues before proceeding.`)
                    this.buildValidationErrors(error.errors);
                })
        },
    },

    created() {
        // this.setPheaderTitle('Secure Your Account')
    }
}
</script>

<style></style>