import { db } from './index';

export default {
    async createMany(prod_types) {
        await db.ProductTypes.bulkAdd(prod_types);
    },

    async getLatest() {
        return await db.ProductTypes.orderBy('id').last();
    },

    async search(params) {
        let colorIndex = 0;
        const searchResults = [];
        const keywords = params.keyword.split(',');

        for (const keyword of keywords) {
            if (!keyword.trim()) {
                continue;
            }

            const keywordParts = keyword.split(' ');
            let productTypes = await db.ProductTypes.filter((productType) => {
                // check that all parts of keyword are present in the product type name
                return keywordParts.every((keywordPart) => {
                    return productType.name.toLowerCase().split(' ').some((productTypeNamePart) => {
                        return productTypeNamePart.startsWith(keywordPart.toLowerCase());
                    });
                }) && params.exclude.includes(productType.id) === false;
            }).toArray();

            if (colorIndex > 4) {
                colorIndex = 0;
            }

            searchResults.push({
                keyword: keyword,
                results: productTypes.map(pt => {
                    pt.colorIndex = colorIndex;
                    return pt;
                })
            });

            colorIndex++;
        }
        
        return searchResults;
    }
}