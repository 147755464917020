<template>
    <div class="row">
        <div class="col-12 col-md-6" v-for="i in 10" :key="i">
            <order-item/>
        </div>
    </div>
</template>
<script>
import OrderItem from './partials/OrderItem.vue';
export default {
    components: {
        OrderItem
    }
}
</script>