<template>
  <div class="holdlist-compare-item elevation p-3 rounded mb-3">
    <div class="ad d-flex mb-2">
        <img @click="viewAd" class="avatar-xs rounded" :src="advert.cover_media.file_url"/>
        <div class="flex-grow-1 ml-3">
            <h5 class="m-0 font-big">
                <a href="#" @click.prevent="viewAd">{{ advert.name }}</a>
            </h5>
            <p class="m-0 font-small text-muted">{{ advert.subtitle }}</p>
        </div>
    </div>

    <div class="attributes d-flex">
        <div class="item mr-2" v-for="n in 5" :key="n">
            <div class="value">
                <div class="val">34g</div>
                <div class="name">Name</div>
            </div>
            <div class="icon">
                <i class="mdi mdi-silverware-variant"></i>
            </div>
        </div>
    </div>

    <div class="actions">
        <div class="d-flex">
            <a
                href="#"
                @click.prevent="!voteUP && interactWithAdvert('like')"
                class="font-small mr-2"
                :class="{'disabled': voteUP }">
                <i class="mdi mdi-thumb-up-outline"></i> Yes
            </a>

            <a
                href="#"
                @click.prevent="!voteDOWN && interactWithAdvert('unlike')"
                class="font-small"
                :class="{ 'disabled': voteDOWN }">
                <i class="mdi mdi-thumb-down-outline"></i> No
            </a>
        </div>

        <a
            href="#"
            @click.prevent="comments"
            class="font-small">
            <i class="mdi mdi-comment-text-outline"></i> Comment
        </a>

        <a
            href="#"
            class="font-small"
            @click.prevent="unhold"
            v-if="isHoldlistOwner">
            <i class="mdi mdi-delete"></i> Remove
        </a>
    </div>
  </div>
</template>

<script>
import bus from '@/event-bus';
import { mapActions } from 'vuex';
import { VOTE_NONE, VOTE_UP, VOTE_DOWN } from '@/constants/holdList';
import { EVENT_SHOW_COMMENTS } from '@/constants/events';

export default {
    props: {
        holdListItem: {
            type: Object,
            required: true
        },
        isHoldlistOwner: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            vote: VOTE_NONE,
        }
    },
    computed: {
        advert() {
            return this.holdListItem && this.holdListItem.advert;
        },

        params() {
            return {
                advert_id: this.holdListItem.advert_id,
                intent_id: this.holdListItem.intent_id
            }
        },

        voteUP() {
            return this.vote === VOTE_UP;
        },

        voteDOWN() {
            return this.vote === VOTE_DOWN;
        },

        canRemove() {
            return this.holdListItem && this.holdListItem.user_id === this.authUser.id;
        }
    },
    watch: {
    },
    methods: {
        ...mapActions({
            updateClicks: 'ChannelStreams/updateClicks',
            holdOrUnhold: 'ChannelStreams/holdOrUnhold',
            interact: 'ChannelStreams/interact',
        }),

        comments() {
            console.log('clicked comments');
            bus.$emit(EVENT_SHOW_COMMENTS, this.holdListItem);
        },

        viewAd() {
            bus.$emit('show-product-details-popup', this.advert.id);
        },

        interactWithAdvert(type) {
            if (type == 'like') {
                this.vote = VOTE_UP;
            } else if (type == 'unlike') {
                this.vote = VOTE_DOWN;
            }

            this.interact({
                ...this.params,
                interaction_type: type,
            }).then(response => {
                // console.log(response);
            })
            .catch(error => null)
        },

        async unhold() {
            let result = await this.swalConfirm(
                `Remove from your hold list?`,
                `This will remove "${this.advert.name}" from your hold list. Are you sure?`, `Remove`
            );

            if (!result.value) return;

            this.holdOrUnhold({
                type: 'unhold',
                ...this.params
            })
            .then(response => null)
            .catch(error => null)

            this.$emit('unhold', this.holdListItem.id);
        }
    },

    created() {
        // console.log(this.holdListItem);
        this.vote = this.holdListItem.vote;
    }
}
</script>

<style>

</style>