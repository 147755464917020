<template>
    <question-answer>
        <question>
            <span slot="progress">
                <span class="font-weight-bold font-big">Products</span>
            </span>
            <div ref="title" class="font-5xl font-thin text-muted"></div>
        </question>


        <answers alignItems="start" :showOptions="showOptions">
            <div class="selected-products" v-if="!isSingle && productTypes.length">
                <i class="mdi mdi-chevron-left icon font-2xl text-white"></i>
                <div class="products no-scrollbar flex-grow-1">
                    <span
                        @click.prevent="removeProductType(pt.id)"
                        class="item text-truncate font-xs" 
                        v-for="pt in productTypes" :key="pt.id"
                        :class="`bc-${pt.colorIndex}`">
                        {{ pt.name }}
                    </span>
                </div>
                <i class="mdi mdi-chevron-right icon font-2xl text-white"></i>
            </div>

            <div class="objective-form">
                <base-input
                  noMargin
                  class="float-input"
                  :placeholder="isSingle ? 'Enter Product Name' : 'Enter product names separated by commas'"
                  reference="product_type"
                  v-model="productTypeText"/>

                  <div class="options mt-4">

                    <div id="accordion">
                        <div class="card" v-for="(item, index) in searchedResults" :key="`acc-${index}`" style="background-color: transparent;border: none;">
                            <div class="card-header p-0" style="background-color: white;" :id="`heading${index}`">
                                <button class="btn btn-link btn-block text-left font-medium" 
                                    data-toggle="collapse" 
                                    :data-target="`#collapse${index}`" 
                                    style="border-radius: 0;"
                                    aria-expanded="true" :aria-controls="`collapse${index}`">
                                    <i class="mdi mdi-plus font-big"></i> Results for: {{ item.keyword }}
                                </button>
                            </div>

                            <div :id="`collapse${index}`" class="collapse mt-2" :class="{show: true}" :aria-labelledby="`heading${index}`" data-parent="#accordion">
                                <div class="row no-gutters">
                                    <div class="col-md-3 col-6 p-1" v-for="(productType, i) in item.results" :key="productType.id">
                                        <div :class="`option bc-${productType.colorIndex}`" @click.prevent="addProductType(productType)">
                                            <div style="width:100%" class="font-medium text-truncate">{{ productType.name }}</div>                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </answers>
    </question-answer>
</template>

<script>
import TypeIt from "typeit";
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import LetsGo from '@/views/customer/channels/create/stages/LetsGo.vue';
import Answers from '@/views/customer/channels/create/stages/partials/Answers.vue';
import Question from '@/views/customer/channels/create/stages/partials/Question.vue';
import QuestionAnswer from '@/views/customer/channels/create/stages/partials/QuestionAnswer.vue';

export default {
    props: {
    },

    components: {
        Answers,
        Question,
        QuestionAnswer,
        LetsGo
    },

    data() {
        return {
            showOptions: false,
            productTypeText: '',
            searchedResults: []
        }
    },

    computed: {
        ...mapGetters({
            mood: 'CreateChannels/getMood',
            productTypes: 'CreateChannels/getProductTypes'
        }),

        isSingle() {
            return this.mood.endsWith('single');
        }
    },

    watch: {
        productTypeText() {
            this.searchProductTypes();
        }
    },

    methods: {
        ...mapActions({
            index: 'ProductTypes/index',
            setValue: 'CreateChannels/setValue'
        }),

        searchProductTypes: debounce(async function() {
            const params = { keyword: this.productTypeText, exclude: this.productTypes.map(pt => pt.id) };
            this.searchedResults = await this.index(params);
        }, 250),

        removeProductType(id) {
            const values = this.productTypes.filter(pt => pt.id != id);
            this.setValue({ key: 'productTypes', value: values });
        },

        addProductType(productType) {
            const exists = this.productTypes.find(pt => pt.id == productType.id)
            
            if (!exists) {
                this.setValue({ key: 'productTypes', value: [...this.productTypes, productType] });
            }
            
            if (this.isSingle) {
                setTimeout(() => {
                    this.goNext();
                }, 0);
            }
        },

        typeHeading() {
            let focus = `product${this.isSingle ? '' : 's'}`;
            let text = `What <span class="font-5xl text-primary">${focus}</span> are you looking for?`;

            const instance = new TypeIt(this.$refs.title, {
                speed: 20,
                startDelay: 100,
                cursor: false,
                afterComplete: async (instance) => {
                    this.showOptions = true;
                },
            })
            .empty()
            .type(text)
            .go()
        },

        goNext() {
            if (this.productTypes.length === 0) return;

            this.$emit('next', 'customize');
        }
    },

    mounted() {
        setTimeout(() => {
            this.typeHeading();
        }, 0);
    },
}
</script>