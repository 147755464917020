import Dexie from 'dexie';

const dbname = 'woonmi';

export const db = new Dexie(dbname);

db.version(1).stores({
  // general models used by all users
  ProductTypes: '&id, name',
  InterestTypes: '&id, name',
  ShoppingObjectives: '&id, name',
  Brands: '&id, name',
  Colors: '&id, name',
  Countries: '&id, name',

  // models specific to users. id is always user_id
  Carts: '&id, value',
  Checkouts: '&id, value',
  Merchants: '&id, name',
  SmartAlerts: '&id',

  // subscription models
  AdvertSubscriptions: '&id',

  SmartAlertHistories: '&id, [item_id+item_type]',
}); 

export const deleteDatabase = () => {
  // delete tables that are user specific
  const tables = ['Carts', 'Checkouts', 'SmartAlerts', 'SmartAlertHistories'];
  tables.forEach(table => {
    db[table].clear();
  });
};