<template>
    <div class="payment-info elevation mb-4">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <h6 class="font-weight-bold font-medium m-0 text-black">Payment Information</h6>
            <button class="btn edit-btn elevation-2" v-if="!showForm" @click="addNewPayment">
                <i class="mdi mdi-credit-card-plus font-medium"></i>
            </button>
            <button class="btn delete-btn elevation-2" v-if="showForm" @click="showForm = false">
                <i class="mdi mdi-close font-medium"></i>
            </button>
        </div>

        <div class="payment-methods" v-if="showForm">
            <div class="item d-flex align-items-center">
                <div id="card-element" class="flex-grow-1"></div>

                <button 
                    type="button" 
                    class="btn btn-secondary elevation-2 ml-3"
                    v-if="displayBtn" 
                    @click="savePaymentDetails">
                    <i class="mdi mdi-credit-card-plus"></i> Add
                </button>
            </div>
        </div>
        
        <form class="payment-methods" v-if="!showForm">
            <div class="item" v-for="pm in paymentMethods" :key="pm.id">
                <label :for="`pm-${pm.id}`" class="checkbox cursor-pointer m-0 d-flex align-items-center text-black font-weight-bold">
                    <input 
                        :id="`pm-${pm.id}`" 
                        v-model="selectedPm" 
                        type="radio" 
                        name="paymentmethod"
                        :value="pm.id" 
                        class="mr-1"/>
                    <span class="check"></span>
                    <span class="text-uppercase bg-default p-0 pl-2 pr-2 border rounded font-xxs mr-1">{{ pm.brand }}</span> ending in {{ pm.last4 }}
                </label>
            </div>
        </form>
    </div>
</template>
<script>
import config from '@/config';
import { mapActions } from 'vuex';

export default {
    props: ['paymentMethods'],

    data() {
        return {
            card: null,
            stripe: null,
            clientSecret: null,
            showForm: false,
            displayBtn: false,
            selectedPm: ''
        }
    },

    watch: {
        selectedPm() {
            this.$emit('payment-method-selected', this.selectedPm)
        },

        paymentMethods() {
            for(let pm of this.paymentMethods) {
                if(pm.is_default) {
                    this.selectedPm = pm.id;
                    break;
                }
            }
        }
    },

    methods: {
        ...mapActions({
            savePaymentMethod: 'Checkouts/savePaymentMethod',
            setupPaymentMethod: 'Checkouts/setupPaymentMethod'
        }),

        addNewPayment() {
            this.showLoader(`Setting up a secure payment form...`)
            this.setupPaymentMethod()
            .then(response => {
                this.clientSecret = response.setup;
                this.initStripe(); 
                this.hideLoader();
            }).catch(error => {
                console.log(error);
                this.hideLoader();
            });
        },

        initStripe() {
            this.showForm = true;

            setTimeout(() => {
                this.stripe = Stripe(config.stripe.publishableKey);
                const elementOptions = {
                    clientSecret: this.clientSecret,
                }
                this.card = this.stripe.elements(elementOptions).create('card');
                this.card.mount('#card-element');
                this.showForm = true;
                this.displayBtn = true;
            }, 0);
        },

        async savePaymentDetails() {
            this.showLoader(`Validating payment details...`);
            const { setupIntent, error } = await this.stripe.confirmCardSetup(this.clientSecret, {
                    payment_method: {
                        card: this.card,
                        billing_details: { name: this.authUser.fullname }
                    }
                }
            );
        
            if (error) {
                console.log(error);
                this.hideLoader();
            } else {
                this.showLoader(`Saving payment method...`);
                this.savePaymentMethod({pm: setupIntent.payment_method}).then(response => {
                    this.showForm = false;
                    this.$emit('payment-method-added', response.pm);
                    this.hideLoader();
                }).catch(error => {
                    console.log(error);
                    this.hideLoader();
                });
            }
        }
    }
}
</script>