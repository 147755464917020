<template>
    <form @submit.prevent="submit" class="card elevation p-4">
        <base-input
            v-model="payload.current_password"
            :error="validationErrors.current_password"
            label="Current Password"
            placeholder="e.g secret"
            type="password"
        />

        <base-input
            v-model="payload.password"
            :error="validationErrors.password"
            label="New Password"
            placeholder="e.g new secret"
            type="password"
        />

        <base-input
            v-model="payload.password_confirmation"
            :error="validationErrors.password_confirmation"
            label="New Password Confirmation"
            placeholder="e.g new secret"
            type="password"
        />
        
        <div class="mb-3">
            <label class="checkbox cursor-pointer d-flex align-items-center text-black-fade">
                <input type="checkbox" name="remember" v-model="payload.logout_sessions" class="mr-1"/>
                <span class="check"></span>
                Logout from all other devices.
            </label>
        </div>

        <div class="p-1">
            <button
                type="submit"
                class="btn elevation btn-block btn-secondary font-xs text-white login-btn">
                <i class="mdi mdi-content-save-edit-outline"></i> UPDATE PASSWORD
            </button>
        </div>
    </form>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
    data() {
        return {
            disableCountry: false,

            payload: {
                current_password: '',
                password: '',
                password_confirmation: '',
                logout_sessions: false
            },

            validationErrors: {
                current_password: '',
                password: '',
                password_confirmation: ''
            }
        }
    },

    methods: {
        ...mapActions({
            updatePassword: 'Authentications/updatePassword'
        }),

        validate() {
            this.validationErrors.current_password = this.payload.current_password ? '' : 'The current password field is required';
            this.validationErrors.password = this.payload.password ? '' : 'The password field is required';
            this.validationErrors.password_confirmation = this.payload.password_confirmation == this.payload.password ? '' : 'The password confirmation field does not match';

            if (this.payload.current_password && this.payload.password && this.payload.current_password == this.payload.password) {
                this.validationErrors.password = `Your new password can not be same as your current password`;
            }

            return Object.values(this.validationErrors).filter(error => !!error);
        },

        submit() {
            this.buildValidationErrors({});

            let errors = this.validate();

            if (errors.length) {
                return this.swalError('Please fix all issues before proceeding');
            }

            this.showLoader('Updating your password...');

            this.updatePassword(this.payload)
                .then(response => {
                    this.hideLoader();
                    this.swalSuccess(response.message);
                    this.$emit('cancel');
                })
                .catch(error => {
                    this.hideLoader();

                    if (error.other_message) {
                        this.swalError('Action not permitted', error.other_message, false);
                    }

                    this.buildValidationErrors(error.errors);
                })
        }
    }
}
</script>