<template>
    <question-answer>
        <question>
            <span slot="progress">
                <span class="font-weight-bold font-big">Mood</span>
            </span>
            <div ref="title" class="font-5xl font-thin text-muted"></div>
        </question>


        <answers :showOptions="showOptions">
            <div class="objective-form">
                <div class="options">
                    <div
                        v-for="(btn, index) in btns"
                        :key="index"
                        class="option d-flex align-items-center justify-content-between elevation"
                        :class="`bc-${index%3}`"
                        @click="selectMood(btn)">
                        <img class="mr-3 font-big icon avatar-xs" :src="btn.icon"/>
                        <div
                            class="flex-grow-1 text text-shadow font-medium"
                            v-html="btn.text">
                        </div>
                        <i class="font-big mdi mdi-chevron-right icon ml-2"></i>
                    </div>
                </div>
            </div>
        </answers>
    </question-answer>
</template>

<script>
import TypeIt from "typeit";
import Answers from '@/views/customer/channels/create/stages/partials/Answers.vue';
import Question from '@/views/customer/channels/create/stages/partials/Question.vue';
import QuestionAnswer from '@/views/customer/channels/create/stages/partials/QuestionAnswer.vue';
import { mapActions } from "vuex";

export default {
    name: 'GettingStartedMood',

    components: {
        Answers,
        Question,
        QuestionAnswer
    },

    data() {
        return {
            showOptions: false,
            btns: [
                {
                    stage: 'objective',
                    icon: 'img/product.png',
                    text: 'A Single Product',
                    mood: 'single',
                },
                {
                    stage: 'objective',
                    icon: 'img/productlisticon.png',
                    text: 'Multiple Products',
                    mood: 'multiple'
                },
                {
                    stage: 'interests',
                    icon: 'img/exploring.png',
                    text: 'Nothing Specific, Just Exploring',
                    mood: 'explore'
                }
            ]
        }
    },

    computed: {
        mood: {
            get() {
                return this.$store.getters['CreateChannels/getMood'];
            },

            set(value) {
                this.setValue({key: 'mood', value});
            }
        }
    },

    methods: {
        ...mapActions({
            setValue: 'CreateChannels/setValue'
        }),

        typeHeading() {
            const objective = `<span class="font-5xl text-primary">shopping for</span>`;

            new TypeIt(this.$refs.title, {
                speed: 20,
                startDelay: 100,
                cursor: false,
                afterComplete: async (instance) => {
                    this.showOptions = true;
                },
            })
                .empty()
                .type(`What are you ${objective}?`)
                .go();
        },

        selectMood(btn) {
            this.mood = btn.mood;
            this.$emit('next', btn.stage);
        }
    },

    mounted() {
        setTimeout(() => {
            this.typeHeading();
        }, 0);
    }
}
</script>