<template>
    <div class="heading d-flex align-items-center justify-content-between">
        <div>
            <h3 class="title m-0 font-xs" v-html="title"></h3>
            <!-- <p class="description text-muted" v-html="desc"></p> -->
        </div>
        <div>
            <button class="btn text-primary p-0">
                <i class="mdi mdi-chevron-down font-xl"></i>
            </button>
        </div>
    </div>
</template>
<script>
export default {
    props: ['title', 'desc']
}
</script>