<template>
    <div class="my-channels mb-4">
        <h5 class="title">My Channels</h5>
        <p class="desc font-xs text-muted text-truncate">Here are your active channels</p>

        <div class="channel-list">
            <div class="row no-gutters">
                <div class="col-2">
                    <router-link :to="{name: 'list_channels'}" >
                        <div class="channel-list-item view-action">
                            <i class="mdi mdi-menu"></i>
                        </div>
                    </router-link>
                </div>

                <div class="col-10" v-if="!items.length">
                    <div class="channel-list-item font-small text-muted">
                        No active channels
                    </div>
                </div>

                <div
                    :class="items.length === 2 ? 'col-5' : 'col-6'"
                    v-for="(item, index) in items"
                    :key="index"
                    @click="viewIntent(item.id)">
                    <div class="channel-list-item" :class="{'active': index === 0}">
                        <div class="icon">
                            <!-- <i class="mdi mdi-briefcase"></i> -->
                            <img src="/img/briefcase-stack.svg" v-if="item.product_types_count > 1"/>
                            <img src="/img/briefcase.svg" v-else/>
                        </div>
                        <p class="m-0 text-truncate font-xs name">{{ item.name }}</p>
                    </div>
                </div>

                <div class="col-2" v-if="more > 2">
                    <router-link :to="{name: 'list_channels'}">
                        <div class="channel-list-item view-more font-small">
                            {{ more - 1 }}+
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['channels'],

    computed: {
        items() {
            return this.channels && this.channels.data ? this.channels.data : [];
        },

        more() {
            return this.channels && this.channels.more;
        }
    },

    methods: {
        viewIntent(intent_id) {
            this.$router.push({ name: 'channels.view', params: { intent_id } });
        }
    }
}
</script>

<style>

</style>