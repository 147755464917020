<template>
  <div class="create-new-channel mb-3">
    
    <p class="text-black font-medium text-center">
        A Woonmi account offers many additional powerful features for customizing and managing your searches and results.
    </p>

    <div class="text-center">
        <router-link :to="{name: 'register', params: $route.params}">
            <button class="btn btn-primary font-medium get-started-btn elevation">
                Create Account
            </button>
        </router-link>
    </div>
</div>
</template>

<script>
export default {
    props: ['mood'],

    computed: {
        createChannelRoute() {
            return {
                name: 'register',
                params: this.$route.params
            }
        }
    }
}
</script>

<style>

</style>