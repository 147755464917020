<template>
    <div class="section">
        <div class="section-content">
            <transition-group name="swipe" class="row no-gutters">
                <div
                    class="col-lg-3 col-md-3 col-6 p-1"
                    v-for="(type, index) in preferences"
                    :key="`${index}-interest`">
                    <button
                        type="button"
                        @click.prevent="addPreference(type)"
                        class="preference-item elevation rounded btn-block"
                        :class="{ 'selected': value.includes(type.id) }">
                        <i class="mdi mdi-checkbox-marked-circle font-xl check"></i>
                        <i class="mdi mdi-checkbox-blank-circle-outline font-xl uncheck"></i>
                        <img :src="type.icon_url" class="avatar-xs"/>
                        <p class="m-0 mt-2 text-capitalize font-xxs text-truncate">{{ type.name }}</p>
                    </button>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        type: {
            type: String,
            default: 'product',
        },

        value: {
            type: Array,
            default: () => ([]),
        }
    },

    computed: {
        ...mapGetters({
            preferenceTypes: 'PreferenceTypes/getAll'
        }),

        preferences() {
            return this.preferenceTypes.filter(type => type.type == this.type);
        }
    },

    methods: {
        ...mapActions({
            
        }),

        addPreference(type) {
            if (this.value.includes(type.id)) {
                this.$emit('input', this.value.filter(id => id != type.id));
                return;
            }
            
            this.$emit('input', [...this.value, type.id]);
        }
    },
}
</script>

<style>

</style>