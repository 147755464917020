<template>
<div class="token-input">
    <label class="m-0">{{ label }}</label>
    <div class="token-field d-flex flex-wrap align-items-center">
        <span
            class="token p-1 pl-3 pr-2 m-1 d-inline-flex align-items-center font-xs"
            v-for="(item, index) in tokens" :key="index">
            {{ item }} <a href="#" @click.prevent="removeToken(index)" class="text-danger ml-2"><i class="mdi mdi-close"></i></a>
        </span>

        <!-- 
            Do not remove this span, it's a hacky solution
            Unless you're smart enough to find a better solution
        -->
        <span></span>

        <input
            ref="tokenfield"
            :name="name"
            :placeholder="placeholder"
            type="text"
            v-model="token"
            class="flex-grow-1"
            :class="{'text-danger': !!errorMsg.trim() }"
            @keypress.enter="addToken(null)"/>
    </div>

    <div class="text-danger font-xxs">
        {{ errorMsg }}
    </div>
</div>
</template>
<script>
import Config from '@/config';
import TypeAhead from '@/mixins/typeaheads';

export default {
    props: ['value', 'shouldSubmit', 'placeholder', 'name', 'label', 'string', 'typeahead', 'types'],

    mixins: [TypeAhead],

    data() {
        return {
            token: '',
            tokens: [],
            numberOfTokenColors: 8,
            errorMsg: '',
        }
    },

    watch: {
        value() {
            this.prepopulate();
        }
    },

    methods: {
        prepopulate() {
            if (this.value && Array.isArray(this.value)) {
                this.tokens = [...this.value];
            }

            if (this.value && this.string) {
                this.tokens = this.value.split(',');
            }

            // console.log(this.tokens)
        },

        addToken(selection) {
            this.errorMsg = '';

            const item = selection || this.token.trim();
            if (!item) return;

            if (this.types && this.types.length) {
                if (this.types.includes('email') && !this.validateEmail(item)) {
                    this.errorMsg = 'Invalid email address'
                    return;
                }
            }

            if (this.value.includes(item)) {
                this.errorMsg = `${item} already included in the list`
                return;
            }

            this.emitValue([...this.value, item]);
            this.$refs.tokenfield.value = '';
            $(this.$refs.tokenfield).typeahead('val', '');
            this.token = '';

            if (!this.shouldSubmit) return;
            this.$emit('submit');
        },

        removeToken(index) {
            let values = typeof this.value == 'string' ? this.value.split(',') : [...this.value];
            values.splice(index , 1);
            this.emitValue(values);

            this.$nextTick().then(() => {
                this.$refs.tokenfield.focus();
            })

            if (!this.shouldSubmit) return;
            this.$emit('submit');
        },

        emitValue(tokens) {
            if (this.string) {
                tokens = tokens.join(',');
            }

            this.$emit('input', tokens);
        },

        initInputTypeAhead() {
            if (!this.typeahead) return;

            this.initTypeAhead(
                this.$refs.tokenfield,
                `${Config.baseURL}${this.typeahead.url}`,
                this.typeahead.options,
                (selection) => {
                    const prop = this.typeahead.options.display;
                    if (selection && selection[prop]) {
                        this.addToken(selection[prop]);
                        this.$refs.tokenfield.value = '';
                    }
                }
            );
        },

        validateEmail(email) {
            const re = /\S+@\S+\.\S+/;
            return re.test(email);
        }
    },

    mounted() {
        this.prepopulate();

        var input = $(`input[name="${this.name}"]`);
        const THIS = this;

        input.on('keydown', function(event) {
            const key = event.keyCode || event.charCode;

            if( (key == 8 || key == 46) && $(this).val() == '' ) {
                THIS.removeToken(THIS.value.length - 1);
            }
        });

        this.initInputTypeAhead();
    }
}
</script>