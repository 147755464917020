import Service from '@/services/advert_subscriptions';
import subscriptionsDB from '@/database/advert_subscriptions';

const state = {
    all: [],
};

const getters = {
    getAll(state) {
        return state.all;
    }
};

const mutations = {
    SET_SUBSCRIPTIONS(state, items) {
        state.all = [...state.all, ...items]
    },
};

const actions = {
    async index({ commit }, params) {
        const items = await subscriptionsDB.index(params);
        return Promise.resolve(items);
    },

    sync({ commit }, params) {
        return Service.index(params)
            .then(async (response) => {
                await subscriptionsDB.updateOrAdd(response);

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    async update({ commit }, params) {
        return Service.update(params.id, params.data)
            .then(async (response) => {
                await subscriptionsDB.updateOrAdd([response.subscription]);
                return Promise.resolve(response.subscription);
            })
            .catch(error => Promise.reject(error));
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}