import Service from '@/services/checkouts';
import checkoutsDB from '@/database/checkouts';

const state = {
    checkout: null
};

const getters = {
    getCheckout(state) {
        return state.checkout;
    }
};

const mutations = {
    SET_CHECKOUT(state, checkout) {
        state.checkout = checkout;
    },

    ADD_PAYMENT_METHOD(state, {user_id, pm}) {
        const pms = state.checkout.payment_methods.map(p => {
            p.is_default = false;
            return p;
        });
        pms.unshift(pm);
        state.checkout.payment_methods = pms;
        checkoutsDB.update(user_id, state.checkout);
    }
};

const actions = {
    async getCheckout({ commit }, params) {
        // first get the checkout from indexedDB
        const checkout = await checkoutsDB.getCheckoutByUser(params.user_id);
        
        if (checkout.length) {
            commit('SET_CHECKOUT', checkout[0].value);
        }

        // then get the checkout from the server
        return Service.getCheckout()
            .then(response => {
                commit('SET_CHECKOUT', response.checkout);

                if (response.checkout) {
                    checkoutsDB.update(params.user_id, response.checkout);
                }

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    addPaymentMethod({ commit }, data) {
        commit('ADD_PAYMENT_METHOD', data);
    },

    storeShippingInfo({ commit }, data) {
        return Service.storeShippingInfo(data)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    savePaymentMethod({ commit }, data) {
        return Service.savePaymentMethod(data)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    setupPaymentMethod({ commit }, data) {
        return Service.setupPaymentMethod(data)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    confirmPayment({ commit }, data) {
        return Service.confirmPayment(data)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}