<template>
    <responsive-width>
        <p-header _title="Matched Products"/>

        <create-channel-info :mood="mood"/>
        
        <Products 
            :ads="all" 
            @viewAd="showRegister"
            @show-register-popup="showRegister"/>

        <create-channel-info class="mt-3" :mood="mood"/>
    </responsive-width>
</template>

<script>
import Products from '@/views/customer/products/Index.vue';
import { mapActions, mapGetters } from 'vuex';
import CreateChannelInfo from './partials/create-channel-info.vue';

export default {
    components: {
        Products,
        CreateChannelInfo
    },

    computed: {
        ...mapGetters({
            all: 'GettingStarted/getAll',
            channelPayload: 'CreateChannels/getReqPayload'
        }),

        mood() {
            return this.$route.params.mood;
        },

        productTypeIds() {
            return this.$route.params.ids;
        }
    },

    methods: {
        ...mapActions({
            getGuestAds: 'GettingStarted/getGuestAds',
            loadParams: 'CreateChannels/loadParams',
        }),

        showRegister() {
            this.notifyWarn(`You need a Woonmi account to access the full features of 
            this page. Please create an account to continue.`);
        },

        loadData() {
            this.showLoader('Matching Products... Please wait');

            this.getGuestAds(this.channelPayload)
            .then(() => this.hideLoader())
            .catch(error => this.hideLoader());
        }
    },

    created() {
        this.loadParams();
        this.loadData()
    }
}
</script>

<style>

</style>