import bus from '@/event-bus.js';
import Service from '@/services/channel_streams';

const state = {
    all: [],
    pageDetails: {
        to: 0,
        from: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        currentPage: 0,
    },

    holdList: [],

    selectedCampaign: null,

    reportReasons: []
};

const getters = {
    getAll(state) {
        return state.all;
    },

    getPageDetails(state) {
        return state.pageDetails;
    },

    getHoldList(state) {
        return state.holdList;
    },

    getSelectedCampaign(state) {
        return state.selectedCampaign
    },

    getReportReasons(state) {
        return state.reportReasons;
    }
};

const mutations = {
    SET_STATUS(state, { clearState, payload }) {
        state.pageDetails = {
            total: payload.total || 0,
            from: payload.from || 0,
            to: payload.to || 0,
            currentPage: payload.current_page || 0,
            lastPage: payload.last_page || 0,
            perPage: payload.per_page || 0
        }

        let all = payload.data ? payload.data : payload;

        if (clearState) {
            state.all = all;
        } else {
            state.all = [...state.all, ...all];
        }
    },

    SET_HOLD_LIST(state, payload) {
        state.holdList = payload;
    },

    REMOVE(state, id) {
        for (let [index, item] of state.all.entries()) {
            if (item.id == id) {
                state.all.splice(index, 1);
                break;
            }
        }

        for (let [index, item] of state.holdList.entries()) {
            if (item.id == id) {
                state.holdList.splice(index, 1);
                break;
            }
        }
    },

    UPDATE_STATUS(state, payload) {
        for (let [index, update] of state.all.entries()) {
            if (update.id == payload.id) {
                bus.$set(state.all, index, payload);
                return;
            }
        }
    },

    CLEAR_HOLD_LIST(state, id) {
        state.holdList = state.holdList.filter(item => item.id == id);
    },

    ADD_COMMENT(state, note) {
        if (!state.selectedCampaign || !state.selectedCampaign.notes || !Array.isArray(state.selectedCampaign.notes)) return;

        state.selectedCampaign.notes.unshift(note);
    },

    SET_SELECTED_CAMPAIGN(state, payload) {
        state.selectedCampaign = payload;
    },

    SET_REPORT_REASONS(state, payload) {
        state.reportReasons = payload;
    },

    SET_CURRENT_POLL(state, payload) {
        for (let [index, update] of state.all.entries()) {
            if (update.advert_id == payload.advert_id) {
                // console.log(update)
                bus.$set(state.all[index].advert, 'poll', payload);
                return;
            }
        }
    },

    SET_IMPRESSIONS(state, payload) {
        // console.log(payload)
        // console.log(state.all)

        for (let [index, item] of state.all.entries()) {
            if (payload.includes(item.id)) {
                // console.log('setting impressions_count for ' + item.id)
                state.all[index].impressions_count = 1;
            }

            // console.log(item);
        }
    }
};

const actions = {
    index({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET_STATUS', { clearState: params.clearState, payload: response });
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    interact({commit}, params) {
        return Service.interact(params)
            .then(response => {
                commit(`UPDATE_STATUS`, response);

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    holdOrUnhold({commit}, params) {
        return Service.holdOrUnhold(params)
            .then(response => {
                commit('REMOVE', params.advert_id);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getHoldList({commit}, params) {
        return Service.getHoldList(params)
            .then(response => {
                commit('SET_HOLD_LIST', response);

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getHoldlistCampaign({commit}, params) {
        return Service.getHoldList(params)
            .then(response => {
                if (Array.isArray(response) && response.length == 1) {
                    commit('SET_SELECTED_CAMPAIGN', response[0]);
                }

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    addComment({commit}, params) {
        return Service.addComment(params)
            .then(response => {
                commit('ADD_COMMENT', response);

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getComments({commit}, params) {
        return Service.getComments(params)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    createViews({commit}, params) {
        return Service.createViews(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    updateViews({commit}, params) {
        return Service.updateViews(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    updateClicks({commit}, params) {
        return Service.updateClicks(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    delete({commit}, id) {
        commit('REMOVE', id);

        return Service.delete(id)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    getReportReasons({commit}, params) {
        return Service.getReportReasons(params)
            .then(response => {
                commit('SET_REPORT_REASONS', response)
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    reportAdvert({commit}, params) {
        return Service.reportAdvert(params)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    shareAdvert({commit}, params) {
        return Service.shareAdvert(params)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    votePoll({commit}, params) {
        return Service.votePoll(params)
            .then(response => {
                // commit('SET_CURRENT_POLL', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getRelatedAds({commit}, params) {
        return Service.getRelatedAds(params)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    remove({commit}, id) {
        commit('REMOVE', id);
    },

    clearHoldList({commit}, id) {
        commit('CLEAR_HOLD_LIST', id);
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}