import Service from '@/services/carts';
import cartsDB from '@/database/carts';

const state = {
    cart: null
};

const getters = {
    getCart(state) {
        return state.cart;
    }
};

const mutations = {
    SET_CART(state, cart) {
        state.cart = cart;
    },

    UPDATE_QUANTITY(state, data) {
        for (let item of data.items) {
            const cartItem = state.cart.items[item.id];
            cartItem.quantity = item.quantity;
            state.cart.items[item.id] = cartItem;
        }
        
        cartsDB.update(data.user_id, state.cart);
    },

    REMOVE_ITEM(state, data) {
        const cart = {...state.cart};
        delete cart.items[data.id];
        state.cart = cart;
        cartsDB.update(data.user_id, cart);
    }
};

const actions = {
    async getCart({ commit }, params) {
        // first get the cart from indexedDB
        const cart = await cartsDB.getCartByUser(params.user_id);
        
        if (cart.length) {
            commit('SET_CART', cart[0].value);
        }

        // then get the cart from the server
        return Service.getCart()
            .then(response => {
                commit('SET_CART', response.cart);

                if (response.cart) {
                    cartsDB.update(params.user_id, response.cart);
                }

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    addItems({ commit }, data) {
        return Service.addItems(data)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    removeItem({ commit }, data) {
        commit('REMOVE_ITEM', data);
        
        return Service.removeItem(data.id)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    updateItem({ commit }, data) {
        commit('UPDATE_QUANTITY', data);
        
        return Service.updateItem(data)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}