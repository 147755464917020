<template>
    <div :id="modalId" class="modal fade" :class="overlayClass" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" :class="modalClasses" role="document">
            <div class="modal-content elevation-2" style="border-radius:0" :class="{'p-0': contentPadding == false}">
                <div class="modal-header bg-default-dark font-small" v-if="showHeader">
                    <slot name="header"/>
                    <button
                        type="button"
                        class="btn text-danger waves-effect waves-light d-flex justify-content-center align-items-center position-relative"
                        @click="closeModal"
                        v-if="showCancel"
                        style="border-radius:50%;height:30px;width:30px;background:white;top:-5px;font-size:12px;">
                        <i class="mdi mdi-close"></i>
                    </button>
                </div>
                <div 
                    class="modal-body nice-scrollbar p-0" 
                    :class="{
                        'max-height': maxHeight, 
                        'fixed-height': fixedHeight, 
                        'small-max-height': smallMaxHeight,
                        'mt-3': contentPadding == true,
                        'mt-0': contentPadding == false,
                    }">
                    <slot name="body"/>
                </div>
                <div class="modal-footer flex-column p-0 pb-3" >
                    <slot name="footer"/>

                    <div class="w-100 text-right" v-if="showFooter">
                        <button
                            v-if="showCancel"
                            type="button"
                            class="btn btn-default-dark elevation font-small waves-effect mr-1 float-left login-btn pl-3 pr-3"
                            @click="closeModal">Cancel
                        </button>

                        <button
                            v-if="showInfo"
                            type="button"
                            class="btn elevation font-small waves-effect mr-1 login-btn"
                            :class="infoClass"
                            @click="$emit('info')">
                                <i v-if="infoIcon" class="" :class="infoIcon"></i>
                                {{infoText}}
                        </button>

                        <button
                            type="button"
                            class="pl-3 pr-3 btn btn-secondary elevation font-small waves-effect login-btn"
                            :class="okClass"
                            @click="$emit('ok')"
                            v-if="showOk">
                            <i :class="okIcon" class="mr-1"></i>
                            {{ okText }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },

        okText: {
            type: String,
            default: 'OK'
        },

        okIcon: {
            type: String,
            default: 'mdi mdi-check'
        },

        okColor: {
            type: String,
            default: 'danger'
        },

        okClass: {
            type: String,
            default: ''
        },

        to: {
            type: Object
        },

        loadText: {
            type: String,
            default: 'Processing...'
        },

        sm: {
            type: Boolean,
            default: false
        },

        md: {
            type: Boolean,
            default: false
        },

        lg: {
            type: Boolean,
            default: false
        },

        xl: {
            type: Boolean,
            default: false
        },

        full: {
            type: Boolean,
            default: false
        },

        centered: {
            type: Boolean,
            default: false
        },

        showFooter: {
            type: Boolean,
            default: true
        },

        showHeader: {
            type: Boolean,
            default: true
        },

        fixedHeight: {
            type: Boolean,
            default: false
        },

        maxHeight: {
            type: Boolean,
            default: false
        },

        smallMaxHeight: {
            type: Boolean,
            default: false
        },

        showCancel: {
            type: Boolean,
            default: true
        },

        showOk: {
            type: Boolean,
            default: true
        },

        showInfo: {
            type: Boolean,
            default: false,
        },

        infoText: {
            type: String,
            default: ''
        },

        infoIcon: {
            type: String,
            default: ''
        },

        infoClass: {
            type: String,
            default: 'btn-secondary'
        },

        opaque: {
            type: Boolean,
            default: false
        },

        overlay: {
            type: String
        },

        contentPadding: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {

        }
    },

    computed: {
        overlayClass() {
            let classNames = []

            if (this.opaque) {
                classNames.push('opaque')
            }

            if (this.overlay) {
                classNames.push(this.overlay)
            }

            return classNames.join(" ")
        },

        modalId() {
            return Math.random().toString(36).substr(2, 9);
        },

        modalClasses () {
            let classes = [];

            if (this.xl) {
                classes.push('modal-xl');
            } else if (this.lg) {
                classes.push('modal-lg');
            } else if (this.md) {
                classes.push('modal-md');
            } else if (this.full) {
                classes.push('modal-full');
            } else if (this.sm) {
                classes.push('modal-sm')
            }

            if (this.centered) {
                classes.push('modal-dialog-centered');
            }

            return classes;
        }
    },

    watch: {
        show() {
            this.showModal(this.show, this.name);
        }
    },

    methods: {
        closeModal () {
            this.$emit('close');
            this.$emit('cancel');
        }
    }
}
</script>

<style lang="scss" scoped>
.modal{
    background: rgba(#EFF6F6, 0.65);

    &.opaque{
        background: #EFF6F6;
    }
}

.modal-content{
    border-radius: 5px !important;
    padding: 10px 10px 0 10px;
    border: none;
}

.modal-header{
    border: none;
    border-radius: 5px;
}

.modal-footer{
    border: none;
    border-radius: 5px;
}

.modal-body{
    &.max-height{
        max-height: 60vh !important;
    }
    &.fixed-height{
        height: 60vh !important;
    }
    &.small-max-height {
        max-height: 55vh !important;
    }
    overflow-y: auto;
    overflow-x: hidden;
}
</style>