<template>
    <div class="master-layout font-small">
        <p-header v-if="showHeader"/>

        <transition name="router">
            <router-view/>
        </transition>

        <bottom-nav v-if="showBottomNav"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
    },

    data() {
        return {
        }
    },

    computed: {
        ...mapGetters({
            sidebarOpened: 'getSidebarOpened'
        }),

        showBottomNav() {
            return ![
                'secure_account',
                'view_holdlist',
                'channels.create'
            ].includes(this.$route.name);
        },

        showHeader() {
            return !['channels.create', 'getting_started'].includes(this.$route.name);
        }
    },

    methods: {
        ...mapActions({
        }),

        goIntended() {
            this.showVerify2FA = false;
        },
    },

    created() {
        // this.check2FA();
    }
}
</script>