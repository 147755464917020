<template>
<div>
    <div class="view-status" :class="{'is-video': isVideo}">
        <button type="button" class="btn control back elevation-2" @click.prevent="cancel">
            <i class="mdi mdi-arrow-left"></i>
        </button>
        <button type="button" class="btn control cancel elevation-2" @click.prevent="cancel">
            <i class="mdi mdi-close"></i>
        </button>

        <div class="left" @click.prevent="setMediaIndex(index - 1)">
            <button type="button" class="btn control elevation-2">
                <i class="mdi mdi-chevron-left"></i>
            </button>
        </div>

        <div class="right" @click.prevent="setMediaIndex(index + 1)">
            <button type="button" class="btn control elevation-2">
                <i class="mdi mdi-chevron-right"></i>
            </button>
        </div>

        <transition name="swipe">
            <div class="row justify-content-center full-height" v-if="showStatus && advert">
                <div class="col-lg-6 col-md-8 col-sm-12 col-12 full-height status-content">

                    <div class="top-part">
                        <div class="progress-indicators">
                            <status-progress
                                :videoFrame="videoFrame"
                                v-for="n in mediaCount"
                                :key="`progress-${n}`"
                                :index="index"
                                :media="currentMedia"
                                @nextMedia="progressNextMedia"
                                :value="n - 1"/>
                        </div>

                        <div class="status-profile d-flex align-items-center">
                            <button type="button" class="btn mr-2 control" @click.prevent="cancel">
                                <i class="mdi mdi-arrow-left"></i>
                            </button>

                            <img class="profile-image mr-3" :src="merchant.logo_url" @click.prevent="$emit('profile')"/>

                            <div class="desc" @click.prevent="$emit('profile')">
                                <p class="m-0 font-small font-weight-bold">{{ merchant.name }}</p>
                                <p class="m-0 font-xs">
                                    <time-counter :timestamp="advert.updated_at"/>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="content-holder" id="content-holder" v-if="currentMedia">
                        <div class="content-image video" v-if="isVideo">
                            <video class="content-video" id="content-video" controls autoplay>
                                <source :type="currentMedia.mime_type">
                            </video>
                        </div>

                        <template v-else>
                            <template v-if="currentPlayType == 'image'">
                                <div class="content-image loader" v-if="loading">
                                    <google-loader/>
                                </div>

                                <div class="content-image loader" v-if="showError">
                                    <empty
                                        iconClass="text-tertiary"
                                        title="Image could not be loaded" textClass="text-tertiary"/>
                                </div>
                            </template>

                            <div class="content-image preview-poll" v-if="currentPlayType == 'poll'">
                                <poll
                                    :poll="currentMedia.poll"
                                    @pollUpdated="pollUpdated"
                                    @voteCasted="voteCasted"/>
                            </div>

                            <div
                                class="content-image testimonial d-flex align-items-center justify-content-center"
                                v-if="currentPlayType == 'testimonial'">
                                <testimonial :testimonial="currentMedia.testimonial"/>
                            </div>
                        </template>
                    </div>

                    <div class="interactions rounded">
                        <a href="#" @click.prevent="interactWithAdvert('like')" class="item like" v-if="showLike">
                            <i class="mdi mdi-heart-outline"></i>
                        </a>

                        <a href="#" @click.prevent="interactWithAdvert('unlike')" class="item unlike" v-else>
                            <i class="mdi mdi-heart"></i>
                        </a>

                        <a href="#" @click.prevent="interactWithAdvert('dislike')" class="item dislike" v-if="showDislike">
                            <i class="mdi mdi-emoticon-sad"></i>
                        </a>

                        <a href="#" @click.prevent="interactWithAdvert('undislike')" class="item dislike" v-else>
                            <i class="mdi mdi-emoticon-sad text-danger"></i>
                        </a>

                        <a v-if="reasons.length" href="#" title="Report" @click.prevent="reportAdvert" class="item text-warning">
                            <i class="mdi mdi-flag-checkered"></i>
                        </a>
                    </div>
                </div>
            </div>
        </transition>

        <div class="actions">
            <div class="row justify-content-center no-gutters">
                <div class="col-lg-6 col-md-8 col-sm-12 col-12">
                    <p class="text-white text-center" v-if="currentMedia && currentMedia.caption">
                        {{ currentMedia.caption }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <report-advert
        :show="showReportModal"
        :advert="advert"
        @cancel="showReportModal = false"/>
</div>
</template>

<script>
import bus from '@/event-bus.js';
import { mapActions, mapGetters } from 'vuex';
import StatusProgress from './Progress';
import GoogleLoader from '@/components/shared/GoogleLoader.vue'
import ReportAdvert from './ReportAdvert';
import Poll from './Poll';
import Testimonial from './Testimonial';

export default {
    props: ['advert'],

    components: {
        StatusProgress,
        GoogleLoader,
        ReportAdvert,
        Poll,
        Testimonial
    },

    data() {
        return {
            index: 0,

            videoFrame: 0,

            loading: true,
            showError: false,
            showStatus: true,

            showLike: true,
            showDislike: true,

            start_time: null,
            viewRecord: null,
            view_breaks: [],
            showReportModal: false,

            pausedIndex: null,
            currentPlayType: 'image'
        }
    },

    computed: {
        ...mapGetters({
            reasons: 'ChannelStreams/getReportReasons'
        }),

        media() {
            if (!this.advert || !this.advert.advert_media || !this.advert.advert_media.length) return [];

            if (this.advert.testimonial) {
                this.advert.advert_media = this.advert.advert_media.filter(item => item.mime_type != `testimonial/testimonial`);

                const testimonial = this.advert.testimonial;

                this.advert.advert_media.push({
                    id: `testimonial-${Math.random().toString(16).substr(2)}`,
                    mime_type: `testimonial/testimonial`,
                    testimonial: {...testimonial}
                });
            }

            if (this.advert.poll && !this.advert.poll.user_votes.length) {
                this.advert.advert_media = this.advert.advert_media.filter(item => item.mime_type != `poll/poll`);

                const poll = this.advert.poll;

                this.advert.advert_media.push({
                    id: `poll-${poll.id}`,
                    mime_type: `poll/poll`,
                    poll: {...poll}
                });
            }

            return this.advert.advert_media;
        },

        isVideo() {
            return this.currentMedia && this.currentMedia.mime_type.startsWith('video')
        },

        isPoll() {
            return this.currentMedia && this.currentMedia.mime_type.startsWith('poll')
        },

        isTestimonial() {
            return this.currentMedia && this.currentMedia.mime_type.startsWith('testimonial')
        },

        currentMedia() {
            if (!this.mediaCount) {
                return null;
            };

            if (this.index >= this.mediaCount) {
                this.index = 0;
            }

            let media;

            if (this.currentPlayType == 'poll') {
                media = this.media[this.mediaCount - 1]
            } else if (this.currentPlayType == 'testimonial') {
                media = this.media[this.mediaCount - 2]
            } else {
                media = this.media[this.index]
            }

            return {
                src: media.file_url,
                duration: 5,
                mime_type: media.mime_type,
                caption: media.caption,
                action: media.action_name,
                lead: media.action_lead,
                poll: media.poll,
                testimonial: media.testimonial
            }
        },

        merchant() {
            if (!this.advert) return null;

            return this.advert.merchant;
        },

        mediaCount() {
            if (!this.media) return 0;

            let count = this.media.length;

            return count;
        }
    },

    watch: {
        index() {
            this.playCurrentMedia();
        },

        showReportModal() {
            if (this.showReportModal) {
                this.pauseOrPlayMedia('pause')
            } else {
                this.pauseOrPlayMedia('play');
            }
        },

        advert: {
            deep: true,
            immediate: true,
            handler() {
                this.showStatus = false;

                setTimeout(() => {
                    this.startStatusView();

                    this.showStatus = true;
                }, 250);
            }
        }
    },

    methods: {
        ...mapActions({
            updateClicks: 'ChannelStreams/updateClicks',
            interact: 'ChannelStreams/interact',
            storeViews: 'ChannelStreams/createViews',
            updateDuration: 'ChannelStreams/updateViews'
        }),

        voteCasted() {
            console.log('vote casted: start progress');

            bus.$emit('start-status-progress', this.index);
        },

        pollUpdated(poll) {
            console.log(poll);
        },

        pauseOrPlayMedia(type) {
            if (this.isVideo) {
                const video = document.getElementById("content-video");

                if (video) {
                    if (type == 'pause') {
                        video.pause();
                    } else {
                        video.play();
                    }
                }
            } else {
                if (type == 'play' && !isNaN(this.pausedIndex)) {
                    this.setMediaIndex(this.pausedIndex);

                    this.pausedIndex = null;
                }
            }
        },

        reportAdvert() {
            this.showReportModal = true;
        },

        interactWithAdvert(type) {
            if (type == 'like') {
                this.showLike = false;
            } else if (type == 'dislike') {
                this.showDislike = false;
            } else if (type == 'unlike') {
                this.showLike = true;
            } else {
                this.showDislike = true;
            }

            if (type == 'like' && !this.showDislike) {
                this.showDislike = true;
            }

            if (type == 'dislike' && !this.showLike) {
                this.showLike = true;
            }

            this.interact({
                advert_id: this.advert.id,
                interaction_type: type
            })
            .then(response => {
                // this.setLikeAndDislike(response)
            })
            .catch(error => null)
        },

        goto() {
            if (!this.advert) return;

            this.updateClicks({advert_id: this.advert.id}).catch(error => null);

            // window.open(this.advert.action_url, '_blank');
        },

        cancel() {
            this.$emit('cancel');
        },

        progressNextMedia(index) {
            this.setMediaIndex(index);
        },

        setMediaIndex(index) {
            if (this.showReportModal) {
                this.pausedIndex = index;
                return;
            }

            if (index >= 0 && index < this.mediaCount) {
                this.index = index;
            } else {
                this.updateViews();
                return;

                let direction = index < this.index ? 'prev' : 'next';

                this.reset();

                this.$emit('navigate', direction);
            }
        },

        loadImage() {
            if (this.isPoll) {
                this.currentPlayType = 'poll';
                return;
            }

            if (this.isTestimonial) {
                this.currentPlayType = 'testimonial';
                bus.$emit('start-status-progress', this.index);
                return;
            }

            this.currentPlayType = 'image';
            let image = new Image();
            image.className = `content-image`;

            let start = (new Date()).getTime();

            image.onload = () => {
                let lag = (new Date()).getTime() - start;

                if (lag) {
                    this.view_breaks.push(lag);
                }

                $('#content-holder').append(image);
                bus.$emit('start-status-progress', this.index);
                this.loading = false;
            };

            image.onerror = () => {
                bus.$emit('start-status-progress', this.index);
                this.loading = false;
                this.showError = true;
            }

            image.src = this.currentMedia.src;
        },

        playCurrentMedia() {
            if (this.currentMedia) {
                this.loading = true;
                this.showError = false;
                this.videoFrame = 0;

                let classes = [
                    '.content-holder img.content-image'
                ];

                $(classes.join(', ')).remove();

                if (this.isVideo) {
                    this.registerVideoEvent()
                } else {
                    this.loadImage();
                }
            }
        },

        registerVideoEvent() {
            this.$nextTick().then(() => {
                const video = $('.content-video');

                const THIS = this;

                this.videoFrame = 0;

                let start = (new Date()).getTime();

                video.on('timeupdate', function(event){
                    if (isNaN(THIS.videoFrame)) {
                        let lag = (new Date()).getTime() - start;

                        if (lag) {
                            THIS.view_breaks.push(lag);
                        }
                    }

                    if( this.currentTime == this.duration ) {
                        THIS.setMediaIndex(THIS.index + 1);
                    }

                    THIS.videoFrame = (this.currentTime / this.duration) * 100;
                });

                if (video[0]) {
                    video[0].src = this.currentMedia.src;
                    video[0].load();
                }
            });
        },

        startStatusView() {
            this.setLikeAndDislike(this.advert);

            this.reset();

            $('.background-cover').remove();

            let cover = new Image();

            cover.className = `background-cover`;

            cover.onload = () => {
                $('.view-status').prepend(cover);

                this.playCurrentMedia();

                this.createViews();
            }

            cover.src = this.merchant.logo_url;
        },

        createViews() {
            this.start_time = (new Date()).getTime();
            this.view_breaks = [];

            this.storeViews({advert_id: this.advert.id})
                .then(response => {
                    this.viewRecord = response;
                })
                .catch(error => null);
        },

        updateViews() {
            if (!this.start_time || this.start_time < 0 || !this.viewRecord) {
                return;
            }

            let lag = this.view_breaks.filter(brk => parseInt(brk)).reduce((accumulator, currentValue) => accumulator + currentValue)

            let duration = parseInt(((new Date()).getTime() - this.start_time - lag) / 1000);

            this.start_time = null;

            this.updateDuration({view_id: this.viewRecord.id, duration})
                .then(response => null)
                .catch(error => null);
        },

        setLikeAndDislike(advert) {
            this.showLike = !advert ||
                !advert.likes ||
                !Array.isArray(advert.likes) ||
                !advert.likes.length;

            this.showDislike = !advert ||
                !advert.dislikes ||
                !Array.isArray(advert.dislikes) ||
                !advert.dislikes.length;
        },

        reset() {
            this.index = 0;
            this.videoFrame = 0;
        }
    },

    mounted() {
        // console.log()
    }
}
</script>