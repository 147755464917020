<template>
    <div>
        <div v-if="show" class="create-intent-new">
            <responsive-width :noPadding="true">
                <div class="back-skip">
                    <button class="btn back" @click="cancel">
                        <i class="mdi mdi-chevron-left"></i>
                    </button>
                    <h5 class="text-primary d-flex align-items-center font-xs m-0">
                        {{ pageTitle }}
                    </h5>
                    <button class="btn back font-xs" @click="cancel">
                        Finish
                    </button>
                </div>
    
                <product-features v-if="isProducts" @done="cancel"/>
                
                <vendor-features v-if="isMerchants" @done="cancel"/>

                <sale-terms v-if="isSales" @done="cancel"/>

            </responsive-width>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import ProductFeatures from '@/views/customer/channels/create/stages/customize/ProductFeatures.vue';
import VendorFeatures from '@/views/customer/channels/create/stages/customize/VendorFeatures.vue';
import SaleTerms from '@/views/customer/channels/create/stages/customize/SaleTerms.vue';

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },

        currentTab: {
            type: String,
            default: 'products'
        }
    },

    components: {
        ProductFeatures,
        VendorFeatures,
        SaleTerms
    },

    data() {
        return {
        }
    },

    computed: {
        pageTitle() {
            if (this.isProducts) {
                return 'Products';
            }

            if (this.isMerchants) {
                return 'Merchants';
            }

            if (this.isSales) {
                return 'Sale Terms';
            }
        },

        isProducts() {
            return this.currentTab === 'products';
        },

        isMerchants() {
            return this.currentTab === 'merchants';
        },

        isSales() {
            return this.currentTab === 'sales'
        }
    },

    methods: {
        ...mapActions({
        }),

        cancel() {
            this.$emit('cancel')
        },
    },
}
</script>
