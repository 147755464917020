<template>
  <div class="section">
        <div class="section-content">
            <base-token-input
                label="Add one or more brands (optional)"
                name="brands"
                placeholder="e.g. Start typing a brand name"
                :value="value"
                @input="setValue"
                :shouldSubmit="true"
                :typeahead="brandConfig"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        brandConfig() {
            return {
                url: `products/features/brands`,
                options: {
                    name: 'brand',
                    display: 'name'
                }
            };
        },
    },

    methods: {
        setValue(value) {
            this.$emit('input', value);
        },
    },
}
</script>

<style>

</style>