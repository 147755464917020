import { db } from './index';
import { chunk } from 'lodash';

export default {
    async createMany(alerts) {
        await db.SmartAlerts.bulkAdd(alerts);
    },

    async updateOrAdd(alerts) {
        for (let item of alerts) {
            console.log('updated or added', item.id)
            await db.SmartAlerts.put(item);
        }
        console.log('Done updating')
    },

    async index(params) {
        const perPage = 20;
        const excludes = params.exclude || [];
        const keywordParts = params.keyword.split(' ');

        let alerts = await db.SmartAlerts.filter((smartAlert) => {
            // check that all parts of keyword are present in the product type name
            return smartAlert.type == params.type && keywordParts.every((keywordPart) => {
                return smartAlert.merchant.name.toLowerCase().split(' ').some((smartAlertNamePart) => {
                    return smartAlertNamePart.startsWith(keywordPart.toLowerCase());
                });
            }) && excludes.includes(smartAlert.id) === false;
        }).toArray();

        
        alerts = chunk(alerts, perPage);
        // console.log('results', alerts)
        // console.log('page', params.page - 1)
        alerts = alerts[params.page - 1] || [];
        
        return alerts;
    }
}