<template>
    <div class="customize-products">
        <div class="text-center text-black mb-2">
            <span class="font-weight-bold">
                {{ progress.current }}
            </span>/
            <span>{{ progress.total }}
                - <span class="">{{ productType ? productType.name : 'Product' }}</span>
            </span>
        </div>

        <div id="vendor-features">
            <div class="card">
                <div class="card-header p-0" id="vendorPref">
                    <h5 class="mb-0">
                        <button 
                            class="btn btn-link btn-block d-flex justify-content-between align-items-center" 
                            data-toggle="collapse" 
                            data-target="#vendorPrefCollapse" 
                            aria-expanded="true" 
                            aria-controls="vendorPrefCollapse">
                            Vendor/Manufacturer Preferences <i class="mdi mdi-chevron-down font-xl"></i>
                        </button>
                    </h5>
                </div>

                <div id="vendorPrefCollapse" class="collapse show" aria-labelledby="vendorPref" data-parent="#vendor-features">
                    <div class="card-body p-1">
                        <preferences
                            type="product"
                            v-model="payload.preferences"/>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header p-0" id="vendorRating">
                    <h5 class="mb-0">
                        <button 
                            class="btn btn-link btn-block d-flex justify-content-between align-items-center" 
                            data-toggle="collapse" 
                            data-target="#vendorRatingCollapse" 
                            aria-expanded="true" 
                            aria-controls="vendorRatingCollapse">
                            Vendor/Manufacturer Rating <i class="mdi mdi-chevron-down font-xl"></i>
                        </button>
                    </h5>
                </div>

                <div id="vendorRatingCollapse" class="collapse show" aria-labelledby="vendorRating" data-parent="#vendor-features">
                    <div class="card-body">
                        <vendor-rating v-model="payload.ratings"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="navigation-btns mt-4">
            <next-btn 
                :progress="progress" 
                submitText="Finish"
                @goNext="goNext"/>
        </div>
    </div>
</template>
<script>
import { get } from 'lodash';
import NextBtn from './partials/next-btn.vue';
import Preferences from './partials/preferences.vue';
import VendorRating from './partials/vendor-rating.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        NextBtn,
        Preferences,
        VendorRating,
    },

    data() {
        return {
            index: 0,
            payload: {
                preferences: [],
                ratings: [],
            },
        }
    },

    computed: {
        ...mapGetters({
            productTypes: 'CreateChannels/getProductTypes',
            merchantsConfig: 'CreateChannels/getCustomizedMerchants',
        }),

        productType() {
            if (this.index < 0 || this.index >= this.productTypes.length) {
                return null;
            }

            return this.productTypes[this.index];
        },

        progress() {
            return {
                current: this.index + 1,
                total: this.productTypes.length || 1
            }
        },
    },

    watch: {
        payload: {
            handler() {
                this.setData();
            },
            deep: true
        },

        index() {
            this.reset();
        },
    },

    methods: {
        ...mapActions({
            setCustomize: 'CreateChannels/setCustomize'
        }),

        reset() {
            this.payload = {
                preferences: get(this.merchantsConfig, `${this.productType.id}.preferences`, []),
                ratings: get(this.merchantsConfig, `${this.productType.id}.ratings`, []),
            }
        },

        setData() {
            const data = {
                ...this.merchantsConfig,
                [this.productType.id]: {...this.payload}
            };

            this.setCustomize({key: 'merchants', value: data});
        },

        goNext() {
            if (this.index < this.productTypes.length - 1) {
                this.index++;
            } else {
                this.$emit('done');
            }
        }
    },

    mounted() {
        this.reset();
    }
}
</script>
