<template>
    <div class="section collapsed">
        <!-- <heading title="Which <span>Material(s)</span>?"/> -->

        <div class="section-content">
            <base-token-input
                label="Add one or more materials (optional)"
                name="materials"
                placeholder="e.g. Start typing a material name"
                :value="value"
                @input="val => $emit('input', val)"
                :shouldSubmit="true"
                :typeahead="materialConfig"/>
        </div>
    </div>
</template>

<script>
import Heading from './Heading.vue';

export default {
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        productTypes: {
            type: Array,
            default: () => [],
        },
        activeFeature: {
            type: String,
            default: 'brands'
        }
    },

    components: {
        Heading
    },

    computed: {
        materialConfig() {
            return {
                url: `products/features/materials`,
                options: {
                    name: 'material',
                    display: 'name'
                }
            };
        },
    },

    methods: {
        
    },

    created() {
    },
}
</script>

<style></style>