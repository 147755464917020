<template>
<div class="p-header">
    <responsive-width :noPadding="true">
        <button type="button" class="btn text-primary ph-back" @click.prevent="$router.back()">
            <i class="mdi mdi-chevron-left font-xxl"></i>
        </button>
    
        <h1 class="ph-title font-medium text-primary">{{ _title || title }}</h1>
    
        <div class="ph-account">
            <div class="dropdown" v-if="isLoggedIn">
                <a class="dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img style="object-fit: cover;" :src="imageUrl || userImage" class="circle"/>
                </a>
    
                <div class="dropdown-menu" aria-labelledby="menuoptions">
                    <router-link :to="{ name: 'settings' }">
                        <a class="dropdown-item" href="#">
                            <i class="mdi mdi-account-circle mr-1"></i> Account
                        </a>
                    </router-link>
    
                    <router-link :to="{name: 'notifications'}">
                        <a class="dropdown-item" href="#">
                            <i class="mdi mdi-bell-ring-outline mr-1"></i> Notifications</a>
                    </router-link>
    
                    <a class="dropdown-item" href="#" @click.prevent="logout">Logout</a>
                </div>
            </div>
    
            <dropdown v-else/>
        </div>
    </responsive-width>
</div>
</template>

<script>
import bus from '@/event-bus'
import { mapActions, mapGetters } from 'vuex';
import Dropdown from '@/views/customer/channels/create/stages/partials/Dropdown.vue';

export default {
    props: [
        '_title'
    ],
    components: { Dropdown },
    data() {
        return {
            imageUrl: null
        };
    },
    computed: {
        ...mapGetters({
            title: 'getPHeaderTitle'
        }),
        userImage() {
            if (this.isLoggedIn) {
                return this.authUser.image_url;
            }
            return '/img/user.jpg';
        },
    },
    methods: {
        ...mapActions({
            authLogout: 'Authentications/logout'
        }),
        logout() {
            this.showLoader('logging out... Please wait');
            this.authLogout()
                .then(response => {
                this.hideLoader();
            })
                .catch(error => {
                this.hideLoader();
            });
        },
        setTitle(title) {
            this.title = title;
        },
        setImage(imageUrl) {
            this.imageUrl = imageUrl;
        }
    },
}
</script>

<style>

</style>