import Axios from 'axios';
import store from './../store';
import Config from '@/config';

export const axios = Axios.create({
    baseURL: Config.baseURL,

    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    }
});

// Intercept each request and set the bearer token for user
axios.interceptors.request.use((config) => {
    let apiToken = store.getters['Authentications/getToken'];

    if (apiToken && !config.headers.common.Authorization) {
        config.headers.common.Authorization = `Bearer ${apiToken}`;
    }

    // NProgress.start();

    return config;
});

// Logout user when the api token is not working (expired or being refreshed)
axios.interceptors.response.use(
(response) => {
    // NProgress.done();
    return Promise.resolve(response);
},

(error) => {
    // NProgress.done();
    if (error.response.status == 401 && error.config.url !== 'login') {
        store.dispatch('Authentications/localLogout');
    } else if (error.response.status == 403) {
        alert(`You're not authorized to perform this action`);
    }

    error.response.data.status_code = error.response.status;

    return Promise.reject(error);
});