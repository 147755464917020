import { db } from './index';
import { chunk } from 'lodash';

export default {
    async createMany(items) {
        await db.AdvertSubscriptions.bulkAdd(items);
    },

    async updateOrAdd(items) {
        for (let item of items) {
            await db.AdvertSubscriptions.put(item);
        }
    },

    async remove(items) {
        for (let item of items) {
            await db.AdvertSubscriptions.delete(item);
        }
    },

    async index(params) {
        const perPage = 20;
        let items = await db.AdvertSubscriptions.toArray();
        items = chunk(items, perPage);
        items = items[params.page - 1] || [];
        return items;
    },

    async getHistory({subscription_id, page}) {
        const perPage = 20;
        let items = await db.AdvertSubscriptionHistory.where({subscription_id}).toArray();
        items = chunk(items, perPage);
        items = items[page - 1] || [];
        return items;
    },

    async createManyHistory(items) {
        for (let item of items) {
            await db.AdvertSubscriptionHistory.put(item);
        }
    },

    async getLastHistory(params) {
        const last = await db.AdvertSubscriptionHistory
            .where(params)
            .reverse('id')
            .first();

        return last ? last.id : 0;
    }
}