<template>
    <div class="vendor-profile d-flex align-items-center pt-3 elevation">
        <img class="cover" :src="cover_url" v-if="cover">
        
        <img class="avatar-sm circle mr-2 index-high" :src="logo">

        <div class="flex-grow-1 index-high">
            <h4 class="vendor-name font-big mb-1 text-black font-weight-bold">
                {{ name }}
            </h4>
            <h6 class="vendor-location m-0 font-xxs text-black font-weight-bold">
                {{ vendorSubtitle }}
            </h6>
        </div>

        <div class="mt-1 index-high">
            <button type="button" class="btn-vendor-profile font-xxs">
                View Profile
            </button>
        </div>
    </div>
</template>
<script>
import { get } from 'lodash';

export default {
    props: ['merchant'],

    computed: {
        vendorSubtitle() {
            const parts = []
            const address = get(this.merchant, 'address');
            const state = get(this.merchant, 'state.name');
            const country = get(this.merchant, 'country.name');

            if (address) {
                parts.push(address);
            }
            if (state) {
                parts.push(state);
            }
            
            return parts.join(', ');
        },
        name() {
            return get(this.merchant, 'name');
        },

        logo() {
            return get(this.merchant, 'logo_url');
        },

        cover() {
            return get(this.merchant, 'cover');
        },
        cover_url() {
            return get(this.merchant, 'cover_url');
        }
    }
}
</script>